import React, { FunctionComponent } from "react";
import { Alert } from "@material-ui/lab";
import { IProps } from "./types";

const SuccessAlert: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <Alert severity="success" data-testid="successAlert">
      <strong>{props.message}</strong>
    </Alert>
  );
};

export default SuccessAlert;
