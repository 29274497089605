import React, { FunctionComponent } from "react";
import SearchBar from "material-ui-search-bar";
import { Search } from "@material-ui/icons";
import Button from "components/Button/Button";
import i18next from "i18next";
import "./i18n";
import { IProps } from "./types";
import { SearchBarWrapper } from "./styles";

const SearchBarComponent: FunctionComponent<IProps> = (props: IProps) => {
  const url = new URL(window.location.href);
  const searchBarParams = url.searchParams;

  const requestSearch = () => {
    searchBarParams.set("q", props.searched);
    url.search = searchBarParams.toString();
    const newUrl = url.pathname + url.search;
    window.history.replaceState({}, "", newUrl);
    const params = new URLSearchParams(window.location.search);
    const query = params.get("q");
    if (props.search && query) {
      props.search(query);
    }
  };

  const cancelSearch = () => {
    const newUrl = url.pathname;
    window.history.replaceState({}, "", newUrl);
    props.search("");
    props.setSearched("");
  };

  return (
    <SearchBarWrapper>
      <SearchBar
        value={props.searched}
        onCancelSearch={requestSearch}
        onRequestSearch={requestSearch}
        onChange={(newValue) => props.setSearched(newValue)}
        closeIcon={<Search />}
        searchIcon={<Search />}
      />
      <Button onClick={cancelSearch}>{i18next.t("SEARCH_BAR:CANCEL")}</Button>
    </SearchBarWrapper>
  );
};

export default SearchBarComponent;
