import { Howl } from "howler";
import { useEffect, useState } from "react";

const useAudio = (url: string): (boolean | (() => void) | Howl)[] => {
  const [audio] = useState(
    new Howl({
      src: [url],
      loop: true,
    })
  );
  const [mute, setMute] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const toggleMute = () => setMute(!mute);

  useEffect(() => {
    if (!isPlaying && !mute) {
      audio.play();
      setIsPlaying(true);
    }
    audio.volume(mute ? 0 : 1);
  }, [mute, audio, isPlaying]);

  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  return [mute, toggleMute, audio];
};

export default useAudio;
