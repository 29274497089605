import { replaceVariables } from "helper/get_json_layer.helper";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as shareActions from "redux/actions/share.actions";
import * as metricActions from "redux/actions/metric.actions";
import getTemplateSelector from "redux/selectors/template.selector";
import Player from "views/Player/Player";
import TextVariableType from "enums/textVariable.enum";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import {
  getShareImageVariables,
  getShareTextVariables,
} from "redux/selectors/share.selector";
import { ILayer } from "common/interfaces/layer.interface";
import { FormContainer } from "views/SpartanEndForm/styles";
import SquarePromoReveal from "views/SpartanEndForm/components/SquarePromoReveal/SquarePromoReveal";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import AnimationBG from "../../views/SpartanEndForm/animationBG.json";
import { ReplayBlack, ReplayCircleHover, ReplayContainer } from "./styles";

const SpartanReferralCampaign: FunctionComponent = () => {
  const [isComplete, setIsComplete] = useState(false);
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const getShareTextVariable = useSelector(getShareTextVariables());
  const getTemplateData = useSelector(getTemplateSelector());
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const [textVariables, setTextVariables] = useState<ITextVariable[]>([]);
  const getShareImageVariable = useSelector(getShareImageVariables());

  useEffect(() => {
    dispatch(shareActions.onInitializePlayer(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getTemplateData.template && !!getTemplateData.template.layers.length) {
      const textVariablesFiltered =
        getTemplateData.template.textVariables.filter(
          (textVariable: ITextVariable) =>
            textVariable.type === TextVariableType.Still
        );
      getShareTextVariable.forEach((textVariableShare: ITextVariable) =>
        textVariablesFiltered.push(textVariableShare)
      );
      setTextVariables(textVariablesFiltered);
      replaceVariables(
        getTemplateData.template,
        setLayersData,
        getTemplateData.template.layers,
        textVariablesFiltered,
        getShareImageVariable
      );
    }
  }, [
    getTemplateData.template,
    getTemplateData.template.layers,
    getShareTextVariable,
    getShareImageVariable,
  ]);

  const onCreateMetric = () => {
    dispatch(metricActions.onCreateMetric(id));
  };

  const onUpdateWatchedTime = (lastSecondPlayed: number) => {
    dispatch(metricActions.onUpdateWatchedTime(lastSecondPlayed));
  };

  const onUpdateCTAClicked = () => {
    dispatch(metricActions.onUpdateCTAClicked());
  };

  const onUpdateAudioClicked = () => {
    dispatch(metricActions.onUpdateAudioClicked());
  };

  const onClickEndCta = () => {
    window.open("/", "_blank");
  };

  const onCompleteVideo = () => {
    setIsComplete(true);
  };

  return !isComplete && !!layersData.length ? (
    <Player
      isSharePlayer
      layers={layersData}
      textVariables={textVariables}
      audio={getTemplateData.template.audio?.dataPath}
      backgroundColor={getTemplateData.template.template.backgroundColor}
      onCreateMetric={onCreateMetric}
      onUpdateWatchedTime={onUpdateWatchedTime}
      onUpdateCTAClicked={onUpdateCTAClicked}
      onUpdateAudioClicked={onUpdateAudioClicked}
      duration={getTemplateData.template.template.duration}
      onClickEndCta={onClickEndCta}
      videoPath={getTemplateData.template.video?.dataPath}
      onCompleteVideo={onCompleteVideo}
    />
  ) : (
    <div>
      {!!layersData.length && (
        <FormContainer showBGImage={false} out={false}>
          <SquarePromoReveal
            promoCode="GG-STWC15B"
            redirectLink="https://endurancecui.active.com/new/events/74242831/select-race?_p=47099830547891397&error=login_required&state=bb033435-33d2-46ba-9b01-fe13da74fe22&e4q=cdcc7dce-a6fa-48e1-a801-b2e63c9c4a7f&e4p=5f590a29-9093-4f3f-8931-9d952d9ea9dd&e4ts=1633009510&e4c=active&e4e=snawe00000000&e4rt=Safetynet&e4h=f960768f6a7ba71b00abad8d44ef7379"
            onUpdateCTAClicked={onUpdateCTAClicked}
          />
          <LottiePlayer
            animationJsonPath={AnimationBG}
            autoplay={false}
            loop
            speed={0.5}
            segments={[13, 156]}
          />
          <ReplayContainer>
            <ReplayBlack />
            <ReplayCircleHover onClick={() => setIsComplete(false)} />
          </ReplayContainer>
        </FormContainer>
      )}
    </div>
  );
};

export default SpartanReferralCampaign;
