export const ON_SAVE_NEARPOD_CHARACTER_CAMPAIGN_DATA =
  "ON_SAVE_NEARPOD_CHARACTER_CAMPAIGN_DATA";
export const ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_REQUESTED =
  "ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_REQUESTED";
export const ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_SUCCEED =
  "ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_SUCCEED";
export const ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_FAILED =
  "ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_FAILED";
export const ON_CREATE_AND_EXPORT_REQUESTED = "ON_CREATE_AND_EXPORT_REQUESTED";
export const ON_CREATE_AND_EXPORT_SUCCEED = "ON_CREATE_AND_EXPORT_SUCCEED";
export const ON_CREATE_AND_EXPORT_FAILED = "ON_CREATE_AND_EXPORT_FAILED";
