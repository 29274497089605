import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "REGISTER_FORM", {
  EMAIL: "Email",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm password",
  SUBMIT_BUTTON: "Register",
});
