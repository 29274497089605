import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeSuccessAssets,
  onCreateTemplateAssets,
  onCreateTemplate,
  onInitializeTemplateForm,
} from "redux/actions/template.actions";
import getAssetSelector from "redux/selectors/asset.selector";
import { onUpload } from "redux/actions/asset.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import getTemplateSelector, {
  getSuccessAssets,
  getTexts,
  getTemplateId,
} from "redux/selectors/template.selector";
import i18next from "i18next";
import "./i18n";
import TemplateFormView from "views/TemplateForm/TemplateFormView";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import getSuccessSelector from "redux/selectors/success.selector";
import { hideSuccessNotification } from "redux/actions/success.actions";
import { ILayersFiles } from "views/TemplateForm/components/components/UploadJsonsForm/types";
import { IAudioAsset, Template } from "common/interfaces/template.interface";
import { IImageFile } from "views/TemplateForm/components/components/UploadImagesForm/types";
import { IAsset } from "common/interfaces/file.interface";
import { IText } from "common/interfaces/text-variable.interface";

const TemplateForm: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getImages = useSelector(getAssetSelector());
  const successSelector = useSelector(getSuccessSelector());
  const successAssetsSelector = useSelector(getSuccessAssets());
  const textsSelector = useSelector(getTexts());
  const templateIdSelector = useSelector(getTemplateId());
  const getTemplates = useSelector(getTemplateSelector());

  useEffect(() => {
    dispatch(onInitializeTemplateForm());
  }, [dispatch]);

  const onUploadFiles = (
    files: ILayersFiles,
    audio: IAudioAsset,
    video: File[],
    template: Template
  ) => {
    const imageFiles: IAsset[] = getImages.images.map((asset) => {
      return {
        ...asset,
        type: getImages.variableFiles.find(
          (image) => image.file.name === asset.originalname
        )?.type,
      };
    });
    dispatch(onCreateTemplateAssets(files, imageFiles, audio, video, template));
  };

  const createTemplate = (texts: IText[]) => {
    dispatch(onCreateTemplate(texts, templateIdSelector));
  };

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  const onUploadImages = (files: IImageFile[]) => {
    dispatch(onUpload(files));
  };

  setTimeout(() => {
    if (successAssetsSelector) {
      dispatch(onChangeSuccessAssets());
    }
  }, 2500);
  return (
    <DashboardLayout>
      {successSelector && (
        <SuccessAlert message={i18next.t("TEMPLATE_FORM:CREATED")} />
      )}
      <TemplateFormView
        onUploadFiles={onUploadFiles}
        onUploadImages={onUploadImages}
        loadingImages={getImages.loading}
        hasImages={getImages.images.length}
        loadingTemplateLayers={getTemplates.loading}
        hasTemplateLayers={getTemplates.template.layers.length}
        templateId={getTemplates.template.template._id}
        successAssets={successAssetsSelector}
        texts={textsSelector}
        createTemplate={createTemplate}
      />
    </DashboardLayout>
  );
};

export default TemplateForm;
