import { IClient } from "common/interfaces/client.interface";
import { AnyAction } from "redux";
import {
  ON_DELETE_CLIENT_SUCCESS,
  ON_RETRIEVE_CLIENTS_REQUESTED,
  ON_RETRIEVE_CLIENTS_SUCCEED,
  ON_RETRIEVE_CLIENT_COUNT_SUCCEED,
  ON_SUBMIT_CLIENT_CREATE_FAILED,
  ON_SUBMIT_CLIENT_CREATE_REQUESTED,
  ON_SUBMIT_CLIENT_CREATE_SUCCESS,
} from "redux/constants/client.constants";

export interface IClientReducerState {
  clients: IClient[];
  loading: boolean;
  count: number;
}

const defaultState: IClientReducerState = {
  clients: [],
  loading: false,
  count: 0,
};

const addClients = (state: IClientReducerState, action: AnyAction) => {
  return {
    ...state,
    clients: action.data,
    loading: false,
  };
};

const addClient = (state: IClientReducerState, action: AnyAction) => {
  return {
    ...state,
    clients: [...state.clients, action.data],
    loading: false,
  };
};

const addClientCount = (state: IClientReducerState, action: AnyAction) => {
  return {
    ...state,
    count: action.count,
  };
};

const setLoading = (state: IClientReducerState) => {
  return {
    ...state,
    loading: true,
  };
};
const unsetLoading = (state: IClientReducerState) => {
  return {
    ...state,
    loading: false,
  };
};

const clientReducer = (
  state: IClientReducerState = defaultState,
  action: AnyAction
): IClientReducerState => {
  switch (action.type) {
    case ON_RETRIEVE_CLIENTS_REQUESTED:
    case ON_SUBMIT_CLIENT_CREATE_REQUESTED:
      return setLoading(state);
    case ON_RETRIEVE_CLIENTS_SUCCEED:
    case ON_DELETE_CLIENT_SUCCESS:
      return addClients(state, action);
    case ON_SUBMIT_CLIENT_CREATE_SUCCESS:
      return addClient(state, action);
    case ON_SUBMIT_CLIENT_CREATE_FAILED:
      return unsetLoading(state);
    case ON_RETRIEVE_CLIENT_COUNT_SUCCEED:
      return addClientCount(state, action);
    default:
      return state;
  }
};

export default clientReducer;
