import styled from "styled-components";
import GoldenGateLogo from "assets/iconSpartanGoldenGate.svg";
import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import { TextField } from "@material-ui/core";

export const SquareContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.wildSand};
  padding: 60px 100px;
  box-shadow: -30px 30px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: ${SIZES.bigTablet}px) {
    padding: 80px 80px;
  }

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    width: 65%;
    padding: 10px 20px;
    box-shadow: -15px 15px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: ${SIZES.bigPhone}px) {
    padding: 30px 20px;
    box-shadow: -15px 15px rgba(0, 0, 0, 0.1);
    width: 80%;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 80px;
  background-image: url(${GoldenGateLogo});
  padding: 0 0 80px;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    width: 80%;
    padding: 0;
  }

  @media screen and (max-width: ${SIZES.bigPhone}px) {
    padding: 0 0 15px;
  }
`;

export const Paragraph = styled.h2`
  padding: 0 0 80px;
  color: ${COLORS.black};
  font-family: "Termina-Regular", sans-serif;
  font-size: 32px;
  line-height: 39px;

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    font-size: 20px;
    padding: 0 0 10px;
  }

  @media screen and (max-width: ${SIZES.bigPhone}px) {
    font-size: 20px;
    padding: 0 0 40px;
    text-align: center;
  }
`;

export const FieldsContainer = styled.div`
  width: 100%;
  padding: 0px 0 40px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    width: 95%;
    height: 150px;
    padding: 0;
    & .MuiFormLabel-root,
    .MuiFormLabel-root {
      font-size: 13px !important;
      top: -3px !important;
    }
    & .MuiInputBase-input {
      font-size: 13px !important;
    }
  }
`;

export const CssTextField = styled(TextField)({
  height: "100px",
  "& .MuiFormLabel-root": {
    color: COLORS.black,
    fontFamily: "Termina-Light",
    fontSize: "18px",
    lineHeight: "21px",
    top: "-20px",
  },
  "& .MuiInputBase-input": {
    color: COLORS.black,
    fontFamily: "Termina-Light",
    fontSize: "18px",
    lineHeight: "21px",
  },
  "& label.Mui-focused": {
    color: COLORS.black,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: COLORS.black,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "red",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: COLORS.black,
    },
  },
});

export const ButtonText = styled.p`
  font-family: "Termina-Regular", sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.black};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    font-size: 17px;
  }

  @media screen and (max-width: ${SIZES.bigPhone}px) {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled.div`
  width: 430px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid ${COLORS.halfBaked};
  background-color: ${COLORS.halfBaked};
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${COLORS.black};
    border: 2px solid ${COLORS.black};
    ${ButtonText} {
      color: ${COLORS.white};
    }
  }

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    width: 270px;
    height: 40px;
  }

  @media screen and (max-width: ${SIZES.bigPhone}px) {
    width: 260px;
    height: 50px;
  }
`;
