import React, { FunctionComponent, useEffect } from "react";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  onInitializeMetricDashboard,
  onSubmitsMetricInformation,
} from "redux/actions/metric.actions";
import MetricsForm from "views/MetricsForm/MetricsForm";

const MetricsFormContainer: FunctionComponent = () => {
  const { campaignId }: { campaignId: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onInitializeMetricDashboard(campaignId));
  }, [dispatch, campaignId]);

  const createMetricsInfo = (
    date: Date,
    VTR: number,
    CTR: number,
    openRate: number,
    image: File
  ) => {
    dispatch(
      onSubmitsMetricInformation(campaignId, date, VTR, CTR, openRate, image)
    );
  };

  return (
    <DashboardLayout>
      <MetricsForm submit={createMetricsInfo} />
    </DashboardLayout>
  );
};

export default MetricsFormContainer;
