import {
  IMetricData,
  IMetricReducerState,
} from "common/interfaces/metric.interface";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getMetrics = (state: IStore) => state.metric;

export const getMetricsData = (): OutputSelector<
  IStore,
  IMetricData,
  (res: IMetricReducerState) => IMetricData
> =>
  createSelector([getMetrics], (data) => {
    return data.data;
  });

export const getMetricId = (): OutputSelector<
  IStore,
  string,
  (res: IMetricReducerState) => string
> => createSelector([getMetrics], (metrics) => metrics.id);

export default getMetricsData;
