import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as templateActions from "redux/actions/template.actions";
import getTemplateSelector, {
  getCount,
} from "redux/selectors/template.selector";

import getSuccessSelector from "redux/selectors/success.selector";
import { hideSuccessNotification } from "redux/actions/success.actions";
import i18next from "i18next";
import "./i18n";
import DashboardView from "views/DashboardView/DashboardView";
import { Data } from "components/DashboardTable/types";

const TemplateDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [actualPage, setActualPage] = useState(0);
  const getTemplateData = useSelector(getTemplateSelector());
  const getTemplateCount = useSelector(getCount());
  const successSelector = useSelector(getSuccessSelector());
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(templateActions.onInitializeTemplateDashboard(query, actualPage));
  }, [dispatch, actualPage]);

  useEffect(() => {
    if (getTemplateData.templates.length) {
      const templateKeys = Object.keys(getTemplateData.templates[0]);
      setKeys(templateKeys);
    }
    return () => {
      setKeys([]);
    };
  }, [getTemplateData]);

  const onClickAdd = () => {
    dispatch(templateActions.onSelectAdd());
  };

  const onDeleteTemplates = (templatesIds: string[]) => {
    dispatch(templateActions.onDeleteTemplates(templatesIds));
  };

  const search = (query: string) => {
    dispatch(templateActions.onInitializeTemplateDashboard(query, actualPage));
  };

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  return (
    <DashboardLayout>
      {successSelector && (
        <SuccessAlert message={i18next.t("TEMPLATE:DELETED")} />
      )}
      <DashboardView
        data={getTemplateData.templates as Data[]}
        keys={keys}
        onDelete={onDeleteTemplates}
        title="Templates"
        isTemplate
        onClickAdd={onClickAdd}
        search={search}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getTemplateCount}
      />
    </DashboardLayout>
  );
};

export default TemplateDashboardContainer;
