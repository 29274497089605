import { Toolbar, Typography } from "@material-ui/core";
import clsx from "clsx";
import DeleteTooltip from "components/DeleteTooltip/DeleteTooltip";
import React, { FunctionComponent } from "react";
import useToolbarStyles from "./styles";
import { EnhancedTableToolbarProps } from "./types";

const EnhancedTableToolbar: FunctionComponent<EnhancedTableToolbarProps> = (
  props: EnhancedTableToolbarProps
) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.title}
        </Typography>
      )}
      {numSelected > 0 && (
        <DeleteTooltip handleDelete={props.deleteShareCampaign} />
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
