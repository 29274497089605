import { IAsset } from "common/interfaces/file.interface";
import { AnyAction } from "redux";
import {
  ON_ADD_PUBLIC_ASSET_TERMS_AND_CONDITIONS_SUCCEED,
  ON_DELETE_ASSET_SUCCEED,
  ON_RETRIEVE_ASSETS_SUCCEED,
  ON_RETRIEVE_ASSET_COUNT_SUCCEED,
  ON_SUBMIT_TC_DOWNLOAD_SUCCEED,
  ON_UPLOAD_REQUESTED,
  ON_UPLOAD_SUCCESS,
} from "redux/constants/asset.constants";
import { ON_INITIALIZE_TEMPLATE_FORM } from "redux/constants/template.constants";
import { IImageFile } from "views/TemplateForm/components/components/UploadImagesForm/types";

export interface IAssetReducerState {
  images: IAsset[];
  loading: boolean;
  assets: IAsset[];
  count: number;
  variableFiles: IImageFile[];
  success: boolean;
  tcLink: string;
}

const defaultState: IAssetReducerState = {
  images: [],
  loading: false,
  assets: [],
  count: 0,
  variableFiles: [],
  success: false,
  tcLink: "",
};

const addImages = (state: IAssetReducerState, action: AnyAction) => {
  return {
    ...state,
    images: action.images,
    loading: false,
  };
};

const addAssetCount = (state: IAssetReducerState, action: AnyAction) => {
  return {
    ...state,
    count: action.count,
  };
};

const addAssets = (state: IAssetReducerState, action: AnyAction) => {
  return {
    ...state,
    assets: action.data,
  };
};

const setLoadingAndVariables = (
  state: IAssetReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    loading: true,
    variableFiles: action.files,
  };
};

const removeData = (state: IAssetReducerState) => {
  return {
    ...state,
    images: [],
    loading: false,
    variableFiles: [],
  };
};

const uploadSuccess = (state: IAssetReducerState) => {
  return {
    ...state,
    success: true,
  };
};

const addDownloadTCLink = (state: IAssetReducerState, action: AnyAction) => {
  return {
    ...state,
    tcLink: action.path,
  };
};

const assetReducer = (
  state: IAssetReducerState = defaultState,
  action: AnyAction
): IAssetReducerState => {
  switch (action.type) {
    case ON_UPLOAD_SUCCESS:
      return addImages(state, action);
    case ON_UPLOAD_REQUESTED:
      return setLoadingAndVariables(state, action);
    case ON_INITIALIZE_TEMPLATE_FORM:
      return removeData(state);
    case ON_RETRIEVE_ASSETS_SUCCEED:
    case ON_DELETE_ASSET_SUCCEED:
      return addAssets(state, action);
    case ON_RETRIEVE_ASSET_COUNT_SUCCEED:
      return addAssetCount(state, action);
    case ON_ADD_PUBLIC_ASSET_TERMS_AND_CONDITIONS_SUCCEED:
      return uploadSuccess(state);
    case ON_SUBMIT_TC_DOWNLOAD_SUCCEED:
      return addDownloadTCLink(state, action);
    default:
      return state;
  }
};

export default assetReducer;
