import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h2`
  font: normal normal bold 20px/57px "Poppins-Bold";
  margin-bottom: 35px;
  color: ${COLORS.fiord};
`;
export const Subtitle = styled.h3`
  width: 40%;
  text-align: center;
  font: normal normal normal 18px/28px "Poppins-Regular";
  margin-bottom: 50px;
  color: ${COLORS.fiord};
`;

export const ImageSuccess = styled.img`
  width: 130px;
  margin-bottom: 50px;
`;
