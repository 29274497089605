import { IDataLayer } from "common/interfaces/layer.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { IObject } from "helper/types";

export const replaceTexts = (
  dataLayer: IDataLayer,
  texts: ITextVariable[]
): IDataLayer => {
  const textToReplace = texts;
  const dataCopy = { ...dataLayer };
  const dataAssets = dataCopy.assets.map((dataAsset) => {
    const assetData = { ...dataAsset };
    if (dataAsset.layers) {
      const dataAssetLayers = (dataAsset.layers as IObject[]).map(
        (dataAssetLayer: IObject) => {
          const data = { ...dataAssetLayer };
          textToReplace.forEach((text) => {
            if (dataAssetLayer.nm === text.name) {
              if (
                dataAssetLayer.t &&
                (dataAssetLayer.t as IObject).d &&
                ((dataAssetLayer.t as IObject).d as IObject).k
              ) {
                (
                  (
                    (
                      ((data.t as IObject).d as IObject).k as IObject[]
                    )[0] as IObject
                  ).s as IObject
                ).t = text.value;
              }
            }
          });
          return data;
        }
      );
      assetData.layers = dataAssetLayers;
    }

    return assetData;
  });
  dataCopy.assets = dataAssets;
  return dataCopy;
};
