import React, { FunctionComponent, useState } from "react";
import FileInput from "components/FileInput/FileInput";
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import Button from "components/Button/Button";
import "./i18n";
import i18next from "i18next";
import { FormWrapper } from "./styles";
import { ICreateAssetsProps } from "./types";

const AddAssetView: FunctionComponent<ICreateAssetsProps> = (
  props: ICreateAssetsProps
) => {
  const [inputFile, setInputFile] = useState<FileList>();

  const onHandleInputChange = (file: FileList) => {
    setInputFile(file);
  };

  const onSubmit = () => {
    if (inputFile) {
      props.onSubmit(inputFile);
    }
  };

  return (
    <FormWrapper>
      {inputFile?.length && inputFile[0]?.name}
      <FileInput
        accept={ACCEPT_TYPES.all}
        buttonText="Upload asset"
        onChange={onHandleInputChange}
      />
      <Button onClick={onSubmit}>
        {i18next.t("CREATE_ASSET_FORM:SUBMIT_BUTTON")}
      </Button>
    </FormWrapper>
  );
};

export default AddAssetView;
