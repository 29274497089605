import styled from "styled-components";
import GoldenGateLogo from "assets/iconSpartanGoldenGate.svg";
import { ReactComponent as CopyLogo } from "assets/iconCopy.svg";
import { ReactComponent as checkLogo } from "assets/checkIcon.svg";
import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import { IParagraph } from "./types";

export const SquareContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.wildSand};
  padding: 80px 170px;
  box-shadow: -30px 30px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: ${SIZES.bigTablet}px) {
    padding: 80px 80px;
  }
  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    padding: 20px 20px;
  }

  @media screen and (max-width: ${SIZES.bigPhone}px) {
    padding: 30px 15px;
    box-shadow: -15px 15px rgba(0, 0, 0, 0.1);
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 80px;
  background-image: url(${GoldenGateLogo});
  padding: 0 0 80px;
  background-size: contain;
  background-repeat: no-repeat;
  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    width: 80%;
    padding: 0;
  }
  @media screen and (max-width: ${SIZES.bigPhone}px) {
    padding: 0 0 15px;
  }
`;

export const Paragraph = styled.h2<IParagraph>`
  padding: ${(props) => (props.showSuccess ? "0 0 30px" : "0 0 50px")};
  color: ${COLORS.black};
  font-family: "Termina-Regular", sans-serif;
  font-size: 32px;
  line-height: 39px;
  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    font-size: 20px;
    padding: 0 0 20px;
  }
  @media screen and (max-width: ${SIZES.bigPhone}px) {
    font-size: 20px;
    padding: 0 0 40px;
  }
`;

export const SuccessMessage = styled(Paragraph)`
  padding: 0 0 50px;
  text-align: center;
  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    padding: 0 0 20px;
  }
`;

export const IconCopy = styled(CopyLogo)`
  width: 36.4px;
  position: absolute;
  cursor: pointer;
  right: -55px;
  top: -5px;

  circle {
    transition: fill 0.2s ease;
    fill: #d3d8df;
  }

  &:hover {
    circle {
      fill: #a0a7af;
    }
  }
`;

export const IconCheck = styled(checkLogo)`
  width: 36.4px;
  position: absolute;
  right: -55px;
  top: -5px;

  circle {
    transition: fill 0.2s ease;
    fill: #bdc3c9;
  }
`;

export const PromoCode = styled.h2`
  padding: 0 0 90px;
  color: #81c9c0;
  font-family: "Druk-Bold", sans-serif;
  font-size: 90px;
  line-height: 29px;
  position: relative;

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    font-size: 70px;
    padding: 0 0 40px;
  }

  @media screen and (max-width: ${SIZES.bigPhone}px) {
    font-size: 60px;
  }
`;

export const ButtonText = styled.p`
  font-family: "Termina-Regular", sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.black};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled.div`
  width: 430px;
  height: 70px;
  color: ${COLORS.black};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${COLORS.black};
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${COLORS.black};
    ${ButtonText} {
      color: ${COLORS.white};
    }
  }

  @media screen and (max-width: ${SIZES.mediumTablet}px) {
    width: 320px;
    height: 50px;
  }
`;
