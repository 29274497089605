import React, { FunctionComponent } from "react";
import { Form, Field } from "react-final-form";
import i18next from "i18next";

import { requiredValidation } from "helper/validations";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import "./i18n";
import { ButtonContainer, CustomForm } from "./styles";
import { ICreateClientFormProps } from "./types";

const CreateClientForm: FunctionComponent<ICreateClientFormProps> = ({
  onSubmit,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <CustomForm onSubmit={handleSubmit}>
        <Field
          label={i18next.t("CREATE_CLIENT_FORM:INPUT_TEXT")}
          name="name"
          render={Input}
          type="text"
          validate={requiredValidation}
        />
        <ButtonContainer>
          <Button disabled={pristine || submitting} type="submit">
            {i18next.t("CREATE_CLIENT_FORM:SUBMIT_BUTTON")}
          </Button>
        </ButtonContainer>
      </CustomForm>
    )}
  />
);

export default CreateClientForm;
