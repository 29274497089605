import { MenuItem, Select } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { ISelectProps } from "./types";

const SelectInput: FunctionComponent<ISelectProps> = (props: ISelectProps) => {
  return (
    <Select
      data-testid="selectInput"
      key={props.keyValue}
      value={props.value}
      label={props.label}
      onChange={(e) => props.onChangeClick(e, props.keyValue, props.index)}
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
    >
      {props.items.map((item: string) => {
        return (
          <MenuItem key={`${item}_${props.keyValue}`} value={item}>
            {item}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectInput;
