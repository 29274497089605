import { IAPIError } from "common/interfaces/api.interface";
import { IWellnetData } from "common/interfaces/wellnetEOY.interface";
import { AnyAction } from "redux";
import * as constants from "redux/constants/wellnetEOY.constants";

export function onSaveData(data: IWellnetData): AnyAction {
  return {
    type: constants.ON_SAVE_DATA,
    data,
  };
}

export function onSendData(
  data: IWellnetData,
  subscription: boolean
): AnyAction {
  return {
    type: constants.ON_SEND_DATA_REQUEST,
    data,
    subscription,
  };
}

export function onSendDataSuccess(): AnyAction {
  return {
    type: constants.ON_SEND_DATA_SUCCEED,
  };
}

export function onSendDataFailed(error: IAPIError): AnyAction {
  return {
    type: constants.ON_SEND_DATA_FAILED,
    error,
  };
}

export function onSaveEmail(email: string): AnyAction {
  return {
    type: constants.ON_SAVE_EMAIL,
    email,
  };
}
