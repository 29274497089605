import { PUBLIC_URL } from "configs/configs";

/* eslint-disable @typescript-eslint/ban-types */
interface IPerson {
  person: string;
  email: string;
  remitent: string;
  photoRemitent: string;
}

interface ITextVar {
  [key: string]: IPerson;
}

export const TEXT_VARIABLES_PROSPECTS_EOY_PSH_2022: ITextVar = {
  "teach-fx_1": {
    person: "Rachel",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "ribbon-health_1": {
    person: "Stephanie",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  tracflo_1: {
    person: "Matt",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "just-right-reader_1": {
    person: "Rosalie",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "just-right-reader_2": {
    person: "Sara",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "praxis-lab_1": {
    person: "Anthony",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "praxis-lab_2": {
    person: "Stephanie",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  ccu_1: {
    person: "Carola",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "bright-hire_1": {
    person: "Ty",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "spark-charge_1": {
    person: "David",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "spark-charge_2": {
    person: "Joshua",
    email: "dolores.sorzana@wearepsh.com",
    remitent: "Dolores Sorzana",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/doloressorzana.png`,
  },
  "teachers-pay-teachers_1": {
    person: "Nathan",
    email: "guido@wearepsh.com",
    remitent: "Guido Rossetti",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/guidorossetti.png`,
  },
  "read-theory_1": {
    person: "Courtney",
    email: "guido@wearepsh.com",
    remitent: "Guido Rossetti",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/guidorossetti.png`,
  },
  "teachers-pay-teachers_2": {
    person: "Faisal",
    email: "guido@wearepsh.com",
    remitent: "Guido Rossetti",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/guidorossetti.png`,
  },
  "magnusson-hotels_1": {
    person: "Jeremie",
    email: "guido@wearepsh.com",
    remitent: "Guido Rossetti",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/guidorossetti.png`,
  },
  "bezos-academy_1": {
    person: "Jessica",
    email: "guido@wearepsh.com",
    remitent: "Guido Rossetti",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/guidorossetti.png`,
  },
  seesaw_1: {
    person: "Sara",
    email: "edus@wearepsh.com",
    remitent: "Eduardo Devito",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/eduardodevito.png`,
  },
  flocabulary_1: {
    person: "Chris",
    email: "matias@wearepsh.com",
    remitent: "Mati Lasarte",
    photoRemitent: `${PUBLIC_URL}/psh-eoy-2022/prospects/matiaslasarte.png`,
  },
};
