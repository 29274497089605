export const SIZES = {
  smallPhone: 320,
  applePhone: 430,
  mediumPhone: 480,
  bigPhone: 575,
  smallTablet: 600,
  phoneLandscape: 667,
  mediumTablet: 767,
  bigPhoneLandscape: 850,
  bigTablet: 991,
  smallDesktop: 1024,
  desktop: 1199,
  bigDesktop: 1366,
};
