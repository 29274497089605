import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "UPLOAD_IMAGES_FORM", {
  NAME_OF_FILES: "Name of files selected",
  CONFIRM: "Confirm Upload",
  RESET: "Reset",
  INVALID_NAME: "The name of the image is invalid. Should be image_",
  VARIABLE_IMAGE: "Variable image",
});
