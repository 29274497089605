import React, { FunctionComponent } from "react";
import i18next from "i18next";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import ForgotPasswordForm from "./components/ForgotPasswordForm/ForgotPasswordForm";
import { IForgotPasswordViewProps } from "./types";
import {
  ForgotPasswordContainer,
  ForgotPasswordContent,
  ForgotPasswordFormContainer,
  Title,
} from "./styles";
import "./i18n";

const ForgotPasswordView: FunctionComponent<IForgotPasswordViewProps> = (
  props: IForgotPasswordViewProps
) => {
  return (
    <ForgotPasswordContainer>
      <ForgotPasswordContent>
        <Title>{i18next.t("FORGOT_PASSWORD_VIEW:TITLE")}</Title>
        <ForgotPasswordFormContainer>
          <ForgotPasswordForm onSubmit={props.onSubmit} />
        </ForgotPasswordFormContainer>
        {!props.isLoading && props.succeed ? (
          <SuccessAlert message={i18next.t("FORGOT_PASSWORD_VIEW:SUCCEED")} />
        ) : (
          !props.isLoading &&
          props.error && (
            <ErrorAlert message={i18next.t("FORGOT_PASSWORD_VIEW:ERROR")} />
          )
        )}
      </ForgotPasswordContent>
    </ForgotPasswordContainer>
  );
};

export default ForgotPasswordView;
