import ContainerFluid from "components/ContainerFluid/ContainerFluid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChangePasswordView from "views/ChangePasswordView/ChangePasswordView";
import { IChangePasswordFormData } from "views/ChangePasswordView/types";
import {
  onChangePasswordRequest,
  onInitializeChangePasswordRequest,
} from "redux/actions/user.actions";
import { getUserError, getUserIsLoading } from "redux/selectors/user.selector";

const ChangePassword: FunctionComponent = () => {
  const { token }: { token: string } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(getUserIsLoading());
  const error = useSelector(getUserError());
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);

  useEffect(() => {
    dispatch(onInitializeChangePasswordRequest(token));
  }, [dispatch, token]);

  const onSubmit = (formData: IChangePasswordFormData) => {
    setPasswordSubmitted(true);
    dispatch(onChangePasswordRequest(formData, token));
  };

  return (
    <ContainerFluid>
      <ChangePasswordView
        onSubmit={onSubmit}
        isLoading={isLoading}
        error={error}
        passwordSubmitted={passwordSubmitted}
      />
    </ContainerFluid>
  );
};

export default ChangePassword;
