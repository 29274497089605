import { IAPIError } from "common/interfaces/api.interface";
import {
  INearpodCharacterCampaignFormData,
  INearpodCharacterCampaign,
  ISelectedCharacter,
} from "common/interfaces/nearpod-character-campaign.interface";
import { AnyAction } from "redux";
import * as constants from "redux/constants/nearpod-character-campaign.constants";

export function onSaveData(data: INearpodCharacterCampaignFormData): AnyAction {
  return {
    type: constants.ON_SAVE_NEARPOD_CHARACTER_CAMPAIGN_DATA,
    data,
  };
}

export function onCreateNearpodCharacterRequest(
  formData: INearpodCharacterCampaignFormData,
  selectData: ISelectedCharacter
): AnyAction {
  return {
    type: constants.ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_REQUESTED,
    formData,
    selectData,
  };
}

export function onCreateNearpodCharacterSucceed(
  data: INearpodCharacterCampaign
): AnyAction {
  return {
    type: constants.ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_SUCCEED,
    data,
  };
}

export function onCreateNearpodCharacterFailed(error: IAPIError): AnyAction {
  return {
    type: constants.ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_FAILED,
    error,
  };
}

export function onCreateAndExport(
  _id: string,
  avatarFile: File,
  badgeFile: File
): AnyAction {
  return {
    type: constants.ON_CREATE_AND_EXPORT_REQUESTED,
    _id,
    avatarFile,
    badgeFile,
  };
}

export function onCreateAndExportSucceed(
  data: INearpodCharacterCampaign
): AnyAction {
  return {
    type: constants.ON_CREATE_AND_EXPORT_SUCCEED,
    data,
  };
}

export function onCreateAndExportFailed(error: IAPIError): AnyAction {
  return {
    type: constants.ON_CREATE_AND_EXPORT_FAILED,
    error,
  };
}
