import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import React, { FunctionComponent, useEffect, useState } from "react";
import Character from "assets/characters/characters.json";
import Smoke from "assets/smoke/smoke.json";
import Lottie from "lottie-web";
import ImageCropper from "components/ImageCropper/ImageCropper";
import { replaceObjectsColors } from "helper/replace/replaceColor.helper";
import { IDataLayer } from "common/interfaces/layer.interface";
import { ISelectedCharacter } from "common/interfaces/nearpod-character-campaign.interface";
import { PUBLIC_URL } from "configs/configs";
import { isMobile } from "react-device-detect";
import MessageModal from "components/MessageModal/MessageModal";
import { MESSAGE } from "common/constants/messages.constants";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import bgAnim from "./bgAnim.json";
import yeti from "./yeti.json";
import {
  GreatContainer,
  UpperSection,
  Bodies,
  Avatar,
  Wardrobe,
  ClothesSelection,
  ClothesOption,
  LowerSection,
  BackButton,
  ColorPicker,
  OtherButtons,
  SkinColor,
  StrictContainer,
  CapTitle,
  ShortTitle,
  TshirtTitle,
  CreateYourAvatar,
  AvatarDisplay,
  Body,
  Color,
  LottieContainer,
  BackButtonContainer,
  BackText,
  BackButtonReverse,
  Buttons,
  ContinueButton,
  ContinueBackText,
  BackButtonWrapper,
  BodyImage,
  JetiContainer,
} from "./styles";
import { IProps } from "./types";

const Step2: FunctionComponent<IProps> = (props: IProps) => {
  const name1 = "name1";
  const name2 = "name2";
  const [currentLottieName, setCurrentLottieName] = useState<string>(name1);
  const [currentCharacter, setCurrentCharacter] = useState(
    Character.characters[0]
  );
  const [cap0, cap1, cap2] = currentCharacter.caps;
  const [shirt0, shirt1, shirt2] = currentCharacter.tshirts;
  const [short0, short1, short2] = currentCharacter.shorts;
  const [buttonCap0, buttonCap1, buttonCap2] = currentCharacter.buttons.caps;
  const [buttonShirt0, buttonShirt1, buttonShirt2] =
    currentCharacter.buttons.tshirts;
  const [buttonShort0, buttonShort1, buttonShort2] =
    currentCharacter.buttons.shorts;
  const [color0, color1, color2] = currentCharacter.colors;
  const [imageError, setImageError] = useState<boolean>(false);

  const [currentCap, setCurrentCap] = useState<string>(cap0);
  const [currentShirt, setCurrentShirt] = useState<string>(shirt0);
  const [currentShort, setCurrentShort] = useState<string>(short0);
  const [currentColor, setCurrentColor] = useState<string>(color1);

  const [isImageModalVisible, setIsImageModalVisible] =
    useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [animation, setAnimation] = useState<any>();
  const animationSmoke = Smoke;
  const [smoke, setSmoke] = useState<boolean>(false);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);
  const [currentCharacterIndex, setCurrentCharacterIndex] = useState(0);

  const toggleError = () => {
    setImageError(false);
  };

  useEffect(() => {
    if (props.data) {
      setCurrentCharacter(Character.characters[props.data.character]);
      setCurrentCharacterIndex(props.data.character);
      setCurrentColor(
        Character.characters[props.data.character].colors[props.data.color]
      );
      setAnimation(Character.characters[props.data.character].animation);
      setCurrentCap(
        Character.characters[props.data.character].caps[props.data.cap]
      );
      setCurrentShirt(
        Character.characters[props.data.character].tshirts[props.data.shirt]
      );
      setCurrentShort(
        Character.characters[props.data.character].shorts[props.data.short]
      );
    }
  }, [props.data]);

  const onClickCharacter = (index: number) => {
    if (currentCharacterIndex !== index) {
      setSmoke(true);
      setShowAnimation(false);
      setCurrentCharacter(Character.characters[index]);
      setCurrentColor(Character.characters[index].colors[1]);
      setCurrentCap(Character.characters[index].caps[0]);
      setCurrentShirt(Character.characters[index].tshirts[0]);
      setCurrentShort(Character.characters[index].shorts[0]);
      setCurrentCharacterIndex(index);
    }
  };

  setTimeout(() => {
    if (smoke) {
      setSmoke(false);
    }
  }, 900);

  setTimeout(() => {
    if (!showAnimation) {
      setShowAnimation(true);
    }
  }, 500);

  const onChangeCap = (cap: string) => {
    setCurrentCap(cap);
  };

  const onChangeShirt = (shirt: string) => {
    setCurrentShirt(shirt);
  };

  const onChangeShort = (short: string) => {
    setCurrentShort(short);
  };

  const onChangeColor = (color: string) => {
    setCurrentColor(color);
  };

  const onClickPhotoButton = () => {
    setIsImageModalVisible(true);
  };

  const onCloseImageCropper = () => {
    setIsImageModalVisible(false);
  };

  const onContinue = () => {
    if (props.faceAsset) {
      const character = currentCharacterIndex;
      const color = currentCharacter.colors.findIndex(
        (c) => c === currentColor
      );
      const cap = currentCharacter.caps.findIndex((c) => c === currentCap);
      const shirt = currentCharacter.tshirts.findIndex(
        (s) => s === currentShirt
      );
      const short = currentCharacter.shorts.findIndex(
        (s) => s === currentShort
      );

      const data: ISelectedCharacter = {
        character,
        color,
        cap,
        shirt,
        short,
      };

      props.onConfirmCharacter(data);
    }
  };

  useEffect(() => {
    Lottie.destroy(name1);
    Lottie.destroy(name2);
    const animationJSON = currentCharacter.animation;
    animationJSON.assets[0].u = "";
    animationJSON.assets[0].p = currentCap;
    animationJSON.assets[1].u = "";
    animationJSON.assets[1].p = currentShirt;
    animationJSON.assets[2].u = "";
    animationJSON.assets[2].p = currentShort;
    animationJSON.assets[3].u = "";

    if (props.faceAsset !== "") {
      animationJSON.assets[3].p = props.faceAsset;
      setIsImageModalVisible(false);
    } else {
      animationJSON.assets[3].p = currentCharacter.default_face;
    }

    setAnimation(
      replaceObjectsColors(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        animationJSON as any as IDataLayer,
        currentCharacter.colorNames.map((name) => ({
          name,
          value: currentColor,
        }))
      )
    );

    setCurrentLottieName((prevState) => (prevState === name1 ? name2 : name1));
  }, [
    currentShirt,
    currentShort,
    currentColor,
    props.faceAsset,
    currentCap,
    currentCharacter.animation,
    currentCharacter.colorNames,
    currentCharacter.default_face,
  ]);

  return (
    <StrictContainer>
      {props.showYeti && (
        <JetiContainer>
          <LottiePlayer
            animationJsonPath={yeti}
            autoplay={false}
            loop={false}
            preserveAspectRatio="xMidYMid meet"
          />
        </JetiContainer>
      )}
      <LottiePlayer
        animationJsonPath={bgAnim}
        autoplay
        loop
        segments={[0, 374]}
        preserveAspectRatio="xMidYMid meet"
      />
      <GreatContainer>
        <UpperSection>
          <Bodies>
            <Body onClick={() => onClickCharacter(0)}>
              <BodyImage
                isSelected={currentCharacterIndex === 0}
                src={`${PUBLIC_URL}/nearpodAvatarCampaign/octopus_body.svg`}
                alt="Octopus face"
              />
            </Body>

            <Body onClick={() => onClickCharacter(1)}>
              <BodyImage
                isSelected={currentCharacterIndex === 1}
                src={`${PUBLIC_URL}/nearpodAvatarCampaign/koala_body.svg`}
                alt="Koala Face"
              />
            </Body>
            <Body onClick={() => onClickCharacter(2)}>
              <BodyImage
                isSelected={currentCharacterIndex === 2}
                src={`${PUBLIC_URL}/nearpodAvatarCampaign/bear_body.svg`}
                alt="Bear Face"
              />
            </Body>
            <Body onClick={() => onClickCharacter(3)}>
              <BodyImage
                isSelected={currentCharacterIndex === 3}
                src={`${PUBLIC_URL}/nearpodAvatarCampaign/turtle_body.svg`}
                alt="Turtle Face"
              />
            </Body>
          </Bodies>
          <Avatar>
            <CreateYourAvatar />
            <AvatarDisplay>
              {showAnimation && (
                <LottiePlayer
                  animationJsonPath={animation}
                  autoplay={false}
                  loop
                  preserveAspectRatio="xMidYMid meet"
                  name={currentLottieName}
                />
              )}
              {smoke && (
                <LottieContainer>
                  <LottiePlayer
                    animationJsonPath={animationSmoke}
                    autoplay={false}
                    loop
                    name={currentLottieName}
                  />
                </LottieContainer>
              )}
            </AvatarDisplay>
          </Avatar>
          <Wardrobe>
            {!isImageModalVisible ? (
              <>
                <CapTitle />
                <ClothesSelection>
                  <ClothesOption
                    isSelected={currentCap === cap0}
                    URL={buttonCap0}
                    onClick={() => onChangeCap(cap0)}
                  />
                  <ClothesOption
                    isSelected={currentCap === cap1}
                    URL={buttonCap1}
                    onClick={() => onChangeCap(cap1)}
                  />
                  <ClothesOption
                    isSelected={currentCap === cap2}
                    URL={buttonCap2}
                    onClick={() => onChangeCap(cap2)}
                  />
                </ClothesSelection>
                <ShortTitle />
                <ClothesSelection>
                  <ClothesOption
                    isSelected={currentShirt === shirt0}
                    URL={buttonShirt0}
                    onClick={() => onChangeShirt(shirt0)}
                  />
                  <ClothesOption
                    isSelected={currentShirt === shirt1}
                    URL={buttonShirt1}
                    onClick={() => onChangeShirt(shirt1)}
                  />
                  <ClothesOption
                    isSelected={currentShirt === shirt2}
                    URL={buttonShirt2}
                    onClick={() => onChangeShirt(shirt2)}
                  />
                </ClothesSelection>
                <TshirtTitle />
                <ClothesSelection>
                  <ClothesOption
                    isSelected={currentShort === short0}
                    URL={buttonShort0}
                    onClick={() => onChangeShort(short0)}
                  />
                  <ClothesOption
                    isSelected={currentShort === short1}
                    URL={buttonShort1}
                    onClick={() => onChangeShort(short1)}
                  />
                  <ClothesOption
                    isSelected={currentShort === short2}
                    URL={buttonShort2}
                    onClick={() => onChangeShort(short2)}
                  />
                </ClothesSelection>
              </>
            ) : (
              <ImageCropper
                onSubmit={props.addAsset}
                assetPath={props.faceAsset}
                defaultImage={currentCharacter.default_face}
                onCloseImageCropper={onCloseImageCropper}
                setError={setImageError}
                hasError={imageError}
              />
            )}
          </Wardrobe>
        </UpperSection>
        <LowerSection>
          <BackButtonWrapper>
            <BackButtonContainer onClick={props.backToForm}>
              <BackButton />
              <BackText>Back</BackText>
            </BackButtonContainer>
          </BackButtonWrapper>
          <ColorPicker>
            <SkinColor />
            <Color
              isSelected={currentColor === color0}
              color={color0}
              onClick={() => onChangeColor(color0)}
            />
            <Color
              isSelected={currentColor === color1}
              color={color1}
              onClick={() => onChangeColor(color1)}
            />
            <Color
              isSelected={currentColor === color2}
              color={color2}
              onClick={() => onChangeColor(color2)}
            />
          </ColorPicker>
          <OtherButtons>
            <Buttons onClick={onClickPhotoButton}>Upload Photo</Buttons>
            <ContinueButton onClick={onContinue} disable={!props.faceAsset}>
              <ContinueBackText>
                {isMobile ? "Preview" : "Continue"}
              </ContinueBackText>
              <BackButtonReverse />
            </ContinueButton>
          </OtherButtons>
        </LowerSection>
        {imageError && (
          <MessageModal
            title={MESSAGE.error.title}
            icon={MESSAGE.error.icon}
            closeIcon={MESSAGE.error.close}
            bgColor={MESSAGE.error.bgColor}
            onClose={toggleError}
            message="The import file is too large to upload."
          />
        )}
      </GreatContainer>
      {props.isLoadingImage && <LoadingScreen />}
    </StrictContainer>
  );
};

export default Step2;
