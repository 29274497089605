import Button from "components/Button/Button";
import i18next from "i18next";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IText } from "common/interfaces/text-variable.interface";
import { Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Container, Text } from "./styles";
import { IProps } from "./types";
import "./i18n";

const SelectTextVariableType: FunctionComponent<IProps> = (props: IProps) => {
  const [values, setValues] = useState<IText[]>([]);

  useEffect(() => {
    const newValues = [...props.textVariables];
    setValues(newValues);
  }, [props.textVariables]);

  const onChangeValue = (
    event: React.ChangeEvent<{ value: unknown }>,
    index: number
  ) => {
    const items = [...values];
    items[index].type = event.target.value as number;
    setValues(items);
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Text>{i18next.t("SELECT_CTA:SELECT")}</Text>
        {values &&
          values.map((textVariable: IText, index: number) => {
            return (
              <Grid key={textVariable.name} item xs={12} sm={12}>
                <InputLabel>
                  {textVariable.name} ({textVariable.value})
                </InputLabel>
                <Select
                  type="number"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={textVariable.type}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                    onChangeValue(event, index)
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </Grid>
            );
          })}
        <Button onClick={() => props.createTemplate(values)}>
          {i18next.t("SELECT_CTA:SUBMIT")}
        </Button>
      </Grid>
    </Container>
  );
};

export default SelectTextVariableType;
