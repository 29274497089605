import {
  aspectRatio,
  controlsHeight,
} from "common/constants/playerConstants.constants";
import { SIZES } from "common/constants/sizes.contants";
import Video from "components/Video/Video";
import { useResize } from "helper/resize.helper";
import React, { FunctionComponent, useState } from "react";
import ControlsPreview from "./ControlsPreview/ControlsPreview";
import {
  ControlsWrapper,
  TemplateContainer,
  VideoContainer,
  Wrapper,
} from "./styles";
import { IProps } from "./types";

const Player: FunctionComponent<IProps> = (props: IProps) => {
  const [widthValue, handleWindowSizeChangeMethod, heightValue]: (
    | number
    | (() => void)
  )[] = useResize();
  const width = widthValue as number;
  const height = heightValue as number;
  const handleWindowSizeChange = handleWindowSizeChangeMethod as () => void;
  const [toggle, setToggle] = useState<boolean>(false);
  const [replayVisible, setReplayVisible] = useState<boolean>(false);
  const [replay, setReplay] = useState<boolean>(false);

  const onComplete = () => {
    setReplayVisible(true);
    setReplay(false);
    setToggle(false);
    if (props.onCompleteVideo) {
      props.onCompleteVideo();
    }
  };

  const onChangeToggle = () => {
    if (props.onUpdateAudioClicked) {
      props.onUpdateAudioClicked();
    }
    setToggle(!toggle);
  };

  const onReplay = () => {
    setReplay(true);
    setReplayVisible(false);
  };

  window.addEventListener("resize", handleWindowSizeChange);

  let sum = 0;
  let newHeight = 0;
  let newWidth = 0;
  if (width > SIZES.applePhone) {
    sum = height - controlsHeight;
    newHeight = width / aspectRatio;
    newWidth = sum * aspectRatio;
    if (newWidth > width) {
      newWidth = width;
    } else {
      newHeight = sum;
    }
  } else {
    sum = width - controlsHeight;
    newWidth = sum * aspectRatio;
    newHeight = sum;
  }

  return (
    <Wrapper>
      <TemplateContainer isSharePlayer={props.isSharePlayer}>
        <VideoContainer width={newWidth} height={newHeight}>
          <Video
            isPlaying
            layers={props.layers}
            audio={props.audio}
            replay={replay}
            toggleAudio={toggle}
            onComplete={onComplete}
            backgroundColor={props.backgroundColor}
            textVariables={props.textVariables}
            segments={[]}
            onCreateMetric={props.onCreateMetric}
            onUpdateWatchedTime={props.onUpdateWatchedTime}
            onUpdateCTAClicked={props.onUpdateCTAClicked}
            duration={props.duration}
            backgroundVideo={props.videoPath}
            onClickEndCta={props.onClickEndCta}
          />
        </VideoContainer>
        <ControlsWrapper width={newWidth}>
          <ControlsPreview
            hasAudio={!!props.audio}
            replay={onReplay}
            replayVisible={replayVisible}
            toggle={toggle}
            onChangeToggle={onChangeToggle}
            isSharedPlayer
          />
        </ControlsWrapper>
      </TemplateContainer>
    </Wrapper>
  );
};

export default Player;
