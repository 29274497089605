import { AnyAction } from "redux";
import * as shareConstants from "redux/constants/share.constants";
import * as nearpodAvatarConstants from "redux/constants/nearpod-character-campaign.constants";
import * as assetConstants from "redux/constants/asset.constants";
import * as Sentry from "@sentry/react";

const loggingMiddleware =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  (next: any) =>
  (action: AnyAction): AnyAction => {
    switch (action.type) {
      case shareConstants.ON_INITIALIZE_PLAYER_REQUESTED:
        Sentry.configureScope((scope) =>
          scope.setTransactionName(
            `Share Player Init with id: ${action.shareId}`
          )
        );
        break;
      case nearpodAvatarConstants.ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_REQUESTED:
        Sentry.configureScope((scope) =>
          scope.setTransactionName(
            `ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_REQUESTED ${action.formData.email}`
          )
        );
        break;
      case nearpodAvatarConstants.ON_CREATE_AND_EXPORT_REQUESTED:
        Sentry.configureScope((scope) =>
          scope.setTransactionName(
            `ON_CREATE_AND_EXPORT_REQUESTED ${action._id}`
          )
        );
        break;
      case nearpodAvatarConstants.ON_SAVE_NEARPOD_CHARACTER_CAMPAIGN_DATA:
        Sentry.configureScope((scope) =>
          scope.setTransactionName(
            `ON_SAVE_NEARPOD_CHARACTER_CAMPAIGN_DATA (app only) ${action.data.email}`
          )
        );
        break;
      case assetConstants.ON_EXPORT_REQUESTED:
        Sentry.configureScope((scope) =>
          scope.setTransactionName(`ON_EXPORT_REQUESTED ${action._id}`)
        );
        break;
      case assetConstants.ON_SUBMIT_CROP_ASSET_REQUESTED:
        Sentry.configureScope((scope) =>
          scope.setTransactionName(
            `ON_SUBMIT_CROP_ASSET_REQUESTED ${action.file.name}`
          )
        );
        break;
      default:
        break;
    }
    return next(action);
  };

export default loggingMiddleware;
