import styled from "styled-components";
import { SIZES } from "common/constants/sizes.contants";

export const ChangePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${SIZES.smallDesktop}px;
  justify-content: center;
  height: 100vh;

  @media (max-width: ${SIZES.smallDesktop}px) {
    padding: 0 25px;
  }
`;

export const ChangePasswordContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 70%;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
`;

export const ChangePasswordFormContainer = styled.div`
  margin-top: 30px;
`;
