export const APP_ON_ROUTE_CHANGE = "APP_ON_ROUTE_CHANGE";

export const APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED =
  "APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED";

export const APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED =
  "APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED";

export const APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED =
  "APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED";

export const APP_ON_INITIALIZE_PUBLIC_ROUTE_REQUESTED =
  "APP_ON_INITIALIZE_PUBLIC_ROUTE_REQUESTED";

export const APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED =
  "APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED";

export const APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED =
  "APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED";
