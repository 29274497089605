import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { onSubmitFormSpartan } from "redux/actions/share.actions";
import SpartanEndForm from "views/SpartanEndForm/SpartanEndForm";
import { IProps } from "./types";

const SpartanFormTest: FunctionComponent<IProps> = (props: IProps) => {
  const dispatch = useDispatch();

  const onSubmit = (email: string, name: string) => {
    dispatch(onSubmitFormSpartan(email, name, props.name));
  };
  return (
    <SpartanEndForm
      onSubmit={onSubmit}
      setIsComplete={props.setIsComplete}
      onUpdateCTAClicked={props.onUpdateCTAClicked}
    />
  );
};

export default SpartanFormTest;
