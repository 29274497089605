import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { IShare } from "common/interfaces/share.interface";
import AddTooltip from "components/AddTooltip/AddTooltip";
import SearchBarComponent from "components/SearchBar/SearchbarComponent";
import { Data } from "components/DashboardTable/types";
import { ICSVRow } from "views/importCsvView/types";
import { AddContainer } from "./styles";
import EnhancedTableHead from "./components/EnhancedTableHead/EnhancedTableHead";
import useStyles from "./components/EnhancedTableHead/styles";
import { IProps } from "./types";
import EnhancedTableToolbar from "./components/EnhancedTableToolbar/EnhancedTableToolbar";
import Row from "./components/Row/Row";

const CollapsibleTable: FunctionComponent<IProps> = (props: IProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<ICSVRow[] | IShare[]>([]);

  useEffect(() => {
    setRows(props.shareCampaigns);
  }, [props.shareCampaigns]);

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setPage(newPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.shareCampaigns.map((shareCampaign: IShare) => {
        return shareCampaign._id;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleAdd = () => {
    props.addShareCampaign();
  };

  const handleDelete = () => {
    props.deleteShareCampaign(selected);
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <SearchBarComponent
          data={props.shareCampaigns as Data[]}
          setSearched={setSearched}
          search={props.search}
          searched={searched}
        />
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={props.title}
          deleteShareCampaign={handleDelete}
        />
        <TableContainer>
          <Table
            aria-label="collapsible table"
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              keys={props.keys}
              numSelected={selected.length}
              classes={classes}
              onSelectAllClick={handleSelectAllClick}
              rowCount={props.shareCampaigns.length}
            />
            <TableBody>
              {rows.map((shareCampaign: IShare | ICSVRow, index: number) => {
                const isItemSelected = isSelected(shareCampaign._id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <Row
                    key={shareCampaign._id}
                    shareCampaign={shareCampaign as IShare}
                    keys={props.variableKeys}
                    isItemSelected={isItemSelected}
                    handleClick={handleClick}
                    labelId={labelId}
                    editShareCampaignVariables={
                      props.editShareCampaignVariables
                    }
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPage={10}
          count={props.count}
          rowsPerPageOptions={[10]}
          page={props.actualPage}
          onPageChange={handleChangePage}
        />
      </Paper>
      <AddContainer>
        <AddTooltip onClick={handleAdd} />
      </AddContainer>
    </div>
  );
};

export default CollapsibleTable;
