import { IUser, IUserState } from "common/interfaces/user.interface";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getUser = (state: IStore) => state.user;

export const getUsersData = (): OutputSelector<
  IStore,
  IUser[],
  (res: IUserState) => IUser[]
> =>
  createSelector([getUser], (data) => {
    return data.users;
  });

const userSelector = (): OutputSelector<
  IStore,
  IUser | null,
  (res: IUserState) => IUser | null
> => createSelector([getUser], (user) => user.data);
export default userSelector;

export const getUserEmailToRegister = (): OutputSelector<
  IStore,
  string,
  (res: IUserState) => string
> => createSelector([getUser], (user) => user.emailToRegister);
export const getUserIsLoading = (): OutputSelector<
  IStore,
  boolean,
  (res: IUserState) => boolean
> => createSelector([getUser], (user) => user.isLoading);
export const getUserToken = (): OutputSelector<
  IStore,
  string,
  (res: IUserState) => string
> => createSelector([getUser], (user) => user.token);
export const getUserError = (): OutputSelector<
  IStore,
  boolean,
  (res: IUserState) => boolean
> => createSelector([getUser], (user) => user.error);
export const getUserSucceed = (): OutputSelector<
  IStore,
  boolean,
  (res: IUserState) => boolean
> => createSelector([getUser], (user) => user.succeed);
createSelector([getUser], (user) => user.error);
export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: IUserState) => number
> => createSelector([getUser], (user) => user.count);
