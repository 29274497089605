import React, { FunctionComponent } from "react";
import { Alert } from "@material-ui/lab";
import { IProps } from "./types";

const ErrorAlert: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <Alert severity="error" data-testid="errorAlert">
      <strong>{props.message}</strong>
    </Alert>
  );
};

export default ErrorAlert;
