import {
  DataPath,
  IDataLayer,
  ILayer,
} from "common/interfaces/layer.interface";
import { IShareImageVariable } from "common/interfaces/share.interface";
import { ITemplate } from "common/interfaces/template.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { replaceImages } from "./replace/replaceImages.helper";
import { replaceTexts } from "./replace/replaceTexts.helper";

export const replaceVariables = (
  template: ITemplate,
  setLayersData: (value: ILayer[]) => void,
  currentLayers: ILayer[],
  textVariables: ITextVariable[],
  shareImageVariables?: IShareImageVariable[]
): void => {
  const layersData = currentLayers;
  const layers = layersData.map((layer: ILayer) => {
    const layerD = layer;
    if (layer.layer.key === "texts" && layer.dataPath.assets) {
      layerD.dataPath = replaceTexts(layer.dataPath, textVariables) as DataPath;
    } else if (layer.layer.key === "images" && layer.dataPath.assets) {
      layerD.dataPath = replaceImages(
        layer.dataPath as IDataLayer,
        template.imageVariables,
        shareImageVariables
      ) as DataPath;
    }
    return layerD;
  });
  setLayersData(layers);
};
