import cookie from "react-cookies";

interface Token {
  accessToken: string;
  expiresIn: number;
}

const baseOptions = { path: "/" };

export function getCookie(key: string): string {
  return cookie.load(key);
}

export function setCookie(key: string, value: string, options = {}): void {
  cookie.save(key, value, { ...baseOptions, ...options });
}

export function removeCookie(key: string, options = {}): void {
  cookie.remove(key, { ...baseOptions, ...options });
}

export function saveUserCookies(token: Token): void {
  const { accessToken, expiresIn } = token;
  setCookie("accessToken", accessToken, { maxAge: expiresIn });
}

export function removeUserCookies(): void {
  removeCookie("accessToken");
}
