import { Fab, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

interface IProps {
  onClick: () => void;
}

const AddTooltip: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <Tooltip title="Add" aria-label="add" data-testid="addTooltip">
      <Fab color="secondary" onClick={props.onClick}>
        <Add />
      </Fab>
    </Tooltip>
  );
};

export default AddTooltip;
