import { AnyAction } from "redux";
import {
  ON_DELETE_ASSET_SUCCEED,
  ON_SUBMIT_ASSET_SUCCEED,
} from "redux/constants/asset.constants";
import {
  ON_CREATE_CAMPAIGN_SUCCEED,
  ON_DELETE_CAMPAIGNS_SUCCEED,
} from "redux/constants/campaign.constants";
import {
  ON_DELETE_CLIENT_SUCCESS,
  ON_SUBMIT_CLIENT_CREATE_SUCCESS,
} from "redux/constants/client.constants";
import {
  ON_ADD_SHARE_CAMPAIGN_SUCCEED,
  ON_DELETE_SHARE_CAMPAIGN_SUCCEED,
  ON_EDIT_SHARE_CAMPAIGN_SUCCEED,
} from "redux/constants/share.constants";
import { ON_SUCCESS_NOTIFICATION_HIDE } from "redux/constants/succes.constants";
import {
  ON_CREATE_TEMPLATE_SUCCESS,
  ON_DELETE_TEMPLATES_SUCCESS,
  ON_INITIALIZE_TEMPLATE_FORM,
} from "redux/constants/template.constants";
import {
  ON_DELETE_TEXT_VARIABLES_SUCCEED,
  ON_EDIT_TEXT_VARIABLE_SUCCEED,
} from "redux/constants/text-variable.constants";
import {
  ON_CREATE_USER_SUCCEEDED,
  ON_DELETE_USERS_SUCCESS,
} from "redux/constants/user.constants";

export interface ISuccessReducerState {
  isShown: boolean;
  successText: string;
}

const defaultState: ISuccessReducerState = {
  isShown: false,
  successText: "",
};

const setVisibleDeleted = (state: ISuccessReducerState) => {
  return {
    ...state,
    isShown: true,
    successText: "deleted",
  };
};

const setVisibleCreated = (state: ISuccessReducerState) => {
  return {
    ...state,
    isShown: true,
    successText: "created",
  };
};

const setVisibleEdited = (state: ISuccessReducerState) => {
  return {
    ...state,
    isShown: true,
    successText: "edited",
  };
};

const unsetVisible = (state: ISuccessReducerState) => {
  return {
    ...state,
    isShown: false,
    successText: "",
  };
};

const successReducer = (
  state: ISuccessReducerState = defaultState,
  action: AnyAction
): ISuccessReducerState => {
  switch (action.type) {
    case ON_DELETE_CLIENT_SUCCESS:
    case ON_DELETE_TEMPLATES_SUCCESS:
    case ON_DELETE_CAMPAIGNS_SUCCEED:
    case ON_DELETE_SHARE_CAMPAIGN_SUCCEED:
    case ON_DELETE_USERS_SUCCESS:
    case ON_DELETE_ASSET_SUCCEED:
    case ON_DELETE_TEXT_VARIABLES_SUCCEED:
      return setVisibleDeleted(state);
    case ON_ADD_SHARE_CAMPAIGN_SUCCEED:
    case ON_CREATE_TEMPLATE_SUCCESS:
    case ON_CREATE_CAMPAIGN_SUCCEED:
    case ON_SUBMIT_CLIENT_CREATE_SUCCESS:
    case ON_CREATE_USER_SUCCEEDED:
    case ON_SUBMIT_ASSET_SUCCEED:
      return setVisibleCreated(state);
    case ON_EDIT_SHARE_CAMPAIGN_SUCCEED:
    case ON_EDIT_TEXT_VARIABLE_SUCCEED:
      return setVisibleEdited(state);
    case ON_SUCCESS_NOTIFICATION_HIDE:
    case ON_INITIALIZE_TEMPLATE_FORM:
      return unsetVisible(state);
    default:
      return state;
  }
};

export default successReducer;
