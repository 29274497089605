import { AxiosResponse } from "axios";
import { IUser } from "common/interfaces/user.interface";
import { saveUserCookies } from "helper/cookies";
import { IChangePasswordFormData } from "views/ChangePasswordView/types";
import { ICreateUserFormData } from "views/CreateUserView/types";
import { IForgotPasswordFormData } from "views/ForgotPasswordView/types";
import { ILoginFormData } from "views/LoginView/types";
import { IRegisterFormData } from "views/RegisterView/types";
import { get, post, put } from "./api";

export async function login(formData: ILoginFormData): Promise<AxiosResponse> {
  const response = await post("auth/login", formData);
  const cookie = {
    accessToken: response.data.access_token,
    expiresIn: response.data.expiresIn,
  };
  saveUserCookies(cookie);
  return response.data;
}

export const getTenUsersService = async (
  query: string,
  page: number
): Promise<AxiosResponse> => {
  let response;
  if (query === "") {
    response = await get(`user/${page}/`);
  } else {
    response = await get(`user/${page}/${query}`);
  }

  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`user/get-count`);
  return response.data;
};

export async function register(
  formData: IRegisterFormData
): Promise<AxiosResponse> {
  const response = await put("user/register", formData);
  return response;
}

export async function getUserForRegister(
  token: string
): Promise<AxiosResponse> {
  const response = await get(`user/register/${token}`);
  return response.data;
}

export async function initialize(): Promise<AxiosResponse> {
  const response = await get("user/profile");
  return response.data;
}

export async function createUser(
  formData: ICreateUserFormData
): Promise<IUser> {
  const response = await post("user/", formData);
  return response.data;
}

export async function deleteUsers(ids: string[]): Promise<AxiosResponse> {
  const response = await put("user/delete", { ids });
  return response.data;
}

export async function submitForgotPassword(
  formData: IForgotPasswordFormData
): Promise<AxiosResponse> {
  const response = await put("user/forgot-password", formData);
  return response.data;
}

export async function initChangePassword(
  token: string
): Promise<AxiosResponse> {
  const response = await get(`user/change-password/${token}`);
  return response.data;
}

export async function changePassword(
  token: string,
  formData: IChangePasswordFormData
): Promise<AxiosResponse> {
  const response = await put(`user/change-password/${token}`, formData);
  return response.data;
}
