import { Button, Grid, Input, InputLabel } from "@material-ui/core";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import React, { useState, useEffect, FunctionComponent } from "react";
import i18next from "i18next";
import "./i18n";
import { Container } from "./styles";
import { IProps } from "./types";

const EditTextVariableView: FunctionComponent<IProps> = (props: IProps) => {
  const [values, setValues] = useState<ITextVariable[]>([]);

  useEffect(() => {
    const newValues = [...props.textVariables];
    setValues(newValues);
  }, [props.textVariables]);

  const editVariables = () => {
    props.editVariables(values);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const items = [...values];
    items[0].name = event.target.value;
    setValues(items);
  };

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const items = [...values];
    items[0].value = event.target.value;
    setValues(items);
  };

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const items = [...values];
    items[0].type = event.target.valueAsNumber;
    setValues(items);
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {values.map((value: ITextVariable) => {
          return (
            <Grid key={value.id} item xs={12} sm={6} spacing={3}>
              <InputLabel id="templates-select-label">name</InputLabel>
              <Input
                value={value.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeName(event)
                }
              />
              <InputLabel id="templates-select-label">value</InputLabel>
              <Input
                value={value.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeValue(event)
                }
              />
              <InputLabel id="templates-select-label">type</InputLabel>
              <Input
                type="number"
                value={value.type}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeType(event)
                }
              />
            </Grid>
          );
        })}
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={editVariables}>
            {i18next.t("EDIT_TEXT_VARIABLE:BUTTON_TEXT")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default EditTextVariableView;
