import { ITextVariable } from "common/interfaces/text-variable.interface";
import { AnyAction } from "redux";
import {
  ON_DELETE_TEXT_VARIABLES_SUCCEED,
  ON_RETRIEVE_TEXT_VARIABLES_SUCCEED,
  ON_RETRIEVE_TEXT_VARIABLE_COUNT_SUCCEED,
} from "redux/constants/text-variable.constants";

export interface ITextVariableReducerState {
  textVariable: ITextVariable[];
  count: number;
}

const defaultState: ITextVariableReducerState = {
  textVariable: [],
  count: 0,
};

const addTextVariables = (
  state: ITextVariableReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    textVariable: action.data,
  };
};

const addTextVariableCount = (
  state: ITextVariableReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    count: action.count,
  };
};

const textVariableReducer = (
  state: ITextVariableReducerState = defaultState,
  action: AnyAction
): ITextVariableReducerState => {
  switch (action.type) {
    case ON_RETRIEVE_TEXT_VARIABLES_SUCCEED:
    case ON_DELETE_TEXT_VARIABLES_SUCCEED:
      return addTextVariables(state, action);
    case ON_RETRIEVE_TEXT_VARIABLE_COUNT_SUCCEED:
      return addTextVariableCount(state, action);
    default:
      return state;
  }
};

export default textVariableReducer;
