import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "DASHBOARD_TABLE", {
  PREVIEW: "Preview",
  SHARE_CAMPAIGN_BUTTON: "View share campaigns",
  METRICS_BUTTON: "View metrics",
  METRICS_ADD_BUTTON: "Add metric info",
  CREATE_SHARE_CAMPAIGNS: "Create share campaigns",
});
