import { COLORS } from "common/constants/color.contants";
import styled, { keyframes } from "styled-components";
import { ISize } from "./types";

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

export const OpacityContainer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
  position: relative;

  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;

  display: flex;
  align-items: center;
`;

export const ViewContainer = styled.div<ISize>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
  background-color: ${COLORS.black};
`;
