/* eslint-disable @typescript-eslint/ban-types */
interface IPerson {
  person: string;
}

interface ITextVar {
  [key: string]: IPerson;
}

export const TEXT_VARIABLES_EOY_PSH_2022: ITextVar = {
  "spartan-trail_1": {
    person: "Flor",
  },
  "spartan-trail_2": {
    person: "Michael",
  },
  booknook_1: {
    person: "Jimmy",
  },
  booknook_2: {
    person: "Tony",
  },
  booknook_3: {
    person: "Heather",
  },
  pathful_1: {
    person: "Allie",
  },
  pathful_2: {
    person: "Lindsay",
  },
  pathful_3: {
    person: "Jennie",
  },
  pathful_4: {
    person: "Pam",
  },
  pathful_5: {
    person: "Patricia",
  },
  wellnet_1: {
    person: "Megan",
  },
  wellnet_2: {
    person: "Jeannette",
  },
  wellnet_3: {
    person: "Rose",
  },
  wellnet_4: {
    person: "Keith",
  },
  nurture_1: {
    person: "Emily",
  },
  nurture_2: {
    person: "Roberto",
  },
  nurture_3: {
    person: "Nikki",
  },
  life_1: {
    person: "Dov",
  },
  life_2: {
    person: "David",
  },
  life_3: {
    person: "Michelle",
  },
  life_4: {
    person: "Maria",
  },
  unipyme_1: {
    person: "Matias",
  },
  unipyme_2: {
    person: "Federico",
  },
  unipyme_3: {
    person: "Laura",
  },
  unipyme_4: {
    person: "Natalia",
  },
  slapp_1: {
    person: "Martin",
  },
  slapp_2: {
    person: "Kevin",
  },
  slapp_3: {
    person: "Luciano",
  },
  "kiddom-content_1": {
    person: "Abbas",
  },
  "kiddom-content_2": {
    person: "Lori",
  },
  "nearpod-marketing_1": {
    person: "Brisa",
  },
  "nearpod-marketing_2": {
    person: "Maria",
  },
  "nearpod-marketing_3": {
    person: "Sandy",
  },
  "nearpod-marketing_4": {
    person: "Alec",
  },
  "nearpod-marketing_5": {
    person: "Rebecca",
  },
  "nearpod-marketing_6": {
    person: "Heather",
  },
  "nearpod-marketing_7": {
    person: "Lindsay",
  },
  "nearpod-marketing_8": {
    person: "Megan",
  },
  "nearpod-product_1": {
    person: "Tyler",
  },
  "nearpod-product_2": {
    person: "Jared",
  },
  "nearpod-product_3": {
    person: "Chantal",
  },
  "nearpod-product_4": {
    person: "Lauren",
  },
  "nearpod-product_5": {
    person: "Zoe",
  },
  "nearpod-content_1": {
    person: "Alex",
  },
  "nearpod-content_2": {
    person: "Lisa",
  },
  "nearpod-content_3": {
    person: "Bonny",
  },
  "nearpod-content_4": {
    person: "Catalina",
  },
  dipper_1: {
    person: "Jacinta",
  },
  dipper_2: {
    person: "Netta",
  },
};
