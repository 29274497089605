/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IImageVariable {
  name: string;
  asset: string;
}

export const replaceImagesJson = (
  dataLayer: any,
  images: IImageVariable[]
): any => {
  const dataCopy = { ...dataLayer };
  const imagesToReplace = images;
  const dataAssets = dataCopy.assets.map((dataAsset: any) => {
    imagesToReplace.forEach((imagesArray) => {
      if (dataAsset.p && (dataAsset.p as string).includes(imagesArray.name)) {
        dataAsset.u = null;
        dataAsset.p = imagesArray.asset;
      }
    });
    return dataAsset;
  });
  dataCopy.assets = dataAssets;
  return dataCopy;
};
