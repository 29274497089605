import React, { FunctionComponent, useEffect, useState } from "react";
import CustomizedSteppers from "components/Stepper/Stepper";
import UploadJsonsForm from "views/TemplateForm/components/components/UploadJsonsForm/UploadJsonsForm";
import StartButtons from "views/TemplateForm/components/components/StartButtons/StartButtons";
import UploadImagesForm from "views/TemplateForm/components/components/UploadImagesForm/UploadImagesForm";
import Button from "components/Button/Button";
import { CircularProgress } from "@material-ui/core";
import i18next from "i18next";
import { IAudioAsset, Template } from "common/interfaces/template.interface";
import { ButtonLink, Container } from "./styles";
import { IProps } from "./types";
import "./i18n";
import { ILayersFiles } from "./components/components/UploadJsonsForm/types";
import ChangeCTATextVariable from "./components/components/SelectCTATextVariable/SelectTextVariableType";

const TemplateFormView: FunctionComponent<IProps> = (props: IProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [buttonsVisible, setButtonsVisible] = useState(true);

  const handleStepChange = () => {
    if (buttonsVisible) {
      setButtonsVisible(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleShowImageForm = () => {
    setButtonsVisible(false);
  };

  useEffect(() => {
    if (props.successAssets) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [props.successAssets]);

  const submitJsons = (
    files: ILayersFiles,
    audio: IAudioAsset,
    video: File[],
    template: Template
  ) => {
    props.onUploadFiles(files, audio, video, template);
  };

  return (
    <>
      <CustomizedSteppers activeStep={activeStep} />
      <Container>
        {buttonsVisible && (
          <StartButtons
            onClickLeftButton={handleShowImageForm}
            onClickRightButton={handleStepChange}
          />
        )}
        {(props.loadingImages || props.loadingTemplateLayers) && (
          <CircularProgress />
        )}
        {!props.loadingImages &&
          activeStep === 0 &&
          !buttonsVisible &&
          !props.hasImages && (
            <UploadImagesForm onUploadImages={props.onUploadImages} />
          )}
        {!buttonsVisible &&
          !props.loadingImages &&
          activeStep !== 1 &&
          !!props.hasImages && (
            <Button onClick={handleStepChange}>
              {i18next.t("TEMPLATE_FORM_VIEW:GO_NEXT")}
            </Button>
          )}
        {activeStep === 1 &&
          !props.loadingTemplateLayers &&
          !props.hasTemplateLayers && (
            <UploadJsonsForm
              onUploadFiles={submitJsons}
              withImages={!!props.hasImages}
            />
          )}
        {activeStep === 2 &&
          !props.loadingTemplateLayers &&
          !props.hasTemplateLayers && (
            <ChangeCTATextVariable
              createTemplate={props.createTemplate}
              textVariables={props.texts}
            />
          )}
        {!!props.hasTemplateLayers && (
          <Button>
            <ButtonLink
              to={`/dashboard/templates/preview/${props.templateId}`}
              target="_blank"
            >
              {i18next.t("TEMPLATE_FORM_VIEW:GO_PREVIEW")}
            </ButtonLink>
          </Button>
        )}
      </Container>
    </>
  );
};
export default TemplateFormView;
