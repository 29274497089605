import { IText } from "common/interfaces/text-variable.interface";
import { ITemplateReducerState } from "redux/reducers/template.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getTemplate = (state: IStore) => state.template;

export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: ITemplateReducerState) => number
> => createSelector([getTemplate], (data) => data.count);

export const getTexts = (): OutputSelector<
  IStore,
  IText[],
  (res: ITemplateReducerState) => IText[]
> => createSelector([getTemplate], (data) => data.texts);

export const getTemplateId = (): OutputSelector<
  IStore,
  string,
  (res: ITemplateReducerState) => string
> => createSelector([getTemplate], (data) => data.templateId);

export const getSuccessAssets = (): OutputSelector<
  IStore,
  boolean,
  (res: ITemplateReducerState) => boolean
> => createSelector([getTemplate], (data) => data.successAssets);

const getTemplateSelector = (): OutputSelector<
  IStore,
  ITemplateReducerState,
  (res: ITemplateReducerState) => ITemplateReducerState
> => createSelector([getTemplate], (template) => template);

export default getTemplateSelector;
