/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAPIError } from "common/interfaces/api.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/lottie-json.contants";

export const onInitializeLottieJson = (json: any, parsed: any): AnyAction => ({
  type: constants.LOTTIE_JSON_ON_INITIALIZE,
  json,
  parsed,
});

export const onInitializeLottieJsonSucceed = (data: any): AnyAction => ({
  type: constants.LOTTIE_JSON_ON_INITIALIZE_SUCCEDED,
  data,
});

export const onInitializeLottieJsonFailed = (error: IAPIError): AnyAction => ({
  type: constants.LOTTIE_JSON_ON_INITIALIZE_FAILED,
  error,
});
