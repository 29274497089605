import React, { FunctionComponent, useState } from "react";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import BackgroundAnimation from "./backgroundAnim.json";
import { BackButton, Container, Wrapper } from "./styles";
import { IProps } from "./types";

const Step3: FunctionComponent<IProps> = (props: IProps) => {
  const [complete, setComplete] = useState(false);
  return (
    <Container>
      <LottiePlayer
        animationJsonPath={BackgroundAnimation}
        loop={false}
        autoplay
        assetPath="/wellnet/img_0.png"
        onComplete={() => setComplete(true)}
      />
      {complete && (
        <Wrapper>
          <BackButton onClick={props.onClickBack} />
        </Wrapper>
      )}
    </Container>
  );
};

export default Step3;
