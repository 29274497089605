/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PUBLIC_URL } from "configs/configs";
import React from "react";
import { Container, Icon } from "./styles";

const Topbar = () => {
  return (
    <Container>
      <Icon src={`${PUBLIC_URL}/common/bms_logo.svg`} alt="bms icon" />
    </Container>
  );
};

export default Topbar;
