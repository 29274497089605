import { Howl } from "howler";

const audioFadeOut = (audio: Howl, duration: number): void => {
  const fadeInterval = setInterval(() => {
    if (audio.volume() > 0) {
      const newVolume = audio.volume() - 0.1;
      audio.volume(newVolume);
    } else {
      audio.pause();
      clearInterval(fadeInterval);
    }
  }, duration);
};

export default audioFadeOut;
