import React, { FunctionComponent } from "react";
import {
  FaceImage,
  ImageMask,
  ImageBody,
  ImageTShirt,
  ImageBottomAvatar,
  ImageContainer,
  ImageCap,
} from "./styles";
import IAvatarImageProps from "./types";

const AvatarImage: FunctionComponent<IAvatarImageProps> = (
  props: IAvatarImageProps
) => {
  return (
    <ImageContainer>
      <FaceImage src={props.faceImage} alt="Face Image" />
      <ImageMask
        src="nearpodAvatarCampaign/export/sm_round_front.png"
        alt="Face background mask"
      />
      <ImageBody src={props.bodyImage} alt="Body Image" />
      <ImageTShirt
        src={props.tShirtImage}
        alt="T shirt Image"
        character={props.character}
      />
      <ImageCap
        src={props.capImage}
        alt="cap image"
        character={props.character}
      />
      <ImageBottomAvatar
        src="nearpodAvatarCampaign/export/sm_round_back.png"
        alt="Bottom Badge Image"
      />
    </ImageContainer>
  );
};

export default AvatarImage;
