import ContainerFluid from "components/ContainerFluid/ContainerFluid";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { onInitializeRegister, onRegister } from "redux/actions/user.actions";
import {
  getUserEmailToRegister,
  getUserIsLoading,
} from "redux/selectors/user.selector";
import RegisterView from "views/RegisterView/RegisterView";
import { IRegisterFormData } from "views/RegisterView/types";

const Register: FunctionComponent = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmailToRegister());
  const isLoading = useSelector(getUserIsLoading());
  const { token }: { token: string } = useParams();

  useEffect(() => {
    dispatch(onInitializeRegister(token));
  }, [dispatch, token]);

  const onSubmit = (formData: IRegisterFormData) => {
    dispatch(onRegister(formData));
  };

  return (
    <ContainerFluid>
      <RegisterView
        onSubmit={onSubmit}
        userEmail={userEmail}
        isLoading={isLoading}
      />
    </ContainerFluid>
  );
};

export default Register;
