import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 30px;
`;

export const Label = styled.input`
  font-size: 14px;
  color: blue;
`;

export const InputWrapper = styled.div`
  margin: 10px 0;
`;

export const InputDateWrapper = styled.div``;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
  })
);

export const FileName = styled.p`
  font-size: 15px;
  line-height: 25px;
`;

export const ButtonContainer = styled.div`
  width: 81%;
  height: 40px;
  display: flex;
  flex-direction: column;
`;
