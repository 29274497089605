import { replaceVariables } from "helper/get_json_layer.helper";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as shareActions from "redux/actions/share.actions";
import * as metricActions from "redux/actions/metric.actions";
import getTemplateSelector from "redux/selectors/template.selector";
import Player from "views/Player/Player";
import TextVariableType from "enums/textVariable.enum";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import {
  getShareImageVariables,
  getShareTextVariables,
} from "redux/selectors/share.selector";
import { ILayer } from "common/interfaces/layer.interface";
import SpartanFormTest from "containers/SpartanFormTest/SpartanFormTest";

const VideoTest: FunctionComponent = () => {
  const [isComplete, setIsComplete] = useState(false);
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const getShareTextVariable = useSelector(getShareTextVariables());
  const getTemplateData = useSelector(getTemplateSelector());
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const [textVariables, setTextVariables] = useState<ITextVariable[]>([]);
  const [name, setName] = useState<string>();
  const getShareImageVariable = useSelector(getShareImageVariables());

  useEffect(() => {
    dispatch(shareActions.onInitializePlayer(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getTemplateData.template && !!getTemplateData.template.layers.length) {
      const textVariablesFiltered =
        getTemplateData.template.textVariables.filter(
          (textVariable: ITextVariable) =>
            textVariable.type === TextVariableType.Still
        );
      const nameTextVariable = getTemplateData.template.textVariables.find(
        (variable) => variable.value.toLowerCase().includes("name")
      );
      getShareTextVariable.forEach((textVariableShare: ITextVariable) => {
        textVariablesFiltered.push(textVariableShare);
        if (
          nameTextVariable &&
          textVariableShare.name === nameTextVariable.name
        ) {
          setName(textVariableShare.value);
        }
      });

      setTextVariables(textVariablesFiltered);
      replaceVariables(
        getTemplateData.template,
        setLayersData,
        getTemplateData.template.layers,
        textVariablesFiltered,
        getShareImageVariable
      );
    }
  }, [
    getTemplateData.template,
    getTemplateData.template.layers,
    getShareTextVariable,
    getShareImageVariable,
  ]);

  const onCreateMetric = () => {
    dispatch(metricActions.onCreateMetric(id));
  };

  const onUpdateWatchedTime = (lastSecondPlayed: number) => {
    dispatch(metricActions.onUpdateWatchedTime(lastSecondPlayed));
  };

  const onUpdateCTAClicked = () => {
    dispatch(metricActions.onUpdateCTAClicked());
  };

  const onUpdateAudioClicked = () => {
    dispatch(metricActions.onUpdateAudioClicked());
  };

  const onClickEndCta = () => {
    window.open("/", "_blank");
  };

  const onCompleteVideo = () => {
    setIsComplete(true);
  };

  return !isComplete && !!layersData.length ? (
    <Player
      isSharePlayer
      layers={layersData}
      textVariables={textVariables}
      audio={getTemplateData.template.audio?.dataPath}
      backgroundColor={getTemplateData.template.template.backgroundColor}
      onCreateMetric={onCreateMetric}
      onUpdateWatchedTime={onUpdateWatchedTime}
      onUpdateCTAClicked={onUpdateCTAClicked}
      onUpdateAudioClicked={onUpdateAudioClicked}
      duration={getTemplateData.template.template.duration}
      onClickEndCta={onClickEndCta}
      videoPath={getTemplateData.template.video?.dataPath}
      onCompleteVideo={onCompleteVideo}
    />
  ) : (
    <div>
      {!!layersData.length && (
        <SpartanFormTest
          setIsComplete={setIsComplete}
          name={name}
          onUpdateCTAClicked={onUpdateCTAClicked}
        />
      )}
    </div>
  );
};

export default VideoTest;
