import { init, reactRouterV5Instrumentation } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { API_BASE_URL, SENTRY_DSN_URL, SENTRY_ENV } from "configs/configs";
import { History } from "history";

const sentryHelper = (history: History): void =>
  init({
    dsn: SENTRY_DSN_URL,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
        tracingOrigins: [API_BASE_URL],
      }),
    ],
    environment: SENTRY_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

export default sentryHelper;
