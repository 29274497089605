import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";
import { IDisabled } from "./types";

export const Container = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h2`
  font: normal normal bold 20px/57px "Poppins-Bold";
  margin-bottom: 35px;
  color: ${COLORS.fiord};
`;
export const Subtitle = styled.h3`
  font: normal normal normal 18px/28px "Poppins-Regular";
  margin-bottom: 50px;
  color: ${COLORS.fiord};
`;

export const ReplaceFileText = styled.p`
  font: normal normal normal 18px/26px "Poppins-Regular";
  color: ${COLORS.fiord};
`;

export const FileName = styled.span`
  font: normal normal bold 18px/26px "Poppins-Bold";
  color: #214569;
`;

export const ReplaceFileOrange = styled.span`
  font: normal normal normal 18px/26px "Poppins-Regular";
  color: #e98573;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const AcceptContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
  align-items: center;

  p {
    font: normal normal normal 14px/26px "Poppins-Regular";
    color: ${COLORS.fiord};
    margin-left: 10px;
    span {
      color: #e98573;
    }
  }
`;

export const SubmitInput = styled.div<IDisabled>`
  width: 144px;
  height: 40px;
  background: ${(props) =>
    !props.disable
      ? "#8699ac 0% 0% no-repeat padding-box"
      : "#214569 0% 0% no-repeat padding-box"};
  border-radius: 3px;
  font: normal normal bold 14px/23px "Poppins-Regular";
  color: ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (!props.disable ? "not-allowed" : "pointer")};
  transition: all 0.2s ease-in;
  ${(props) =>
    props.disable &&
    `&:hover {
      background: rgb(20, 43, 65);
    }`}
`;
