import ILottieJsonState from "common/interfaces/lottie-json.interface";
import { AnyAction } from "redux";
import { LOTTIE_JSON_ON_INITIALIZE_SUCCEDED } from "redux/constants/lottie-json.contants";

const defaultState: ILottieJsonState = {
  data: null,
};

const addJsonData = (state: ILottieJsonState, action: AnyAction) => {
  return {
    ...state,
    data: action.data,
  };
};

const lottieJsonReducer = (
  state: ILottieJsonState = defaultState,
  action: AnyAction
): ILottieJsonState => {
  switch (action.type) {
    case LOTTIE_JSON_ON_INITIALIZE_SUCCEDED:
      return addJsonData(state, action);
    default:
      return state;
  }
};

export default lottieJsonReducer;
