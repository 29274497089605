import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "METRICS_DASHBOARD_VIEW", {
  TITLE: "Metrics",
  NO_METRICS: "No metrics to show",
  VIEWS: "Views count: {{count}}",
  TOTAL_WATCHED_TIME: "Total watched time in minutes: {{time}}",
  SOUND_PLAYED_COUNT: "Sound played count: {{count}}",
  FINISHED_VIDEO_PERCENTAGE: "Finished video percentage: {{percentage}}%",
  FINISHED_VIDEO_COUNT: "Finished video count: {{count}}",
  CLICKED_CTA_COUNT: "Clicked CTA count: {{count}}",
  AVERAGE_WATCHED_TIME: "Average watched time in seconds: {{time}}",
  DOWNLOAD: "Download metrics",
  UNIQUE_VIEWERS: "Unique viewers: {{count}}",
  UP_TO_SEVENTY_FIVE: "Views up to 75% percentage: {{percentage}}%",
});
