import { COLORS } from "common/constants/color.contants";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const Label = styled.input`
  font-size: 14px;
  color: blue;
`;

export const ButtonLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.white};
`;
