import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/campaign.actions";
import getClientSelector from "redux/selectors/client.selector";
import getTemplateSelector from "redux/selectors/template.selector";
import FormCampaign from "views/formCampaign/formCampaign";

const FormCampaignContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getTemplateData = useSelector(getTemplateSelector());
  const getClientData = useSelector(getClientSelector());

  useEffect(() => {
    dispatch(actions.onInitializeCampaignForm());
  }, [dispatch]);

  const createCampaign = (
    templateId: string,
    clientId: string,
    campaignName: string,
    metadataTitle: string,
    metadataDescription: string,
    metadataImage: File,
    metadataFavicon: File
  ) => {
    dispatch(
      actions.onCreateCampaign(
        templateId,
        clientId,
        campaignName,
        metadataTitle,
        metadataDescription,
        metadataImage,
        metadataFavicon
      )
    );
  };

  return (
    <DashboardLayout>
      <FormCampaign
        createCampaign={createCampaign}
        templates={getTemplateData.templates}
        clients={getClientData.clients}
      />
    </DashboardLayout>
  );
};

export default FormCampaignContainer;
