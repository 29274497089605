/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Container, Message } from "./styles";

const LoadingScreen = () => {
  return (
    <Container>
      <Message>Loading image...</Message>
    </Container>
  );
};

export default LoadingScreen;
