import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Grid, FormControl, TextField } from "@material-ui/core";
import Autocomplete from "components/Autocomplete/AutocompleteInput";
import { Template } from "common/interfaces/template.interface";
import { IClient } from "common/interfaces/client.interface";
import "./i18n";
import i18next from "i18next";
import FileInput from "components/FileInput/FileInput";
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import { IProps } from "./types";
import {
  ButtonContainer,
  Container,
  FileName,
  InputWrapper,
  useStyles,
} from "./styles";

const FormCampaign: FunctionComponent<IProps> = (props: IProps) => {
  const [templateSelected, setTemplateSelected] = useState<Template | null>();
  const [clientSelected, setClientSelected] = useState<IClient | null>();
  const [campaignName, setCampaignName] = useState<string>("");
  const [metadataTitle, setMetadataTitle] = useState<string>("");
  const [metadataDescription, setMetadataDescription] = useState<string>("");
  const [metadataImage, setMetadataImage] = useState<File | null>();
  const [metadataFavicon, setMetadataFavicon] = useState<File | null>();
  const [faviconError, setFaviconError] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (props.templates.length > 0) {
      setTemplateSelected(props.templates[0]);
    }
  }, [props.templates]);

  const onChangeCampaignName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignName(event.target.value);
  };

  const onChangeMetadataTitle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetadataTitle(event.target.value);
  };

  const onChangeMetadataDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMetadataDescription(event.target.value);
  };

  const onCreateCampaign = () => {
    if (
      templateSelected &&
      clientSelected &&
      campaignName &&
      metadataTitle &&
      metadataDescription &&
      metadataImage &&
      metadataFavicon
    ) {
      props.createCampaign(
        templateSelected._id,
        clientSelected._id,
        campaignName,
        metadataTitle,
        metadataDescription,
        metadataImage,
        metadataFavicon
      );
    }
  };

  const handleImageReset = () => {
    setMetadataImage(null);
  };

  const handleFaviconReset = () => {
    setMetadataFavicon(null);
  };

  const onChangeClient = (value: IClient | null) => {
    setClientSelected(value);
  };

  const onChangeTemplate = (value: Template | null) => {
    setTemplateSelected(value);
  };

  const onHandleInputImageChange = (fileList: FileList) => {
    const files = Array.from(fileList);
    if (files.length) {
      setMetadataImage(files[0]);
    }
  };

  const onHandleInputFaviconChange = (fileList: FileList) => {
    const files = Array.from(fileList);
    if (files.length) {
      if (files[0].type === ACCEPT_TYPES.pngImages) {
        setFaviconError(false);
        setMetadataFavicon(files[0]);
      } else {
        setFaviconError(true);
      }
    }
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={6} sm={3} spacing={3} style={{ width: 300 }}>
          <FormControl
            required
            variant="outlined"
            className={classes.formControl}
          >
            <Autocomplete
              value={templateSelected}
              onChangeClick={onChangeTemplate}
              label="Templates"
              options={props.templates}
              getOptionLabel={(object: Template) => object.title}
            />
            <Autocomplete
              value={clientSelected}
              onChangeClick={onChangeClient}
              label="Clients"
              options={props.clients}
              getOptionLabel={(object: IClient) => object.name}
            />
            <InputWrapper>
              <TextField
                label="Campaign Name"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={onChangeCampaignName}
              />
            </InputWrapper>
            <InputWrapper>
              <TextField
                label="Metadata title"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={onChangeMetadataTitle}
              />
            </InputWrapper>
            <InputWrapper>
              <TextField
                label="Metadata description"
                variant="outlined"
                type="text"
                autoComplete="off"
                onChange={onChangeMetadataDescription}
              />
            </InputWrapper>
            <InputWrapper>
              {!metadataImage ? (
                <FileInput
                  accept={ACCEPT_TYPES.pngImages}
                  buttonText="Upload Metadata Image"
                  onChange={onHandleInputImageChange}
                />
              ) : (
                <>
                  <InputWrapper>
                    <FileName>{metadataImage.name}</FileName>
                  </InputWrapper>
                  <ButtonContainer>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleImageReset}
                    >
                      {i18next.t("CREATE_CAMPAIGN_FORM:RESET")}
                    </Button>
                  </ButtonContainer>
                </>
              )}
            </InputWrapper>
            <InputWrapper>
              {!metadataFavicon ? (
                <FileInput
                  accept={ACCEPT_TYPES.pngImages}
                  buttonText="Upload Favicon PNG"
                  onChange={onHandleInputFaviconChange}
                />
              ) : (
                <>
                  <InputWrapper>
                    <FileName>{metadataFavicon.name}</FileName>
                  </InputWrapper>
                  <ButtonContainer>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleFaviconReset}
                    >
                      {i18next.t("CREATE_CAMPAIGN_FORM:RESET")}
                    </Button>
                  </ButtonContainer>
                </>
              )}
            </InputWrapper>
            {faviconError && (
              <ErrorAlert
                message={i18next.t("CREATE_CAMPAIGN_FORM:FAVICON_ERROR")}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateCampaign}
          >
            {i18next.t("CREATE_CAMPAIGN_FORM:CREATE")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default FormCampaign;
