import styled from "styled-components";
import { IAnimationContainer } from "./types";

export const AnimationContainer = styled.div<IAnimationContainer>`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  margin: 0 auto;
  border-radius: 0;
  overflow: hidden;
`;

export const AnimationLayer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.7s;
`;

export const VideoComponent = styled.video`
  width: 100%;
  height: 100%;
`;
