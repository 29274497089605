import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import styled from "styled-components";

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f29150;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;
  overflow: auto;
  align-items: center;
`;

export const YetiContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
`;

export const BadgeImage = styled.img`
  width: 100%;
`;

export const AvatarImage = styled.img`
  width: 100%;
  border-radius: 100%;
`;

export const DownloadContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 65%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    width: 90%;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) {
    width: 90%;
    height: 90%;
  }
`;

export const ImagesContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 80%;
  }
`;

export const BadgeSection = styled.div`
  width: 60%;
`;

export const AvatarSection = styled.div`
  width: 35%;
`;

export const TitleWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  z-index: 2;

  @media screen and (max-width: ${SIZES.bigDesktop}px) {
    width: 50%;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    width: 40%;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 30%;
  }
`;

export const ImageTitle = styled.img`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin-bottom: 10px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: "Avenir-Black";
  color: ${COLORS.white};
  font-size: 36px;
  text-align: center;

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    font-size: 25px;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 15px;
  }
`;
