import React, { ChangeEvent, FunctionComponent } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { IClient } from "common/interfaces/client.interface";
import { Template } from "common/interfaces/template.interface";
import { IAutocompleteProps } from "./types";

const AutocompleteInput: FunctionComponent<IAutocompleteProps> = (
  props: IAutocompleteProps
) => {
  return (
    <Autocomplete
      id="clear-on-escape"
      data-testid="autocompleteInput"
      clearOnEscape
      value={props.value as (Template & IClient) | null}
      onChange={(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        event: ChangeEvent<any>,
        newValue: (Template & IClient) | null
      ) => props.onChangeClick(newValue)}
      renderInput={(params) => (
        <TextField
          data-testid="autocompleteClick"
          {...params}
          label={props.label}
          margin="normal"
          variant="outlined"
        />
      )}
      options={props.options as (Template & IClient)[]}
      getOptionLabel={props.getOptionLabel}
    />
  );
};

export default AutocompleteInput;
