import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ResetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
