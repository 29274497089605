import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import background from "assets/shape-group.svg";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  justify-content: center;

  @media (max-width: ${SIZES.bigDesktop}px) {
    margin-top: 0;
  }
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -90px;
`;

export const TopColor = styled.div`
  width: 100%;
  height: 24px;
  background: transparent
    linear-gradient(269deg, #425061 0%, #946a6a 49%, #e98573 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;

  @media (max-width: ${SIZES.bigDesktop}px) {
    padding: 0;
  }
`;

export const CampaignContainer = styled.div`
  width: 100%;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 6px 12px #00000029;
  opacity: 1;
  backdrop-filter: blur(14px);
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${SIZES.bigDesktop}px) {
    padding: 0;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: column;
    height: 500px;
    justify-content: center;
    align-items: center;
  }
`;

export const TextsContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 26px;
  color: #425061;
  text-align: left;
  margin-bottom: 10px;
  font-family: Poppins-Regular;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: 20px;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    margin-left: 10px;
  }
`;

export const CampaignTitle = styled.h1`
  font-size: 40px;
  color: #e98573;
  text-align: left;
  font-family: Poppins-Bold;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
    margin-left: 35px;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    width: 97%;
    margin-left: 10px;
  }
`;

export const ImageContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 352px;
  height: 198px;
  display: flex;
`;

export const StatsSectionContainer = styled.div`
  width: 100%;
  height: 300px;
  background: #214569;
  box-shadow: 0px 6px 12px #00000029;
  opacity: 1;
  backdrop-filter: blur(14px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${SIZES.bigDesktop}px) {
    padding: 0;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    height: 750px;
    align-items: center;
  }
`;

export const QuickStatsTitle = styled.h1`
  font-size: 36px;
  color: #e98573;
  text-align: left;
  margin-bottom: 30px;
  margin-left: 43px;
  font-family: Poppins-Bold;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin-left: 20px;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    margin-left: 0px;
  }
`;

export const StatsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: ${SIZES.smallTablet}px) {
    flex-direction: column;
    align-items: center;
    height: 80%;
  }
`;

export const Stat = styled.div`
  width: 280px;
  height: 172px;
  background: #ffffff;
  box-shadow: 0px 6px 12px #183048;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 230px;
  }
`;

export const StatName = styled.p`
  width: 80%;
  font-size: 20px;
  color: #425061;
  text-align: center;
  font-family: Poppins-Bold;
`;

export const StatNumber = styled.p`
  font-size: 64px;
  color: #e98573;
  text-align: center;
  font-family: Poppins-Bold;
`;

export const InfoContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 90%;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    width: 93%;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: #425061;
  text-align: left;
  font-family: Poppins-Bold;
`;

export const TextSend = styled.p`
  font-size: 20px;
  color: #425061;
  text-align: left;
  font-family: Poppins-Regular;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
`;

export const ChartsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 6px 12px #00000029;
  opacity: 1;
  backdrop-filter: blur(14px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 20px;

  @media (max-width: ${SIZES.bigTablet}px) {
    padding: 0;
    margin-bottom: 0px;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    height: 600px;
  }
`;

export const ChartContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  padding: 5%;
  opacity: 1;

  @media (max-width: ${SIZES.smallTablet}px) {
    width: 85%;
  }
`;

export const ChartContainerPie = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  padding: 10px 20%;

  @media (max-width: ${SIZES.smallTablet}px) {
    width: 55%;
  }
`;

export const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PlayCount = styled.p`
  font-size: 16px;
  color: ${COLORS.fiord};
  text-align: left;
`;

export const VideoWatched = styled.p`
  font-size: 30px;
  color: ${COLORS.fiord};
  width: 90%;
  margin: 20px 0;
  text-align: left;
  font-family: Poppins-Bold;
  margin-top: 30px;
`;

export const config = {
  options: {
    responsive: true,
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};
