import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export const AddContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.p`
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
`;

export default useStyles;
