import { IClientReducerState } from "redux/reducers/client.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getClient = (state: IStore) => state.client;

const getClientSelector = (): OutputSelector<
  IStore,
  IClientReducerState,
  (res: IClientReducerState) => IClientReducerState
> => createSelector([getClient], (client) => client);

export const getClientLoadingSelector = (): OutputSelector<
  IStore,
  boolean,
  (res: IClientReducerState) => boolean
> => createSelector([getClient], (client) => client.loading);

export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: IClientReducerState) => number
> => createSelector([getClient], (client) => client.count);

export default getClientSelector;
