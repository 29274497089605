import Axios from "axios";
import { IDataLayer } from "common/interfaces/layer.interface";

const getJsonData = async (url: string): Promise<IDataLayer> => {
  const json = await Axios.get(url, {
    headers: { "Content-Type": "application/json" },
  });
  return json.data;
};

export default getJsonData;
