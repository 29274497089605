import { Button } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { IProps } from "./types";

const FileInput: FunctionComponent<IProps> = (props: IProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      props.onChange(files);
    }
  };

  return (
    <Button variant="contained" component="label" data-testid="fileInputButton">
      {props.buttonText}
      <input
        data-testid="fileInput"
        type="file"
        multiple={props.multiple}
        accept={props.accept}
        hidden
        onChange={onChange}
      />
    </Button>
  );
};

export default FileInput;
