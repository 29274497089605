import {
  INearpodCharacterCampaignFormData,
  INearpodCharacterCampaign,
  INearpodCharacterCampaignReducerState,
} from "common/interfaces/nearpod-character-campaign.interface";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getNearpodCharacterCampaign = (state: IStore) =>
  state.nearpodCharacterCampaign;

export const getNearpodCharacterCampaignData = (): OutputSelector<
  IStore,
  INearpodCharacterCampaign,
  (res: INearpodCharacterCampaignReducerState) => INearpodCharacterCampaign
> =>
  createSelector([getNearpodCharacterCampaign], (data) => {
    return data.campaign;
  });

export const getNearpodCharacterCampaignFormData = (): OutputSelector<
  IStore,
  INearpodCharacterCampaignFormData,
  (
    res: INearpodCharacterCampaignReducerState
  ) => INearpodCharacterCampaignFormData
> =>
  createSelector([getNearpodCharacterCampaign], (data) => {
    return {
      name: data.campaign.name,
      email: data.campaign.email,
      institution: data.campaign.institution,
      country: data.campaign.country,
      state: data.campaign.state,
    };
  });

export const getFaceAsset = (): OutputSelector<
  IStore,
  string,
  (res: INearpodCharacterCampaignReducerState) => string
> =>
  createSelector([getNearpodCharacterCampaign], (data) => {
    return data.faceAsset;
  });

export const getLoading = (): OutputSelector<
  IStore,
  boolean,
  (res: INearpodCharacterCampaignReducerState) => boolean
> =>
  createSelector([getNearpodCharacterCampaign], (data) => {
    return data.loading;
  });
