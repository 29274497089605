/* eslint-disable @typescript-eslint/no-explicit-any */
import AvatarImage from "components/AvatarImage/AvatarImage";
import React, { createRef, FunctionComponent, MutableRefObject } from "react";
import Character from "assets/characters/characters.json";
import html2canvas from "html2canvas";
import { isMobile } from "react-device-detect";
import Badge from "components/Badge/Badge";
import { base64ToFile } from "helper/imageCropper.helper";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import bgAnim from "./bgAnim.json";
import { IProps } from "./types";
import {
  AvatarButtonWrapper,
  AvatarWrapper,
  BackButton,
  BackButtonContainer,
  Background,
  BackText,
  BadgeButtonWrapper,
  BadgeWrapper,
  Button,
  Container,
  TitleWrapper,
  ImageTitle,
  Subtitle,
  ButtonWrapper,
  ImagesWrapper,
} from "./styles";

const Step3: FunctionComponent<IProps> = (props: IProps) => {
  const avatarRef: MutableRefObject<null> = createRef();
  const badgeRef: MutableRefObject<null> = createRef();

  const handleDownloadImage = async (isAvatar: boolean) => {
    const element: any = isAvatar ? avatarRef.current : badgeRef.current;
    if (element) {
      const canvas = await html2canvas(element, {
        useCORS: true,
        windowWidth: 1920,
        windowHeight: 1080,
      });

      const data = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.download = "image.png";
        link.href = data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        const newFile = await base64ToFile(data, "image.png", "image/png");
        props.uploadExport(newFile, isAvatar);
      }
    }
  };

  const onCreateAndExport = async () => {
    const avatarElement = avatarRef.current;
    const badgeElement = badgeRef.current;
    if (avatarElement && badgeElement) {
      const avatarCanvas = await html2canvas(avatarElement, {
        useCORS: true,
        windowWidth: 1920,
        windowHeight: 1080,
      });
      const badgeCanvas = await html2canvas(badgeElement, {
        useCORS: true,
        windowWidth: 1920,
        windowHeight: 1080,
      });

      const avatarData = avatarCanvas.toDataURL("image/png");
      const badgeData = badgeCanvas.toDataURL("image/png");
      const avatarFile = await base64ToFile(
        avatarData,
        "image.png",
        "image/png"
      );
      const badgeFile = await base64ToFile(badgeData, "image.png", "image/png");
      props.onCreateAndExport(avatarFile, badgeFile);
    }
  };

  return (
    <Background>
      <LottiePlayer
        animationJsonPath={bgAnim}
        autoplay
        loop
        segments={[22, 374]}
        preserveAspectRatio="xMidYMid meet"
        name="Background Animation"
      />
      <Container>
        <TitleWrapper>
          <ImageTitle
            src={
              !isMobile
                ? "nearpodAvatarCampaign/title_create_&_save.svg"
                : "nearpodAvatarCampaign/title_preview_images.svg"
            }
          />
          <Subtitle>These are your images!</Subtitle>
        </TitleWrapper>
        <ImagesWrapper>
          <BadgeWrapper ref={badgeRef}>
            <Badge
              faceImage={props.faceImage}
              bodyImage={
                Character.characters[props.data.character].badgeCharacter[
                  props.data.color
                ]
              }
              tShirtImage={
                Character.characters[props.data.character].tshirts[
                  props.data.shirt
                ]
              }
              shortImage={
                Character.characters[props.data.character].shorts[
                  props.data.short
                ]
              }
              capImage={
                Character.characters[props.data.character].caps[props.data.cap]
              }
              name={props.data.name}
              region={props.data.state ? props.data.state : props.data.country}
              institution={props.data.institution}
              character={props.data.character}
            />
          </BadgeWrapper>
          <AvatarWrapper ref={avatarRef}>
            <AvatarImage
              faceImage={props.faceImage}
              bodyImage={
                Character.characters[props.data.character].avatarCharacter[
                  props.data.color
                ]
              }
              tShirtImage={
                Character.characters[props.data.character].tshirts[
                  props.data.shirt
                ]
              }
              capImage={
                Character.characters[props.data.character].caps[props.data.cap]
              }
              character={props.data.character}
            />
          </AvatarWrapper>
        </ImagesWrapper>
        <ButtonWrapper>
          <BackButtonContainer onClick={props.backToSelectCharacter}>
            <BackButton />
            <BackText>Back</BackText>
          </BackButtonContainer>
          {!isMobile && (
            <BadgeButtonWrapper>
              <Button onClick={() => handleDownloadImage(false)}>
                Download to Share
              </Button>
            </BadgeButtonWrapper>
          )}
          {!isMobile && (
            <AvatarButtonWrapper>
              <Button onClick={() => handleDownloadImage(true)}>
                Download Profile Picture
              </Button>
            </AvatarButtonWrapper>
          )}
          {isMobile && (
            <Button onClick={onCreateAndExport}>Create & Save</Button>
          )}
        </ButtonWrapper>
      </Container>
      {props.isLoading && <LoadingScreen />}
    </Background>
  );
};
export default Step3;
