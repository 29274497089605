import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as assetActions from "redux/actions/asset.actions";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import { hideSuccessNotification } from "redux/actions/success.actions";
import i18next from "i18next";
import "./i18n";
import DashboardView from "views/DashboardView/DashboardView";
import { Data } from "components/DashboardTable/types";
import getAssetSelector, { getCount } from "redux/selectors/asset.selector";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";

const AssetDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [actualPage, setActualPage] = useState(0);
  const getAssetData = useSelector(getAssetSelector());
  const getAssetCount = useSelector(getCount());
  const successSelector = useSelector(getSuccessSelector());
  const successText = useSelector(getSuccessText());
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(assetActions.onInitializeAssetDashboard(query, actualPage));
  }, [dispatch, actualPage]);

  useEffect(() => {
    if (getAssetData.assets.length) {
      const assetKeys = Object.keys(getAssetData.assets[0]);
      setKeys(assetKeys);
    }
    return () => {
      setKeys([]);
    };
  }, [getAssetData]);

  const onClickAdd = () => {
    dispatch(assetActions.onSelectAdd());
  };

  const onDeleteAsset = (assetsIds: string[]) => {
    dispatch(assetActions.onDeleteAsset(assetsIds));
  };

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  const search = (query: string) => {
    dispatch(assetActions.onInitializeAssetDashboard(query, actualPage));
  };

  return (
    <DashboardLayout>
      {successSelector && successText === SUCCESS_TEXT.created && (
        <SuccessAlert message={i18next.t("ASSET:CREATED")} />
      )}
      {successSelector && successText === SUCCESS_TEXT.deleted && (
        <SuccessAlert message={i18next.t("ASSET:DELETED")} />
      )}
      <DashboardView
        search={search}
        data={getAssetData.assets as Data[]}
        keys={keys}
        title="Assets"
        onClickAdd={onClickAdd}
        actualPage={actualPage}
        onDelete={onDeleteAsset}
        setPage={setActualPage}
        count={getAssetCount}
      />
    </DashboardLayout>
  );
};

export default AssetDashboardContainer;
