import React, { FunctionComponent } from "react";
import i18next from "i18next";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import { IRegisterViewProps } from "./types";
import {
  RegisterContainer,
  RegisterContent,
  RegisterFormContainer,
  Title,
} from "./styles";
import "./i18n";

const RegisterView: FunctionComponent<IRegisterViewProps> = (
  props: IRegisterViewProps
) => {
  const { onSubmit, userEmail, isLoading } = props;

  return (
    <RegisterContainer data-testid="register-view">
      {!isLoading && (
        <RegisterContent>
          <Title>{i18next.t("REGISTER_VIEW:TITLE")}</Title>
          <RegisterFormContainer>
            {userEmail ? (
              <RegisterForm onSubmit={onSubmit} userEmail={userEmail} />
            ) : (
              <>{i18next.t("REGISTER_VIEW:TOKEN_ERROR")}</>
            )}
          </RegisterFormContainer>
        </RegisterContent>
      )}
    </RegisterContainer>
  );
};

export default RegisterView;
