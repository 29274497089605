import { ISuccessReducerState } from "redux/reducers/success.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getSuccess = (state: IStore) => state.success;

const getSuccessSelector = (): OutputSelector<
  IStore,
  boolean,
  (res: ISuccessReducerState) => boolean
> => createSelector([getSuccess], (success) => success.isShown);

export const getSuccessText = (): OutputSelector<
  IStore,
  string,
  (res: ISuccessReducerState) => string
> => createSelector([getSuccess], (success) => success.successText);

export default getSuccessSelector;
