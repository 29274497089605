import ContainerFluid from "components/ContainerFluid/ContainerFluid";
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSubmitForgotPasswordRequest } from "redux/actions/user.actions";
import {
  getUserError,
  getUserIsLoading,
  getUserSucceed,
} from "redux/selectors/user.selector";
import ForgotPasswordView from "views/ForgotPasswordView/ForgotPasswordView";
import { IForgotPasswordFormData } from "views/ForgotPasswordView/types";

const ForgotPassword: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getUserIsLoading());
  const error = useSelector(getUserError());
  const succeed = useSelector(getUserSucceed());

  const onSubmit = (formData: IForgotPasswordFormData) => {
    dispatch(onSubmitForgotPasswordRequest(formData));
  };

  return (
    <ContainerFluid>
      <ForgotPasswordView
        onSubmit={onSubmit}
        isLoading={isLoading}
        error={error}
        succeed={succeed}
      />
    </ContainerFluid>
  );
};

export default ForgotPassword;
