import React, { FunctionComponent, useState } from "react";
import { useResize } from "helper/resize.helper";
import Step2 from "../components/Step2/Step2";
import { OpacityContainer, ViewContainer } from "../styles";
import { IProps } from "./types";
import BackgroundAnim from "../components/BackgroundAnim/BackgroundAnim";
import Step3 from "../components/Step3/Step3";

const WellnetGeneralEOYView: FunctionComponent<IProps> = (props: IProps) => {
  const [hasFinishedStart, setHasFinishedStart] = useState(false);
  const [widthValue, handleWindowSizeChangeMethod, heightValue]: (
    | number
    | (() => void)
  )[] = useResize();
  const width = widthValue as number;
  const height = heightValue as number;
  const handleWindowSizeChange = handleWindowSizeChangeMethod as () => void;

  window.addEventListener("resize", handleWindowSizeChange);

  return (
    <ViewContainer width={width} height={height}>
      <BackgroundAnim setAnimationStarted={setHasFinishedStart} />
      {props.step === 2 && hasFinishedStart && (
        <OpacityContainer>
          <Step2
            onClickAccept={props.onClickAccept}
            back={props.back}
            onClickContinue={props.onClickContinue}
          />
        </OpacityContainer>
      )}
      {props.step === 3 && <Step3 onClickBack={props.onClickBack} />}
    </ViewContainer>
  );
};

export default WellnetGeneralEOYView;
