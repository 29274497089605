import { ITextVariable } from "common/interfaces/text-variable.interface";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "redux/actions/share.actions";
import getTemplateSelector from "redux/selectors/template.selector";
import AddShareCampaignView from "views/addShareCampaign/addShareCampaign";

const AddShareCampaignContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getTemplateData = useSelector(getTemplateSelector());
  const { id }: { id: string } = useParams();

  useEffect(() => {
    dispatch(actions.onInitializeAddShareCampaign(id));
  }, [dispatch, id]);

  const addShareCampaign = (values: ITextVariable[]) => {
    dispatch(actions.onAddShareCampaign(values, id));
  };

  return (
    <DashboardLayout>
      <AddShareCampaignView
        textVariables={getTemplateData.template.textVariables}
        addShareCampaign={addShareCampaign}
      />
    </DashboardLayout>
  );
};

export default AddShareCampaignContainer;
