import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "configs/configs";
import { getCookie } from "helper/cookies";
import * as Sentry from "@sentry/browser";

const api = axios.create({
  baseURL: API_BASE_URL,
});

/*
// If token is expired
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      return api
        .post('auth/refresh-token', { refreshToken: getCookie('refreshToken') })
        .then(({ data }) => {
          saveUserCookies(data.token);
          const accessToken = getCookie('accessToken');
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        });
    }
    return Promise.reject(error);
  },
);
*/

function getOptions() {
  const accessToken = getCookie("accessToken");
  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  };
  return options;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function errorResponse(error: any) {
  const data = error;
  const message = data?.errorMessage || error.message;
  if (data.error !== undefined) {
    const apiError = { err: data.error };
    Sentry.configureScope((scope) => scope.setExtra("API Error", apiError));
    Sentry.captureException(new Error(message));
  }
  throw new Error(message);
}

export async function get(url: string, headers = {}): Promise<AxiosResponse> {
  try {
    const options = { ...getOptions(), ...headers };
    const response = await api.get(url, options);
    if (!response.data.success) {
      throw errorResponse(response.data);
    }
    return response.data;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function post(
  url: string,
  params = {},
  headers = {}
): Promise<AxiosResponse> {
  try {
    const options = { ...getOptions(), ...headers };
    const response = await api.post(url, params, options);
    if (!response.data.success) {
      throw errorResponse(response);
    }
    return response.data;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function put(
  url: string,
  params = {},
  headers = {}
): Promise<AxiosResponse> {
  try {
    const options = { ...getOptions(), ...headers };
    const response = await api.put(url, params, options);
    if (!response.data.success) {
      throw errorResponse(response);
    }
    return response.data;
  } catch (error) {
    throw errorResponse(error);
  }
}
