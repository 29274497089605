import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "STEP_2", {
  TITLE:
    "WHILE YOU ENJOY SOME R&R THIS HOLIDAY, CATCH UP ON ANYTHING YOU MISSED.",
  SUBSCRIBE_QUESTION: "Can we add you to our upcoming, 2022 newsletter list?",
  YES: "Yes",
  NO: "Thanks, not just yet.",
  ACCEPT: "Submit",
  CONTINUE: "Continue",
});
