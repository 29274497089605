import { Button, Grid, Input, InputLabel } from "@material-ui/core";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import React, { useState, useEffect, FunctionComponent } from "react";
import i18next from "i18next";
import "./i18n";
import { Container } from "./styles";
import { IProps } from "./types";

const AddShareCampaignView: FunctionComponent<IProps> = (props: IProps) => {
  const [values, setValues] = useState<ITextVariable[]>([]);

  useEffect(() => {
    const textVariablesFiltered = props.textVariables.filter(
      (textVariable: ITextVariable) => textVariable.type !== 1
    );
    const newValues = [...textVariablesFiltered];
    setValues(newValues);
  }, [props.textVariables]);

  const editVariables = () => {
    props.addShareCampaign(values);
  };

  const onChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    textVariableId: string,
    index: number
  ) => {
    const items = [...values];
    const item = values.find(
      (value: ITextVariable) => value._id === textVariableId
    );
    if (item) {
      if (item.type === 2) {
        item.ctaLink = event.target.value;
        items[index] = item;
        setValues(items);
      } else {
        item.value = event.target.value;
        items[index] = item;
        setValues(items);
      }
    }
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {values.map((value: ITextVariable, index: number) => {
          return (
            <Grid key={value._id} item xs={12} sm={6} spacing={3}>
              <InputLabel id="templates-select-label">{value.name}</InputLabel>
              {value.type === 2 ? (
                <Input
                  value={value.ctaLink}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeValue(event, value._id, index)
                  }
                />
              ) : (
                <Input
                  value={value.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeValue(event, value._id, index)
                  }
                />
              )}
            </Grid>
          );
        })}
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={editVariables}>
            {i18next.t("ADD_SHARE:BUTTON_TEXT")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default AddShareCampaignView;
