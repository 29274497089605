import { COLORS } from "common/constants/color.contants";
import styled, { css } from "styled-components";
import { IButtonProps } from "./types";

export const CustomButton = styled.button`
  border: 0;
  color: ${COLORS.white};
  font: normal normal bold 14px/18px;
  padding: 14px 30px;
  border-radius: 4px;
  outline: none;

  ${({ disabled }: IButtonProps) =>
    disabled
      ? css`
          background: ${COLORS.gray};
          cursor: not-allowed;
        `
      : css`
          background: ${COLORS.sanMarino};
          cursor: pointer;
        `}
  ${({ disabled }: IButtonProps) =>
    !disabled &&
    css`
      :hover {
        background: ${COLORS.luckyPoint};
        color: ${COLORS.white};
      }
    `}
`;
