import React, { FunctionComponent } from "react";
import CSVReader from "react-csv-reader";
import "./i18n";
import { Container } from "./styles";
import { IProps } from "./types";

const ImportCsvForm: FunctionComponent<IProps> = (props: IProps) => {
  const onFileLoaded = (data: string[][]) => {
    const headers = data.shift();
    if (headers) {
      props.setCsvHeaders(headers);
    }
    props.setCsvArray(data);
  };

  return (
    <Container>
      <CSVReader onFileLoaded={onFileLoaded} />
    </Container>
  );
};

export default ImportCsvForm;
