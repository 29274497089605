/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/html-has-lang */
import React, { FunctionComponent, useEffect, useState } from "react";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import { ViewContainer } from "views/WellnetEOY/styles";
import { useResize } from "helper/resize.helper";
import axios from "axios";
import {
  Container,
  FullScreenContainer,
  OpacityContainer,
  ThankYouText,
} from "./styles";
import "./psh-eoy-styles.css";
import Form from "./Form/Form";

interface IProps {
  jsonData: any;
  name: string;
}

const PshEoyView: FunctionComponent<IProps> = ({ jsonData, name }) => {
  const [widthValue, handleWindowSizeChangeMethod, heightValue]: (
    | number
    | (() => void)
  )[] = useResize();
  const width = widthValue as number;
  const height = heightValue as number;
  const [hasFinished, setHasFinished] = useState(false);
  const [hasFormSent, setHasFormSent] = useState(false);

  const onComplete = () => {
    setHasFinished(true);
  };

  const onClickNotify = async (stars: number, text: string) => {
    try {
      await axios.request({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: `https://api.hsforms.com/submissions/v3/integration/submit/5511705/a97063cc-5228-4186-b7e5-9492f1a21d08`,
        data: JSON.stringify({
          fields: [
            {
              name: "puntaje_estrellas",
              value: stars,
            },
            {
              name: "message",
              value: text,
            },
            {
              name: "firstname",
              value: name,
            },
            {
              name: "website",
              value: window.location.href,
            },
          ],
        }),
      });
      setHasFormSent(true);
    } catch (error) {
      setHasFormSent(false);
    }
  };

  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("full--screen--container");
    document.documentElement.classList.add("html--container-lottie");
    document.getElementById("root")?.classList.add("lottie-root-container");
    return () => {
      document.body.classList.remove("full--screen--container");
      document.documentElement.classList.remove("html--container-lottie");
      document
        .getElementById("root")
        ?.classList.remove("lottie-root-container");
    };
  }, []);

  const handleWindowSizeChange = handleWindowSizeChangeMethod as () => void;

  window.addEventListener("resize", handleWindowSizeChange);

  return hasFinished ? (
    <ViewContainer width={width} height={height}>
      <OpacityContainer>
        {hasFormSent ? (
          <Container>
            <ThankYouText>Thank you</ThankYouText>
          </Container>
        ) : (
          <Form onClickDone={onClickNotify} />
        )}
      </OpacityContainer>
    </ViewContainer>
  ) : (
    <FullScreenContainer>
      <LottiePlayer
        animationJsonPath={jsonData}
        autoplay
        loop={false}
        preserveAspectRatio="xMidYMid Meet"
        onComplete={onComplete}
      />
    </FullScreenContainer>
  );
};

export default PshEoyView;
