import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import i18next from "i18next";
import "./i18n";
import { isMobile } from "react-device-detect";
import React, { FunctionComponent } from "react";
import NotFound404 from "./404_desktop.json";
import NotFoundMobile404 from "./404_mobile.json";
import { Container, NotFound, TextContainer, Title } from "./style";

const NotFoundPage: FunctionComponent = () => {
  return (
    <Container>
      <LottiePlayer
        animationJsonPath={isMobile ? NotFoundMobile404 : NotFound404}
        autoplay={false}
        loop
        segments={[43, 269]}
      />
      <TextContainer>
        <NotFound>404</NotFound>
        <Title>{i18next.t("NOT_FOUND_PAGE:TITLE")}</Title>
        <Title>{i18next.t("NOT_FOUND_PAGE:SUBTITLE")}</Title>
      </TextContainer>
    </Container>
  );
};

export default NotFoundPage;
