import { IMetric, IMetricData } from "common/interfaces/metric.interface";
import { get, post, put } from "./api";

export const getMetricsData = async (
  campaignId: string
): Promise<IMetricData> => {
  const response = await get(`metric/${campaignId}`);
  return response.data;
};

export const createMetric = async (
  shareCampaignId: string
): Promise<string> => {
  const response = await post(`metric/`, { shareCampaignId });
  return response.data;
};

export const updateWatchedTime = async (
  id: string,
  lastSecondPlayed: number
): Promise<IMetric> => {
  const response = await put(`metric/update`, { id, lastSecondPlayed });
  return response.data;
};

export const updateAudioClicked = async (id: string): Promise<IMetric> => {
  const response = await put(`metric/audio`, { id });
  return response.data;
};

export const updateCTAClicked = async (id: string): Promise<IMetric> => {
  const response = await put(`metric/cta`, { id });
  return response.data;
};
