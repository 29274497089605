import { IAPIError } from "common/interfaces/api.interface";
import { AnyAction } from "redux";
import { takeLatest, call, all, put, select } from "redux-saga/effects";
import { getEmail } from "redux/selectors/wellnetEOY.selector";
import * as services from "service/wellnetEOY.service";
import * as wellnetActions from "../actions/wellnetEOY.actions";
import * as wellnetConstants from "../constants/wellnetEOY.constants";

function* saveData(action: AnyAction) {
  try {
    const email: string = yield select(getEmail());
    yield call(services.saveData, action.data, action.subscription, email);
    yield put(wellnetActions.onSendDataSuccess());
  } catch (err) {
    yield put(wellnetActions.onSendDataFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(wellnetConstants.ON_SEND_DATA_REQUEST, saveData),
]);
