import { ICampaign } from "common/interfaces/campaign.interface";
import { AnyAction } from "redux";
import {
  ON_DELETE_CAMPAIGNS_SUCCEED,
  ON_RETRIEVE_CAMPAIGNS_SUCCEED,
  ON_RETRIEVE_CAMPAIGN_COUNT_SUCCEED,
} from "redux/constants/campaign.constants";

export interface ICampaignReducerState {
  campaigns: ICampaign[];
  count: number;
}

const defaultState: ICampaignReducerState = {
  campaigns: [],
  count: 0,
};

const addCampaigns = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    campaigns: action.data,
  };
};

const addCampaignCount = (state: ICampaignReducerState, action: AnyAction) => {
  return {
    ...state,
    count: action.count,
  };
};

const campaignReducer = (
  state: ICampaignReducerState = defaultState,
  action: AnyAction
): ICampaignReducerState => {
  switch (action.type) {
    case ON_RETRIEVE_CAMPAIGNS_SUCCEED:
    case ON_DELETE_CAMPAIGNS_SUCCEED:
      return addCampaigns(state, action);
    case ON_RETRIEVE_CAMPAIGN_COUNT_SUCCEED:
      return addCampaignCount(state, action);
    default:
      return state;
  }
};

export default campaignReducer;
