import { AxiosResponse } from "axios";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { get, put } from "./api";

export const getTenTextVariablesData = async (
  query: string,
  page: number
): Promise<AxiosResponse> => {
  let response;
  if (query === "") {
    response = await get(`text-variable/get/${page}/`);
  } else {
    response = await get(`text-variable/get/${page}/${query}`);
  }

  return response.data;
};

export const deleteTextVariables = async (
  textVariablesIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    textVariablesIds,
  };
  const response = await put(`text-variable/delete`, data);
  return response.data;
};

export const editTextVariable = async (
  textVariable: ITextVariable
): Promise<AxiosResponse> => {
  const data = {
    textVariable,
  };
  const response = await put(`text-variable/edit`, data);
  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`text-variable/get-count`);
  return response.data;
};
