import { takeLatest, call, all, put } from "redux-saga/effects";
import { AnyAction } from "redux";
import { IAsset } from "common/interfaces/file.interface";
import {
  uploadAssetsService,
  uploadPublicAssetService,
  uploadPublicAssetServiceTermsAndConditions,
} from "service/upload.service";
import * as services from "service/asset.service";
import { IAPIError } from "common/interfaces/api.interface";
import { saveExport } from "service/nearpod-character-campaign.service";
import { INearpodCharacterCampaign } from "common/interfaces/nearpod-character-campaign.interface";
import * as assetActions from "../actions/asset.actions";
import * as constants from "../constants/asset.constants";

function* getPaginatedAssets(action: AnyAction) {
  try {
    const response: IAsset[] = yield call(
      services.getTenAssetsService,
      action.query,
      action.page
    );
    yield put(assetActions.onGetAssetsSucceed(response));
  } catch (err) {
    yield put(assetActions.onGetAssetFailed(err as IAPIError));
  }
}

function* getAssetCount() {
  try {
    const count: number = yield call(services.getCount);
    yield put(assetActions.getAssetCountSucceed(count));
  } catch (err) {
    yield put(assetActions.getAssetCountFailed(err as IAPIError));
  }
}

function* uploadAssets(action: AnyAction) {
  try {
    yield call(uploadAssetsService, action.files);
    yield put(assetActions.onAddAssetSucceed());
  } catch (err) {
    yield put(assetActions.onUploadFailed(err as IAPIError));
  }
}

function* uploadCropAsset(action: AnyAction) {
  try {
    const asset: IAsset = yield call(uploadPublicAssetService, action.file);
    if (asset && asset._id) {
      const assetPath: string = yield call(
        services.getAssetImagePath,
        asset._id
      );
      yield put(assetActions.onAddCropAssetSucceed(assetPath));
    }
  } catch (err) {
    yield put(assetActions.onUploadFailed(err as IAPIError));
  }
}

function* uploadExportAsset(action: AnyAction) {
  try {
    const asset: IAsset = yield call(uploadPublicAssetService, action.file);
    if (asset && asset._id) {
      const assetPath: string = yield call(
        services.getAssetImagePath,
        asset._id
      );

      const campaign: INearpodCharacterCampaign = yield call(
        saveExport,
        action._id,
        assetPath,
        action.isAvatar
      );

      yield put(assetActions.onExportAssetSucceed(campaign));
    }
  } catch (err) {
    yield put(assetActions.onUploadFailed(err as IAPIError));
  }
}

function* onDeleteAssets(action: AnyAction) {
  try {
    const response: IAsset[] = yield call(
      services.deleteAssets,
      action.assetsIds
    );
    yield put(assetActions.onDeleteAssetSucceed(response));
  } catch (err) {
    yield put(assetActions.onDeleteAssetFailed(err as IAPIError));
  }
}

function* onUploadPublicAsset(action: AnyAction) {
  try {
    const response: IAsset = yield call(uploadPublicAssetService, action.file);
    yield put(assetActions.onAddPublicAssetSucceed(response));
  } catch (err) {
    yield put(assetActions.onAddPublicAssetFailed(err as IAPIError));
  }
}

function* onUploadPublicAssetTermsAndConditions(action: AnyAction) {
  try {
    const response: IAsset = yield call(
      uploadPublicAssetServiceTermsAndConditions,
      action.file,
      action.uuid
    );
    yield put(assetActions.onAddPublicAssetTermsAndConditionsSucceed(response));
  } catch (err) {
    yield put(
      assetActions.onAddPublicAssetTermsAndConditionsFailed(err as IAPIError)
    );
  }
}

function* submitTCDownload(action: AnyAction) {
  try {
    const response: string = yield call(
      services.downloadTC,
      action.downloadText
    );
    yield put(assetActions.onSubmitDownloadTCSucceed(response));
  } catch (err) {
    yield put(assetActions.onSubmitDownloadTCFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(
    constants.ON_INITIALIZE_ASSETS_DASHBOARD_REQUESTED,
    getPaginatedAssets
  ),
  takeLatest(constants.ON_INITIALIZE_ASSETS_DASHBOARD_REQUESTED, getAssetCount),
  takeLatest(constants.ON_SUBMIT_ASSET_REQUESTED, uploadAssets),
  takeLatest(constants.ON_SUBMIT_CROP_ASSET_REQUESTED, uploadCropAsset),
  takeLatest(constants.ON_EXPORT_REQUESTED, uploadExportAsset),
  takeLatest(constants.ON_DELETE_ASSET_REQUESTED, onDeleteAssets),
  takeLatest(constants.ON_ADD_PUBLIC_ASSET_REQUESTED, onUploadPublicAsset),
  takeLatest(
    constants.ON_ADD_PUBLIC_ASSET_TERMS_AND_CONDITIONS_REQUESTED,
    onUploadPublicAssetTermsAndConditions
  ),
  takeLatest(constants.ON_SUBMIT_TC_DOWNLOAD_REQUESTED, submitTCDownload),
]);
