import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Button from "components/Button/Button";
import i18next from "i18next";
import AddTooltip from "components/AddTooltip/AddTooltip";
import { ICSVRow } from "views/importCsvView/types";
import { Data, IProps, Order } from "./types";
import "./i18n";
import EnhancedTableHead from "./components/EnhancedTableHead/EnhancedTableHead";
import EnhancedTableToolbar from "./components/EnhancedTableToolbar/EnhancedTableToolbar";
import useStyles, {
  AddContainer,
  ButtonsWrapper,
  TextContainer,
} from "./styles";
import SearchBarComponent from "../SearchBar/SearchbarComponent";

const DashboardTable: FunctionComponent<IProps> = (props: IProps) => {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("_id");
  const [selected, setSelected] = useState<string[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [rows, setRows] = useState<ICSVRow[] | Data[]>([]);

  useEffect(() => {
    if (props.data.length) {
      setRows(props.data as Data[]);
    }
  }, [props.data]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.data.map((n) => {
        return n._id;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setPage(newPage);
  };

  const selectCampaign = (campaignId: string) => {
    if (props.selectCampaign) {
      props.selectCampaign(campaignId);
    }
  };

  const onClickViewMetrics = (campaignId: string) => {
    if (props.onClickViewMetrics) {
      props.onClickViewMetrics(campaignId);
    }
  };

  const onCreateMetricsData = (campaignId: string) => {
    if (props.onCreateMetricsData) {
      props.onCreateMetricsData(campaignId);
    }
  };

  const handleAddClick = () => {
    if (props.onClickAdd) {
      props.onClickAdd();
    }
  };

  const handleEdit = (id: string) => {
    if (props.onEdit) {
      props.onEdit(id);
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const deleteCampaigns = () => {
    if (props.onDelete) {
      props.onDelete(selected);
      setSelected([]);
    }
  };

  const onCreateShareCampaigns = (campaignId: string) => {
    if (props.createShareCampaign) {
      props.createShareCampaign(campaignId);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <SearchBarComponent
          data={props.data}
          setSearched={setSearched}
          searched={searched}
          search={props.search}
        />
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={props.title}
          onClick={deleteCampaigns}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            {!!props.keys.length && (
              <EnhancedTableHead
                headers={props.keys}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.data.length}
              />
            )}
            <TableBody>
              {!!props.data.length &&
                rows.map((tableData: Data | ICSVRow, index: number) => {
                  const isItemSelected = isSelected(tableData._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={tableData._id}
                      selected={isItemSelected}
                      className={classes.table}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, tableData._id)}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      {props.keys.map((key: string) => {
                        return (
                          <>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              key={key}
                            >
                              <TextContainer>{tableData[key]}</TextContainer>
                            </TableCell>
                          </>
                        );
                      })}
                      {props.onEdit && (
                        <Button onClick={() => handleEdit(tableData._id)}>
                          Edit
                        </Button>
                      )}
                      {props.isCampaignDashboard && (
                        <ButtonsWrapper>
                          <Button onClick={() => selectCampaign(tableData._id)}>
                            {i18next.t("DASHBOARD_TABLE:SHARE_CAMPAIGN_BUTTON")}
                          </Button>
                          <Button
                            onClick={() => onCreateMetricsData(tableData._id)}
                          >
                            {i18next.t("DASHBOARD_TABLE:METRICS_ADD_BUTTON")}
                          </Button>
                          <Button
                            onClick={() => onClickViewMetrics(tableData._id)}
                          >
                            {i18next.t("DASHBOARD_TABLE:METRICS_BUTTON")}
                          </Button>
                          <Button
                            onClick={() =>
                              onCreateShareCampaigns(tableData._id)
                            }
                          >
                            {i18next.t(
                              "DASHBOARD_TABLE:CREATE_SHARE_CAMPAIGNS"
                            )}
                          </Button>
                        </ButtonsWrapper>
                      )}
                      {props.isTemplateDashboard && (
                        <Button
                          onClick={() =>
                            window.open(
                              `templates/preview/${tableData._id}`,
                              "_blank"
                            )
                          }
                        >
                          {i18next.t("DASHBOARD_TABLE:PREVIEW")}
                        </Button>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={props.count}
          rowsPerPage={10}
          page={props.page}
          onPageChange={handleChangePage}
        />
      </Paper>
      {props.onClickAdd && (
        <AddContainer>
          <AddTooltip onClick={handleAddClick} />
        </AddContainer>
      )}
    </div>
  );
};

export default DashboardTable;
