import { IAPIError } from "common/interfaces/api.interface";
import {
  IShare,
  IShareDataResponse,
  IVariable,
} from "common/interfaces/share.interface";
import { ITemplate } from "common/interfaces/template.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { AnyAction } from "redux";
import { IHeaderMatch } from "views/importCsvView/types";
import * as constants from "../constants/share.constants";

export const onInitializePlayer = (shareId: string): AnyAction => ({
  type: constants.ON_INITIALIZE_PLAYER_REQUESTED,
  shareId,
});

export const onInitializePlayerSucceed = (
  data: IShareDataResponse
): AnyAction => ({
  type: constants.ON_INITIALIZE_PLAYER_SUCCEED,
  data,
});

export const onInitializePlayerFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_INITIALIZE_PLAYER_FAILED,
  error,
});

export const onInitializeMatchCSV = (campaignId: string): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_CSV_REQUESTED,
  campaignId,
});

export const onInitializeMatchCSVSucceed = (
  template: ITemplate
): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_CSV_SUCCEED,
  template,
});

export const onInitializeMatchCSVFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_INITIALIZE_MATCH_CSV_FAILED,
  error,
});

export const onCreateShareData = (
  campaignId: string,
  headersMatch: IHeaderMatch[],
  csvHeaders: string[],
  csvData: string[][]
): AnyAction => ({
  type: constants.ON_CREATE_SHARE_CAMPAIGN_REQUESTED,
  campaignId,
  headersMatch,
  csvHeaders,
  csvData,
});

export const onCreateExportDataSucceed = (row: IShare[]): AnyAction => ({
  type: constants.ON_CREATE_EXPORT_DATA_SUCCEED,
  row,
});

export const onCreateShareDataSucceed = (): AnyAction => ({
  type: constants.ON_CREATE_SHARE_CAMPAIGN_SUCCEED,
});

export const onCreateShareDataFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_CREATE_SHARE_CAMPAIGN_FAILED,
  error,
});

export const getShareCampaigns = (
  query: string,
  campaignId: string,
  page: number
): AnyAction => ({
  type: constants.ON_RETRIEVE_SHARE_CAMPAIGNS_REQUESTED,
  query,
  campaignId,
  page,
});

export const getShareCampaignsSucceed = (data: IShare[]): AnyAction => ({
  type: constants.ON_RETRIEVE_SHARE_CAMPAIGNS_SUCCEED,
  data,
});

export const getShareCampaignsFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_SHARE_CAMPAIGNS_FAILED,
  error,
});

export const onDeleteShareCampaign = (
  shareCampaignId: string[]
): AnyAction => ({
  type: constants.ON_DELETE_SHARE_CAMPAIGN_REQUESTED,
  shareCampaignId,
});

export const onDeleteShareCampaignSucceed = (data: IShare[]): AnyAction => ({
  type: constants.ON_DELETE_SHARE_CAMPAIGN_SUCCEED,
  data,
});

export const onDeleteShareCampaignFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_SHARE_CAMPAIGN_FAILED,
  error,
});

export const onEditShareCampaign = (
  values: IVariable,
  shareCampaignId: string
): AnyAction => ({
  type: constants.ON_EDIT_SHARE_CAMPAIGN_REQUESTED,
  values,
  shareCampaignId,
});

export const onEditShareCampaignSucceed = (campaignId: string): AnyAction => ({
  type: constants.ON_EDIT_SHARE_CAMPAIGN_SUCCEED,
  campaignId,
});

export const onEditShareCampaignFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_EDIT_SHARE_CAMPAIGN_FAILED,
  error,
});

export const onInitializeEditVariables = (
  shareCampaignId: string
): AnyAction => ({
  type: constants.ON_INITIALIZE_EDIT_VIEW_REQUESTED,
  shareCampaignId,
});

export const onInitializeEditVariablesSucceed = (data: {
  shareData: IShare;
}): AnyAction => ({
  type: constants.ON_INITIALIZE_EDIT_VIEW_SUCCEED,
  data,
});

export const onInitializeEditVariablesFailed = (
  error: IAPIError
): AnyAction => ({
  type: constants.ON_INITIALIZE_EDIT_VIEW_FAILED,
  error,
});

export const selectShareCampaignEdit = (
  shareCampaignId: string
): AnyAction => ({
  type: constants.ON_SELECT_SHARE_CAMPAIGN_EDIT,
  shareCampaignId,
});

export const onSelectAddShareCampaign = (campaignId: string): AnyAction => ({
  type: constants.ON_SELECT_SHARE_CAMPAIGN_ADD,
  campaignId,
});

export const onInitializeAddShareCampaign = (
  campaignId: string
): AnyAction => ({
  type: constants.ON_INITIALIZE_ADD_VIEW_REQUESTED,
  campaignId,
});

export const onInitializeAddShareCampaignSucceed = (
  template: ITemplate
): AnyAction => ({
  type: constants.ON_INITIALIZE_ADD_VIEW_SUCCEED,
  template,
});

export const onInitializeAddShareCampaignFailed = (
  error: IAPIError
): AnyAction => ({
  type: constants.ON_INITIALIZE_ADD_VIEW_FAILED,
  error,
});

export const onAddShareCampaign = (
  values: ITextVariable[],
  campaignId: string
): AnyAction => ({
  type: constants.ON_ADD_SHARE_CAMPAIGN_REQUESTED,
  values,
  campaignId,
});

export const onAddShareCampaignSucceed = (campaignId: string): AnyAction => ({
  type: constants.ON_ADD_SHARE_CAMPAIGN_SUCCEED,
  campaignId,
});

export const onAddShareCampaignFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_ADD_SHARE_CAMPAIGN_FAILED,
  error,
});

export const getShareCamapignCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_SUCCEED,
  count,
});

export const getShareCamapignCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_FAILED,
  error,
});

export const onSubmitFormSpartan = (
  email: string,
  name: string,
  friendName?: string
): AnyAction => ({
  type: constants.ON_SUBMIT_FORM_SPARTAN_REQUESTED,
  email,
  name,
  friendName,
});

export const onSubmitFormSpartanSuccess = (): AnyAction => ({
  type: constants.ON_SUBMIT_FORM_SPARTAN_SUCCEED,
});

export const onSubmitFormSpartanFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_SUBMIT_FORM_SPARTAN_FAILED,
  error,
});
