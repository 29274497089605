import React, { FunctionComponent, useEffect, useState } from "react";
import { Checkbox, TableCell, TableHead, TableRow } from "@material-ui/core";

import { EnhancedTableProps, HeadCell } from "./types";

const EnhancedTableHead: FunctionComponent<EnhancedTableProps> = (
  props: EnhancedTableProps
) => {
  const { onSelectAllClick, numSelected, rowCount } = props;
  const [headCells, setHeadCells] = useState<HeadCell[]>([]);

  useEffect(() => {
    const headCellsNew: HeadCell[] = props.headers.map((key: string) => {
      return {
        id: key,
        numeric: false,
        disablePadding: true,
        label: key,
      };
    });
    setHeadCells(headCellsNew);
  }, [props.headers]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
