import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/user.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import { getUsersData, getCount } from "redux/selectors/user.selector";
import DashboardView from "views/DashboardView/DashboardView";
import "./i18n";
import i18next from "i18next";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";
import getSuccessSelector from "redux/selectors/success.selector";
import { Data } from "components/DashboardTable/types";

const UserDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const usersData = useSelector(getUsersData());
  const [tableKeys, setTableKeys] = useState<string[]>([]);
  const [actualPage, setActualPage] = useState(0);
  const getUserCount = useSelector(getCount());
  const successSelector = useSelector(getSuccessSelector());

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(actions.onInitializeUserDashboard(query, actualPage));
  }, [dispatch, actualPage]);

  useEffect(() => {
    if (usersData.length) {
      const keys = Object.keys(usersData[0]);
      setTableKeys(keys);
    }
  }, [usersData]);

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  const search = (query: string) => {
    dispatch(actions.onInitializeUserDashboard(query, actualPage));
  };

  const onDelete = (ids: string[]) => {
    dispatch(actions.onDeleteUsers(ids));
  };

  const onCreateUser = () => {
    dispatch(actions.onRedirectToCreateUserForm());
  };
  return (
    <DashboardLayout>
      {successSelector && (
        <SuccessAlert message={i18next.t("USER_DASHBOARD:DELETED")} />
      )}
      <DashboardView
        search={search}
        data={usersData as Data[]}
        keys={tableKeys}
        title="Users"
        onClickAdd={onCreateUser}
        onDelete={onDelete}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getUserCount}
      />
    </DashboardLayout>
  );
};

export default UserDashboardContainer;
