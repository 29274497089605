import { AxiosResponse } from "axios";
import { IWellnetData } from "common/interfaces/wellnetEOY.interface";
import { post } from "./api";

export const saveData = async (
  data: IWellnetData,
  newsletter: boolean,
  email: string
): Promise<AxiosResponse> => {
  const { textArea, pollResult } = data;
  const response = await post(`wellnet-video-form/`, {
    email,
    pollResult,
    textArea,
    newsletter,
  });
  return response.data;
};
