import { IRGBColor } from "common/interfaces/color-variable.interface";

export const hexToRGB = (hex: string): IRGBColor | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseFloat((parseInt(result[1], 16) / 255).toFixed(2)),
        g: parseFloat((parseInt(result[2], 16) / 255).toFixed(2)),
        b: parseFloat((parseInt(result[3], 16) / 255).toFixed(2)),
      }
    : null;
};
