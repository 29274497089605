import { AnyAction } from "redux";
import { ON_RETRIEVE_USERS_SUCCEEDED } from "redux/constants/user.constants";
import { IUserState } from "common/interfaces/user.interface";
import * as constants from "redux/constants/user.constants";
import * as appConstants from "redux/constants/app.constants";

const defaultState: IUserState = {
  users: [],
  data: null,
  emailToRegister: "",
  isLoading: true,
  token: "",
  count: 0,
  error: false,
  succeed: false,
};

const addUsers = (state: IUserState, action: AnyAction) => {
  return {
    ...state,
    users: action.data,
  };
};

const addUserCount = (state: IUserState, action: AnyAction) => {
  return {
    ...state,
    count: action.count,
  };
};

const userLoggedSucceded = (state: IUserState, action: AnyAction) => {
  return {
    ...state,
    data: action.user,
  };
};

const initRegisterSucceeded = (state: IUserState, action: AnyAction) => {
  return {
    ...state,
    emailToRegister: action.data.email,
    isLoading: false,
  };
};

const setLoadingFinished = (state: IUserState) => {
  return {
    ...state,
    isLoading: false,
  };
};

const setToken = (state: IUserState, action: AnyAction) => {
  return {
    ...state,
    token: action.data.token,
    error: false,
  };
};

const setError = (state: IUserState) => {
  return {
    ...state,
    error: true,
    token: "",
    isLoading: false,
  };
};

const setSucceed = (state: IUserState) => {
  return {
    ...state,
    succeed: true,
    isLoading: false,
  };
};

const resetToken = (state: IUserState) => {
  return {
    ...state,
    token: "",
    error: false,
  };
};

const userReducer = (
  state: IUserState = defaultState,
  action: AnyAction
): IUserState => {
  switch (action.type) {
    case ON_RETRIEVE_USERS_SUCCEEDED:
    case constants.ON_DELETE_USERS_SUCCESS:
    case constants.ON_RETRIEVE_USER_SUCCEED:
      return addUsers(state, action);
    case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED:
    case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
    case constants.USER_ON_LOGIN_SUCCEEDED:
      return userLoggedSucceded(state, action);
    case constants.ON_INITIALIZE_REGISTER_SUCCEEDED:
      return initRegisterSucceeded(state, action);
    case constants.ON_INITIALIZE_REGISTER_FAILED:
    case constants.ON_INITIALIZE_CHANGE_PASSWORD_SUCCEEDED:
      return setLoadingFinished(state);
    case constants.ON_CREATE_USER_SUCCEEDED:
      return setToken(state, action);
    case constants.ON_CREATE_USER_FAILED:
    case constants.ON_INITIALIZE_CHANGE_PASSWORD_FAILED:
    case constants.ON_SUBMIT_FORGOT_PASSWORD_FAILED:
      return setError(state);
    case constants.ON_RETRIEVE_USER_COUNT_SUCCEED:
      return addUserCount(state, action);
    case constants.ON_SUBMIT_FORGOT_PASSWORD_SUCCEEDED:
      return setSucceed(state);
    case constants.ON_INITIALIZE_CREATE_USER:
      return resetToken(state);
    case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
    case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
    default:
      return state;
  }
};

export default userReducer;
