import React, { ChangeEvent, FunctionComponent, useState } from "react";
import i18next from "i18next";
import "./i18n";
import {
  Button,
  Container,
  Star,
  StarsWrapper,
  TextArea,
  TextAreaTitle,
  TextAreaWrapper,
  Title,
  Wrapper,
} from "./styles";
import { IProps } from "./types";

const Form: FunctionComponent<IProps> = (props: IProps) => {
  const [selectedStar, setSelectedStar] = useState(0);
  const [hoverStar, setHoverStar] = useState(0);
  const [text, setText] = useState("");

  const onClickDone = () => {
    if (selectedStar > 0) {
      props.onClickDone(selectedStar, text);
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>{i18next.t("STEP_1:TITLE")}</Title>
        <StarsWrapper>
          <Star
            isActive={(selectedStar >= 1 && hoverStar === 0) || hoverStar >= 1}
            onClick={() => setSelectedStar(1)}
            onMouseEnter={() => setHoverStar(1)}
            onMouseLeave={() => setHoverStar(0)}
          />
          <Star
            isActive={(selectedStar >= 2 && hoverStar === 0) || hoverStar >= 2}
            onClick={() => setSelectedStar(2)}
            onMouseEnter={() => setHoverStar(2)}
            onMouseLeave={() => setHoverStar(0)}
          />
          <Star
            isActive={(selectedStar >= 3 && hoverStar === 0) || hoverStar >= 3}
            onClick={() => setSelectedStar(3)}
            onMouseEnter={() => setHoverStar(3)}
            onMouseLeave={() => setHoverStar(0)}
          />
          <Star
            isActive={(selectedStar >= 4 && hoverStar === 0) || hoverStar >= 4}
            onClick={() => setSelectedStar(4)}
            onMouseEnter={() => setHoverStar(4)}
            onMouseLeave={() => setHoverStar(0)}
          />
          <Star
            isActive={(selectedStar >= 5 && hoverStar === 0) || hoverStar >= 5}
            onClick={() => setSelectedStar(5)}
            onMouseEnter={() => setHoverStar(5)}
            onMouseLeave={() => setHoverStar(0)}
          />
        </StarsWrapper>
        <TextAreaWrapper>
          <TextAreaTitle>{i18next.t("STEP_1:TEXT_AREA_TITLE")}</TextAreaTitle>
          <TextArea
            maxLength={255}
            value={text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setText(e.target.value)
            }
          />
        </TextAreaWrapper>
        <Button onClick={onClickDone} isActive={selectedStar > 0}>
          {i18next.t("STEP_1:DONE")}
        </Button>
      </Wrapper>
    </Container>
  );
};

export default Form;
