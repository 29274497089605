import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import * as actions from "redux/actions/share.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import getShareSelector, { getCount } from "redux/selectors/share.selector";
import CollapsibleTable from "components/CollapsibleTable/CollapsibleTable";
import "./i18n";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import { hideSuccessNotification } from "redux/actions/success.actions";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";
import Button from "components/Button/Button";
import * as campaignActions from "redux/actions/campaign.actions";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { IVariable } from "common/interfaces/share.interface";

const ShareCampaignDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getShareData = useSelector(getShareSelector());
  const getShareCount = useSelector(getCount());
  const { id }: { id: string } = useParams();
  const [actualPage, setActualPage] = useState(0);
  const successText = useSelector(getSuccessText());
  const [tableKeys, setTableKeys] = useState<string[]>([]);
  const successSelector = useSelector(getSuccessSelector());
  const [collapsibleTableKeys, setCollapsibleTableKeys] = useState<string[]>(
    []
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(actions.getShareCampaigns(query, id, actualPage));
  }, [dispatch, id, actualPage]);

  const deleteShareCampaign = (shareCampaignId: string[]) => {
    dispatch(actions.onDeleteShareCampaign(shareCampaignId));
  };

  const editShareCampaignVariables = (shareCampaignId: string) => {
    dispatch(actions.selectShareCampaignEdit(shareCampaignId));
  };

  const addShareCampaign = () => {
    dispatch(actions.onSelectAddShareCampaign(id));
  };

  const onCreateShareCampaigns = (campaignId: string) => {
    dispatch(campaignActions.onCreateShareCampaigns(campaignId));
  };

  useEffect(() => {
    if (getShareData.count) {
      const keys = Object.keys(getShareData.share);
      if (
        getShareData.shareCampaigns.length &&
        getShareData.shareCampaigns[0].variable
      ) {
        let collapsibleKeys: string[] = [];
        if (
          (getShareData.shareCampaigns[0].variable as ITextVariable[]).length
        ) {
          collapsibleKeys = Object.keys(
            (getShareData.shareCampaigns[0].variable as ITextVariable[])[0]
          );
        } else if (
          (getShareData.shareCampaigns[0].variable as IVariable).text
        ) {
          collapsibleKeys = Object.keys(
            (getShareData.shareCampaigns[0].variable as IVariable).text[0]
          );
        }

        setCollapsibleTableKeys(collapsibleKeys);
      }
      setTableKeys(keys.filter((key) => key !== "variable"));
    }
  }, [getShareData]);

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  const search = (query: string) => {
    dispatch(actions.getShareCampaigns(query, id, actualPage));
  };

  return (
    <DashboardLayout>
      {successSelector && successText === SUCCESS_TEXT.created && (
        <SuccessAlert message={i18next.t("SHARE_CAMPAIGN:CREATED")} />
      )}
      {successSelector && successText === SUCCESS_TEXT.deleted && (
        <SuccessAlert message={i18next.t("SHARE_CAMPAIGN:DELETED")} />
      )}
      {successSelector && successText === SUCCESS_TEXT.edited && (
        <SuccessAlert message={i18next.t("SHARE_CAMPAIGN:EDITED")} />
      )}
      <>
        <CollapsibleTable
          search={search}
          deleteShareCampaign={deleteShareCampaign}
          shareCampaigns={getShareData.shareCampaigns}
          keys={tableKeys}
          variableKeys={collapsibleTableKeys}
          editShareCampaignVariables={editShareCampaignVariables}
          addShareCampaign={addShareCampaign}
          title={i18next.t("SHARE_CAMPAIGN:TITLE")}
          actualPage={actualPage}
          setPage={setActualPage}
          count={getShareCount}
        />
        <Button onClick={() => onCreateShareCampaigns(id)}>
          {i18next.t("DASHBOARD_TABLE:CREATE_SHARE_CAMPAIGNS")}
        </Button>
      </>
    </DashboardLayout>
  );
};

export default ShareCampaignDashboardContainer;
