import {
  INearpodCharacterCampaignFormData,
  ISelectedCharacter,
} from "common/interfaces/nearpod-character-campaign.interface";
import React, { FunctionComponent, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { onAddCropAsset, onExportAsset } from "redux/actions/asset.actions";
import {
  onCreateAndExport,
  onCreateNearpodCharacterRequest,
  onSaveData,
} from "redux/actions/nearpod-character-campaign.actions";
import {
  getFaceAsset,
  getLoading,
  getNearpodCharacterCampaignData,
  getNearpodCharacterCampaignFormData,
} from "redux/selectors/nearpod-character-campaign.selector";
import NearpodCampEngageCharacterCampaignView from "views/NearpodCampEngageCharacterCampaignView/NearpodCampEngageCharacterCampaignView";

const NearpodCampEngageCharacterCampaign: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<number>(1);
  const [showYeti, setShowYeti] = useState<boolean>(false);
  const [yetiShown, setYeniShown] = useState<boolean>(false);

  setTimeout(() => {
    if (step === 2 && showYeti) {
      setShowYeti(false);
    }
  }, 4800);

  useEffect(() => {
    setTimeout(() => {
      if (step === 2 && !yetiShown) {
        setShowYeti(true);
        setYeniShown(true);
      }
    }, 1000);
  }, [step, yetiShown]);

  const nearpodCharacterCampaignFormData = useSelector(
    getNearpodCharacterCampaignFormData()
  );
  const nearpodCharacterCampaignData = useSelector(
    getNearpodCharacterCampaignData()
  );
  const faceAsset = useSelector(getFaceAsset());
  const isLoadingImage = useSelector(getLoading());

  const onConfirmCharacter = (selectedData: ISelectedCharacter) => {
    dispatch(
      onCreateNearpodCharacterRequest(
        nearpodCharacterCampaignFormData,
        selectedData
      )
    );
    setStep(3);
  };

  const addAsset = (file: File) => {
    dispatch(onAddCropAsset(file));
  };

  const uploadExport = (file: File, isAvatar: boolean) => {
    dispatch(onExportAsset(nearpodCharacterCampaignData._id, file, isAvatar));
  };

  const onSubmitForm = (data: INearpodCharacterCampaignFormData) => {
    dispatch(onSaveData(data));
    setStep(2);
  };

  const backToForm = () => {
    setStep(1);
  };

  const backToSelectCharacter = () => {
    setStep(2);
  };

  const onClickCreateAndExport = (avatarFile: File, badgeFile: File) => {
    dispatch(
      onCreateAndExport(nearpodCharacterCampaignData._id, avatarFile, badgeFile)
    );
  };

  useEffect(() => {
    if (
      isMobile &&
      nearpodCharacterCampaignData.avatarAssetPath &&
      nearpodCharacterCampaignData.badgeAssetPath
    ) {
      setStep(4);
    }
  }, [
    nearpodCharacterCampaignData.avatarAssetPath,
    nearpodCharacterCampaignData.badgeAssetPath,
  ]);
  return (
    <NearpodCampEngageCharacterCampaignView
      onSubmitForm={onSubmitForm}
      step={step}
      onConfirmCharacter={onConfirmCharacter}
      faceAsset={faceAsset}
      addAsset={addAsset}
      uploadExport={uploadExport}
      backToForm={backToForm}
      showYeti={showYeti}
      backToSelectCharacter={backToSelectCharacter}
      data={nearpodCharacterCampaignData}
      onCreateAndExport={onClickCreateAndExport}
      isLoadingImage={isLoadingImage}
    />
  );
};

export default NearpodCampEngageCharacterCampaign;
