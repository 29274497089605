import { IAPIError } from "common/interfaces/api.interface";
import { IClient } from "common/interfaces/client.interface";
import { AnyAction } from "redux";
import { takeLatest, call, all, put } from "redux-saga/effects";
import * as services from "service/client.service";
import * as clientActions from "../actions/client.actions";
import * as campaignConstants from "../constants/campaign.constants";
import * as clientConstants from "../constants/client.constants";

export interface ResponseGenerator {
  data: any;
}

function* getClients() {
  try {
    const response: IClient[] = yield call(services.getClientsService);
    yield put(clientActions.getClientsSucceed(response));
  } catch (err) {
    yield put(clientActions.getClientsFailed(err as IAPIError));
  }
}

function* getTenClients(action: AnyAction) {
  try {
    const response: IClient[] = yield call(
      services.getTenClientsService,
      action.query,
      action.page
    );
    yield put(clientActions.getClientsSucceed(response));
  } catch (err) {
    yield put(clientActions.getClientsFailed(err as IAPIError));
  }
}

function* getClientCount() {
  try {
    const count: number = yield call(services.getCount);
    yield put(clientActions.getClientCountSucceed(count));
  } catch (err) {
    yield put(clientActions.getClientCountFailed(err as IAPIError));
  }
}

function* createClient(action: AnyAction) {
  try {
    const response: IClient = yield call(services.createClient, action.name);
    yield put(clientActions.onSubmitClientCreateSuccess(response));
  } catch (err) {
    yield put(clientActions.onSubmitClientCreateFailed(err as IAPIError));
  }
}

function* deleteClients(action: AnyAction) {
  try {
    const response: IClient[] = yield call(
      services.deleteClients,
      action.clientIds
    );
    yield put(clientActions.onDeleteClientSuccess(response));
  } catch (err) {
    yield put(clientActions.onDeleteClientFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(campaignConstants.ON_INITIALIZE_CAMPAIGN_FORM, getClients),
  takeLatest(clientConstants.ON_RETRIEVE_CLIENTS_REQUESTED, getTenClients),
  takeLatest(clientConstants.ON_RETRIEVE_CLIENTS_REQUESTED, getClientCount),
  takeLatest(clientConstants.ON_SUBMIT_CLIENT_CREATE_REQUESTED, createClient),
  takeLatest(clientConstants.ON_DELETE_CLIENT_REQUESTED, deleteClients),
]);
