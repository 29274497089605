import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h2`
  font: normal normal bold 20px/57px "Poppins-Bold";
  margin-bottom: 35px;
  color: ${COLORS.fiord};
`;
export const Subtitle = styled.h3`
  font: normal normal normal 18px/28px "Poppins-Regular";
  margin-bottom: 50px;
  color: ${COLORS.fiord};
`;

export const SubmitInput = styled.div`
  width: 144px;
  height: 40px;
  background: #214569 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal bold 14px/23px "Poppins-Regular";
  color: ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background: rgb(20, 43, 65);
  }
`;
