import styled, { keyframes } from "styled-components";
import { ContainerProps } from "views/SpartanEndForm/types";
import { ReactComponent as replay } from "assets/icon-replay-spartan.svg";
import { ReactComponent as replayBlack } from "assets/icon-replay-b-spartan.svg";
import { ReactComponent as replayHover } from "assets/icon-replay-fill-01.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const FormContainer = styled.div<ContainerProps>`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.out ? "hidden" : "visible")};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;
  background-position: top;
`;

export const Replay = styled(replay)`
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 150;
`;

export const ReplayBlack = styled(replayBlack)`
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 150;
`;

export const ReplayCircleHover = styled(replayHover)`
  position: absolute;
  width: 44px;
  height: 44px;
  opacity: 0;
  z-index: 150;
`;

export const ReplayContainer = styled.div`
  width: 44px;
  height: 44px;
  position: absolute;
  transition: all 0.2s ease-out;
  bottom: 35px;
  left: 35px;
  cursor: pointer;
  &:hover {
    ${Replay} {
      opacity: 0;
    }
    ${ReplayBlack} {
      opacity: 0;
    }
    ${ReplayCircleHover} {
      opacity: 1;
    }
  }
`;
