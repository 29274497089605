import { all } from "redux-saga/effects";
import template from "./sagas/template.sagas";
import upload from "./sagas/upload.sagas";
import client from "./sagas/client.sagas";
import campaign from "./sagas/campaign.sagas";
import share from "./sagas/share.sagas";
import user from "./sagas/user.sagas";
import metric from "./sagas/metric.sagas";
import textVariable from "./sagas/text-variable.sagas";
import wellnetEOY from "./sagas/wellnetEOY.sagas";
import asset from "./sagas/asset.sagas";
import nearpodCharacterCampaign from "./sagas/nearpod-character-campaign.sagas";
import lottieJsonSagas from "./sagas/lottie-json.sagas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    template,
    upload,
    client,
    campaign,
    share,
    user,
    metric,
    asset,
    textVariable,
    nearpodCharacterCampaign,
    wellnetEOY,
    lottieJsonSagas,
  ]);
}
