import { AxiosResponse } from "axios";
import { get, post, put } from "./api";

export const getClientsService = async (): Promise<AxiosResponse> => {
  const response = await get(`client/`);
  return response.data;
};

export const getTenClientsService = async (
  query: string,
  page: number
): Promise<AxiosResponse> => {
  let response;
  if (query === "") {
    response = await get(`client/${page}/`);
  } else {
    response = await get(`client/${page}/${query}`);
  }

  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`client/get/count`);
  return response.data;
};

export const createClient = async (name: string): Promise<AxiosResponse> => {
  const response = await post(`client/`, { name });
  return response.data;
};

export const deleteClients = async (
  clientIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    clientIds,
  };
  const response = await put(`client/delete`, data);
  return response.data;
};
