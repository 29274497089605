import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import { PUBLIC_URL } from "configs/configs";
import styled, { keyframes } from "styled-components";

export const FullScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  @media screen and (max-width: ${SIZES.smallTablet}px) {
    transform: rotate(90deg) scale(1.6);
  }
`;

const fadeIn = keyframes`
//  0% { opacity: 0; }
//  100% { opacity: 1; }
  0% {background-color: rgba(255, 255, 255, 1);}
  100% {background-color: rgba(242, 245, 169, 0);}
`;

export const OpacityContainer = styled.div`
  width: 100%;
  height: 100%;
  // opacity: 0;
  z-index: 3;
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  animation-name: ${fadeIn};
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  display: flex;
  align-items: center;
  background-image: url(${PUBLIC_URL}/eoy_psh/bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
`;

export const ThankYouText = styled.p`
  width: 100%;
  max-width: 730px;
  color: ${COLORS.white};
  font-size: 60px;
  font-family: Montserrat-SemiBold;
  text-align: center;

  @media screen and (max-width: 900px) and (orientation: landscape) {
    width: 80%;
    font-size: 14px;
  }
`;
