import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";
import { SIZES } from "common/constants/sizes.contants";
import { ReactComponent as start } from "assets/wellnetEOY/star.svg";
import { IButton, IStar } from "./types";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 95%;
  }
`;

export const Wrapper = styled.div`
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 100%;
  }
`;

export const Title = styled.h1`
  width: 730px;
  color: ${COLORS.white};
  font-size: 30px;
  font-family: Montserrat-SemiBold;
  text-align: center;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 80%;
    font-size: 14px;
  }
`;

export const StarsWrapper = styled.div`
  display: flex;
  width: 65%;
  justify-content: space-evenly;

  @media screen and (max-width: ${SIZES.bigDesktop}px) {
    width: 75%;
  }

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    width: 100%;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px)and (orientation: landscape) {
    width: 70%;
    height: 25%
  }
}`;

export const Star = styled(start)<IStar>`
  cursor: pointer;
  g {
    path {
      transition: fill 0.2s ease;
      fill: ${(props) => (props.isActive ? COLORS.gargoyleGas : "transparent")};
    }
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 40px;
  }
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 110px;
  }
`;

export const TextAreaTitle = styled.p`
  color: ${COLORS.white};
  font-size: 20px;
  font-family: Montserrat-Regular;
  text-align: center;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 14px;
  }
`;

export const TextArea = styled.textarea`
  width: 640px;
  height: 160px;
  outline: none;
  resize: none;
  border: none;
  border-radius: 10px;
  border: 1px solid ${COLORS.silverSand};
  font-family: Montserrat-Regular;
  padding: 10px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 80%;
    height: 60px;
  }
`;

export const Button = styled.button<IButton>`
  width: 190px;
  height: 50px;
  border-radius: 28px;
  background-color: ${COLORS.blueCrayola};
  border: none;
  ${(props) => !props.isActive && "opacity: 0.5"};
  ${(props) => !props.isActive && "cursor: default"};
  color: ${COLORS.white};
  font-family: Montserrat-Regular;
  font-size: 24px;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: ${(props) => props.isActive && COLORS.denim};
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 140px;
    height: 35px;
    font-size: 16px;
  }
`;
