import { EMAIL_REGEX, COMPLETE_NAME_REGEX } from "helper/validations/contants";
import React, { FunctionComponent, useState } from "react";
import {
  ButtonContainer,
  ButtonText,
  CssTextField,
  FieldsContainer,
  LogoContainer,
  Paragraph,
  SquareContainer,
} from "./styles";
import { IProps } from "./types";

const Form: FunctionComponent<IProps> = (props: IProps) => {
  const [hasNameError, setHasNameError] = useState<
    "required" | "name_regex" | ""
  >("");
  const [hasEmailError, setHasEmailError] = useState<
    "required" | "email_regex" | ""
  >("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const onChangeEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasEmailError("");
    setEmail(event.target.value);
  };

  const onChangeNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasNameError("");
    setName(event.target.value);
  };

  const handleSubmit = () => {
    if (name === "") {
      setHasNameError("required");
    } else if (!COMPLETE_NAME_REGEX.test(name)) {
      setHasNameError("name_regex");
    }
    if (!email) {
      setHasEmailError("required");
    } else if (!EMAIL_REGEX.test(email)) {
      setHasEmailError("email_regex");
    }
    if (
      name !== "" &&
      COMPLETE_NAME_REGEX.test(name) &&
      EMAIL_REGEX.test(email)
    ) {
      props.onSubmitForm(email, name);
    }
  };

  const emailError = () => {
    return hasEmailError === "required"
      ? "Email is required"
      : "Invalid email address";
  };

  const nameError = () => {
    return hasNameError === "required"
      ? "Name is required"
      : "Name should only contain letters";
  };

  return (
    <SquareContainer>
      <LogoContainer />
      <Paragraph>Bring your buddy to our next race!</Paragraph>
      <FieldsContainer>
        <CssTextField
          label={hasEmailError ? emailError() : "Your friend’s email*"}
          variant="standard"
          onChange={onChangeEmailInput}
          error={!!hasEmailError}
        />
        <CssTextField
          label={hasNameError ? nameError() : "Your friend’s name*"}
          variant="standard"
          onChange={onChangeNameInput}
          error={!!hasNameError}
        />
      </FieldsContainer>
      <ButtonContainer onClick={handleSubmit}>
        <ButtonText>GET YOUR CODE!</ButtonText>
      </ButtonContainer>
    </SquareContainer>
  );
};

export default Form;
