import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/text-variable.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import getTextVariableSelector, {
  getCount,
} from "redux/selectors/text-variable.selector";
import DashboardView from "views/DashboardView/DashboardView";
import i18next from "i18next";
import "./i18n";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";
import { Data } from "components/DashboardTable/types";

const TextVariableDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getTextVariablesData = useSelector(getTextVariableSelector());
  const successSelector = useSelector(getSuccessSelector());
  const successText = useSelector(getSuccessText());
  const getTemplateCount = useSelector(getCount());
  const [actualPage, setActualPage] = useState(0);
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(actions.getTextVariables(query, actualPage));
  }, [dispatch, actualPage]);

  useEffect(() => {
    if (getTextVariablesData.textVariable.length) {
      const textVariableKeys = Object.keys(
        getTextVariablesData.textVariable[0]
      );
      setKeys(textVariableKeys);
    }
    return () => {
      setKeys([]);
    };
  }, [getTextVariablesData.textVariable]);

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  const onDeleteTextVariables = (textVariablesIds: string[]) => {
    dispatch(actions.onDeleteTextVariables(textVariablesIds));
  };

  const editTextVariables = (textVariableId: string) => {
    dispatch(actions.onSelectEditTextVariables(textVariableId));
  };

  const search = (query: string) => {
    dispatch(actions.getTextVariables(query, actualPage));
  };

  return (
    <DashboardLayout>
      {successSelector &&
        (successText === SUCCESS_TEXT.edited ? (
          <SuccessAlert message={i18next.t("TEXT_VARIABLE:EDITED")} />
        ) : (
          <SuccessAlert message={i18next.t("TEXT_VARIABLE:DELETED")} />
        ))}
      <DashboardView
        search={search}
        keys={keys}
        data={getTextVariablesData.textVariable as Data[]}
        title="Text variables"
        onDelete={onDeleteTextVariables}
        onEdit={editTextVariables}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getTemplateCount}
      />
    </DashboardLayout>
  );
};
export default TextVariableDashboardContainer;
