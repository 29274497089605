import ILottieJsonState from "common/interfaces/lottie-json.interface";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getJson = (state: IStore) => state.lottieJson;

export const getLottieData = (): OutputSelector<
  IStore,
  ILottieJsonState,
  (res: ILottieJsonState) => ILottieJsonState
> => createSelector([getJson], (lottieData) => lottieData);
