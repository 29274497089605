import { CircularProgress } from "@material-ui/core";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import React, { FunctionComponent } from "react";
import CreateClientForm from "./components/CreateClientForm/CreateClientForm";
import { ICreateClientProps } from "./types";

const AddClientView: FunctionComponent<ICreateClientProps> = (
  props: ICreateClientProps
) => {
  return (
    <>
      {props.loading && !props.clientError.error && <CircularProgress />}{" "}
      {!props.clientError.error ? (
        <CreateClientForm onSubmit={props.onSubmit} />
      ) : (
        <ErrorAlert message={props.clientError.message} />
      )}
    </>
  );
};

export default AddClientView;
