import { IconButton, Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import { IProps } from "./types";

const DeleteTooltip: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <Tooltip
      title="Delete"
      data-testid="deleteTooltip"
      onClick={props.handleDelete}
    >
      <IconButton aria-label="delete">
        <Delete />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteTooltip;
