import React, { FunctionComponent, useState } from "react";
import { Button, Grid, FormControl, TextField } from "@material-ui/core";
import "./i18n";
import i18next from "i18next";
import FileInput from "components/FileInput/FileInput";
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import { IProps } from "./types";
import {
  ButtonContainer,
  Container,
  FileName,
  InputDateWrapper,
  InputWrapper,
  useStyles,
} from "./styles";

const MetricsForm: FunctionComponent<IProps> = (props: IProps) => {
  const [sendDate, setSendDate] = useState<Date>();
  const [VTR, setVTR] = useState<number>();
  const [CTR, setCTR] = useState<number>();
  const [openRate, setOpenRate] = useState<number>();
  const [image, setImage] = useState<File | null>();
  const classes = useStyles();

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(`${event.target.value} 00:00:00`);
    setSendDate(date);
  };

  const onChangeVTR = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVTR(Number(event.target.value));
  };

  const onChangeCTR = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCTR(Number(event.target.value));
  };

  const handleReset = () => {
    setImage(null);
  };

  const onChangeOpenRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenRate(Number(event.target.value));
  };

  const onSubmit = () => {
    if (sendDate && VTR && CTR && openRate && image) {
      props.submit(sendDate, VTR, CTR, openRate, image);
    }
  };

  const onHandleInputChange = (fileList: FileList) => {
    const files = Array.from(fileList);
    if (files.length) {
      setImage(files[0]);
    }
  };

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={6} sm={3} spacing={3} style={{ width: 300 }}>
          <FormControl
            required
            variant="outlined"
            className={classes.formControl}
          >
            <InputDateWrapper>
              <TextField
                id="date"
                label="Send date"
                type="date"
                fullWidth
                variant="outlined"
                onChange={onChangeDate}
                defaultValue="2021-05-24"
              />
            </InputDateWrapper>
            <InputWrapper>
              <TextField
                label="VTR"
                value={VTR}
                variant="outlined"
                type="number"
                fullWidth
                autoComplete="off"
                onChange={onChangeVTR}
              />
            </InputWrapper>
            <InputWrapper>
              <TextField
                label="CTR"
                value={CTR}
                variant="outlined"
                type="number"
                fullWidth
                autoComplete="off"
                onChange={onChangeCTR}
              />
            </InputWrapper>
            <InputWrapper>
              <TextField
                label="open rate"
                value={openRate}
                variant="outlined"
                type="number"
                fullWidth
                autoComplete="off"
                onChange={onChangeOpenRate}
              />
            </InputWrapper>
            {!image ? (
              <FileInput
                accept={ACCEPT_TYPES.images}
                buttonText="Upload Image"
                onChange={onHandleInputChange}
              />
            ) : (
              <>
                <InputWrapper>
                  <FileName>{image.name}</FileName>
                </InputWrapper>
                <ButtonContainer>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReset}
                  >
                    {i18next.t("CREATE_CAMPAIGN_FORM:RESET")}
                  </Button>
                </ButtonContainer>
              </>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {i18next.t("CREATE_CAMPAIGN_FORM:CREATE")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default MetricsForm;
