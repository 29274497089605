import { IWellnetEOYReducerState } from "common/interfaces/wellnetEOY.interface";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getWellnetEOY = (state: IStore) => state.wellnetEOY;

export const GetWellnetEOYData = (): OutputSelector<
  IStore,
  IWellnetEOYReducerState,
  (res: IWellnetEOYReducerState) => IWellnetEOYReducerState
> =>
  createSelector([getWellnetEOY], (data) => {
    return data;
  });

export const getEmail = (): OutputSelector<
  IStore,
  string,
  (res: IWellnetEOYReducerState) => string
> => createSelector([getWellnetEOY], (data) => data.email);
