import { ICampaignReducerState } from "redux/reducers/campaigns.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getCampaign = (state: IStore) => state.campaign;

const getCampaignSelector = (): OutputSelector<
  IStore,
  ICampaignReducerState,
  (res: ICampaignReducerState) => ICampaignReducerState
> => createSelector([getCampaign], (campaign) => campaign);

export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: ICampaignReducerState) => number
> => createSelector([getCampaign], (campaign) => campaign.count);

export default getCampaignSelector;
