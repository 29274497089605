import { FormControl, InputLabel } from "@material-ui/core";
import Button from "components/Button/Button";
import React, { useState, useEffect, FunctionComponent } from "react";
import i18next from "i18next";
import CsvDownloader from "react-csv-downloader";
import getTimeStamp from "helper/getTimeStamp.helper";
import Select from "components/Select/SelectInput";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { Container, SelectContainer, useStyles } from "./styles";
import { ICSVRow, IHeaderMatch, IProps } from "./types";
import "./i18n";
import ImportCsvForm from "./components/ImportCsvForm/ImportCsvForm";

const ImportCsvView: FunctionComponent<IProps> = (props: IProps) => {
  const [csvArray, setCsvArray] = useState<string[][]>([]);
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [csvDataDownload, setCsvDataDownload] = useState<ICSVRow[]>([]);
  const [headersMatch, setHeadersMatch] = useState<IHeaderMatch[]>([]);
  const [textVariablesFiltered, setTextVariablesFiltered] = useState<
    ITextVariable[]
  >([]);

  const classes = useStyles();
  useEffect(() => {
    const newTextVariablesFiltered = props.textVariables.filter(
      (textVariable: ITextVariable) => textVariable.type !== 1
    );
    setTextVariablesFiltered(newTextVariablesFiltered);
  }, [props.textVariables]);

  useEffect(() => {
    if (props.exportCsvData && !!props.exportCsvData.length) {
      const headers = [...props.headers];

      const exportData = [...props.exportCsvData];
      headers.push("bmsLink");

      const newArray = exportData.map((item: ICSVRow) => {
        let exportCsv = {};
        Object.values(item).forEach((value: string, index: number) => {
          const newKey = headers[index];
          exportCsv = {
            ...exportCsv,
            [newKey]: value,
          };
        });
        return exportCsv;
      });
      setCsvDataDownload(newArray);
      setCsvHeaders(headers);
    }
  }, [props.exportCsvData, props.headers]);

  const onChangeHeader = (
    event: React.ChangeEvent<{ value: unknown }>,
    textVariableId: string,
    i: number
  ) => {
    const headersMatchCopy = [...headersMatch];
    const headerValue = {
      key: textVariableId,
      value: event.target.value as string,
      index: i,
    };
    const index = headersMatchCopy.findIndex(
      (header: IHeaderMatch) => header.key === textVariableId
    );
    if (index >= 0) {
      headersMatchCopy.splice(index, 1, headerValue);
      setHeadersMatch(headersMatchCopy);
    } else {
      setHeadersMatch((prevState) => [...prevState, headerValue]);
    }
  };

  const submitCSV = () => {
    props.confirmCSV(headersMatch, csvHeaders, csvArray);
  };

  return textVariablesFiltered.length ? (
    <Container>
      <SelectContainer>
        {props.exportCsvData && props.exportCsvData.length ? (
          <div>
            <CsvDownloader
              filename={`BMS_EXPORT_${getTimeStamp()}`}
              extension=".csv"
              separator=","
              datas={csvDataDownload}
              columns={csvHeaders}
              text="DOWNLOAD"
              wrapColumnChar='"'
            >
              <Button>Download</Button>
            </CsvDownloader>
          </div>
        ) : (
          <div>
            <ImportCsvForm
              setCsvArray={setCsvArray}
              setCsvHeaders={setCsvHeaders}
            />
            {!!csvHeaders.length &&
              textVariablesFiltered.map(
                (textVariable: ITextVariable, index: number) => {
                  const headerFound = headersMatch.find(
                    (header: IHeaderMatch) => header.index === index
                  );
                  return (
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        {textVariable.value}
                      </InputLabel>
                      <Select
                        keyValue={textVariable._id}
                        value={headerFound ? headerFound.value : ""}
                        label={textVariable.value}
                        onChangeClick={onChangeHeader}
                        items={csvHeaders}
                        index={index}
                      />
                    </FormControl>
                  );
                }
              )}
            {!!csvArray.length && (
              <Button onClick={submitCSV}>
                {i18next.t("IMPORT_CSV:BUTTON_TEXT")}
              </Button>
            )}
          </div>
        )}
      </SelectContainer>
    </Container>
  ) : null;
};
export default ImportCsvView;
