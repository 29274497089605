import React, { FunctionComponent } from "react";
import i18next from "i18next";
import ErrorAlert from "components/ErrorAlert/ErrorAlert";
import ChangePasswordForm from "./components/ChangePasswordForm/ChangePasswordForm";
import { IChangePasswordViewProps } from "./types";
import {
  ChangePasswordContainer,
  ChangePasswordContent,
  ChangePasswordFormContainer,
  Title,
} from "./styles";
import "./i18n";

const ChangePasswordView: FunctionComponent<IChangePasswordViewProps> = (
  props: IChangePasswordViewProps
) => {
  return (
    <ChangePasswordContainer>
      <ChangePasswordContent>
        <Title>{i18next.t("CHANGE_PASSWORD_VIEW:TITLE")}</Title>
        {!props.isLoading && (
          <ChangePasswordFormContainer>
            {!props.passwordSubmitted && props.error ? (
              <ErrorAlert
                message={i18next.t("CHANGE_PASSWORD_VIEW:TOKEN_ERROR")}
              />
            ) : (
              !props.error && <ChangePasswordForm onSubmit={props.onSubmit} />
            )}
            {props.passwordSubmitted && props.error && (
              <ErrorAlert
                message={i18next.t("CHANGE_PASSWORD_VIEW:SUBMIT_ERROR")}
              />
            )}
          </ChangePasswordFormContainer>
        )}
      </ChangePasswordContent>
    </ChangePasswordContainer>
  );
};

export default ChangePasswordView;
