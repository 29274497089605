import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import * as actions from "redux/actions/asset.actions";
import AddAssetView from "views/AddAssetView/AddAssetView";

const AssetForm: FunctionComponent = () => {
  const dispatch = useDispatch();
  const addAsset = (file: FileList) => {
    dispatch(actions.onAddAsset(file));
  };

  return (
    <DashboardLayout>
      <AddAssetView onSubmit={addAsset} />
    </DashboardLayout>
  );
};

export default AssetForm;
