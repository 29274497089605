import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.h2`
  font-size: 27px;
  font-family: Avenir-Medium;
  color: ${COLORS.white};
`;
