import { AxiosResponse } from "axios";
import { IImageVariable } from "common/interfaces/image-variable.interface";
import { IDataLayer, ILayer, Layer } from "common/interfaces/layer.interface";
import { IAudioAsset, Template } from "common/interfaces/template.interface";
import { IText } from "common/interfaces/text-variable.interface";
import toBase64 from "helper/convertTo64.helper";
import createFile from "helper/createFileInterface.helper";
import getJsonData from "helper/get_json_data";
import { IObject } from "helper/types";
import { get, post, put } from "./api";

export const createTemplateService = async (
  template: Template
): Promise<AxiosResponse> => {
  const response = await post("template/", template);
  return response;
};

export const createLayers = async (
  files: IObject,
  templateId: string
): Promise<AxiosResponse> => {
  const newFiles = { ...files };
  const promises = Object.entries(newFiles).map(async ([key, value]) => {
    const fileConverted = (await toBase64(value as File)) as string;
    return { [key]: createFile(value as File, fileConverted) };
  });
  const resolved = await Promise.all(promises);
  const layers = Object.assign({}, ...resolved);
  const data = { templateId, layers };
  const response = await post(`layer/`, data);
  return response.data;
};

export const createAudio = async (
  fileAudio: IAudioAsset,
  templateId: string
): Promise<AxiosResponse> => {
  const newFile = fileAudio.assetAudio[0];
  const fileConverted = (await toBase64(newFile)) as string;
  const audio = createFile(newFile, fileConverted);
  const audioName = fileAudio.name;
  const data = { templateId, audio, audioName };
  const response = await post(`audio/`, data);
  return response.data;
};

export const createVideo = async (
  videoFile: File[],
  templateId: string
): Promise<AxiosResponse> => {
  const newFile = videoFile[0];
  const fileConverted = (await toBase64(newFile)) as string;
  const video = createFile(newFile, fileConverted);
  const data = { templateId, video };
  const response = await post(`video/`, data);
  return response.data;
};

export const createTextVariables = async (
  textVariables: IText[],
  templateId: string
): Promise<AxiosResponse> => {
  const data = { textVariables, templateId };
  const response = await post("text-variable/", data);
  return response.data;
};

export const createImageVariables = async (
  imageVariables: IImageVariable[]
): Promise<AxiosResponse> => {
  const data = { imageVariables };
  const response = await post("image-variable/", data);
  return response.data;
};

export const getAssetPath = async (id: string): Promise<IDataLayer> => {
  const response = await get(`assets/${id}`);
  const jsonLayer = await getJsonData(response.data);
  return jsonLayer;
};

export const getJsons = async (
  layers: ILayer[]
): Promise<
  {
    dataPath: IDataLayer;
    layer: Layer;
  }[]
> => {
  const promises = await Promise.all(
    layers.map(async (layer: ILayer) => {
      const json = await getJsonData(layer.dataPath);
      return { ...layer, dataPath: json };
    })
  );
  return promises;
};

export const getTemplateService = async (
  templateId: string
): Promise<AxiosResponse> => {
  const response = await get(`template/${templateId}`);
  return response.data;
};

export const getTemplatesService = async (): Promise<AxiosResponse> => {
  const response = await get(`template/`);
  return response.data;
};

export const getTenTemplatesService = async (
  query: string,
  page: number
): Promise<AxiosResponse> => {
  let response;
  if (query === "") {
    response = await get(`template/get/${page}/`);
  } else {
    response = await get(`template/get/${page}/${query}`);
  }

  return response.data;
};

export const deleteTemplates = async (
  templatesIds: string[]
): Promise<AxiosResponse> => {
  const data = {
    templatesIds,
  };
  const response = await put(`template/delete`, data);
  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`template/gets/count`);
  return response.data;
};
