import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "common/constants/color.contants";

export const CustomForm = styled.form`
  width: 100%;
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ForgotPasswordLink = styled(Link)`
  color: ${COLORS.greenChristi};
  font-size: 13px;
  cursor: pointer;

  :hover {
    color: ${COLORS.gray};
  }
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  height: 12px;
  text-align: left;
  color: ${COLORS.red};
  margin-bottom: 10px;
`;
