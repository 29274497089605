import { AxiosResponse } from "axios";
import { get, post, put } from "./api";

export const getCampaignData = async (
  campaignId: string
): Promise<AxiosResponse> => {
  const response = await get(`campaign/${campaignId}`);
  return response.data;
};

export const onCreateCampaign = async (
  templateId: string,
  clientId: string,
  campaignName: string,
  metadataTitle: string,
  metadataDescription: string,
  metadataImageAssetId: string,
  metadataFaviconAssetId: string
): Promise<AxiosResponse> => {
  const data = {
    templateId,
    clientId,
    campaignName,
    metadataTitle,
    metadataDescription,
    metadataImageAssetId,
    metadataFaviconAssetId,
  };
  const response = await post(`campaign/`, data);
  return response.data;
};

export const deleteCampaigns = async (
  campaignsId: string[]
): Promise<AxiosResponse> => {
  const data = {
    campaignsId,
  };
  const response = await put(`campaign/delete/`, data);
  return response.data;
};

export const getTenCampaignsService = async (
  query: string,
  page: number
): Promise<AxiosResponse> => {
  let response;
  if (query === "") {
    response = await get(`campaign/page/${page}/`);
  } else {
    response = await get(`campaign/page/${page}/${query}`);
  }

  return response.data;
};

export const getCount = async (): Promise<AxiosResponse> => {
  const response = await get(`campaign/get/count`);
  return response.data;
};

export const submitMetricInformationService = async (
  campaignId: string,
  date: Date,
  VTR: number,
  CTR: number,
  openRate: number,
  imageAssetId: string
): Promise<AxiosResponse> => {
  const data = {
    campaignId,
    date,
    VTR,
    CTR,
    openRate,
    imageAssetId,
  };
  const response = await put(`campaign/add-metric-information/`, data);
  return response.data;
};
