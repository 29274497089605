import React, { FunctionComponent, useEffect } from "react";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import { useParams } from "react-router-dom";
import MetricsDashboardView from "views/MetricsDashboardView/MetricsDashboardView";
import { useDispatch, useSelector } from "react-redux";
import { onInitializeMetricDashboard } from "redux/actions/metric.actions";
import { getMetricsData } from "redux/selectors/metric.selector";

const MetricsDashboardContainer: FunctionComponent = () => {
  const { campaignId }: { campaignId: string } = useParams();
  const dispatch = useDispatch();
  const metricData = useSelector(getMetricsData());

  useEffect(() => {
    dispatch(onInitializeMetricDashboard(campaignId));
  }, [dispatch, campaignId]);

  return (
    <DashboardLayout>
      <MetricsDashboardView metricData={metricData} />
    </DashboardLayout>
  );
};

export default MetricsDashboardContainer;
