import React, { FunctionComponent } from "react";
import { Form, Field } from "react-final-form";
import i18next from "i18next";
import {
  composeValidators,
  emailValidation,
  requiredValidation,
} from "helper/validations";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { IForgotPasswordFormProps } from "./types";
import { CustomForm, ButtonContainer } from "./styles";
import "./i18n";

const ForgotPasswordForm: FunctionComponent<IForgotPasswordFormProps> = (
  props: IForgotPasswordFormProps
) => (
  <Form
    onSubmit={props.onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <CustomForm onSubmit={handleSubmit}>
        <Field
          label={i18next.t("FORGOT_PASSWORD_FORM:EMAIL")}
          name="email"
          render={Input}
          type="email"
          validate={composeValidators(requiredValidation, emailValidation)}
        />
        <ButtonContainer>
          <Button disabled={pristine || submitting} type="submit">
            {i18next.t("FORGOT_PASSWORD_FORM:SUBMIT_BUTTON")}
          </Button>
        </ButtonContainer>
      </CustomForm>
    )}
  />
);

export default ForgotPasswordForm;
