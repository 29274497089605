export const ON_INITIALIZE_METRICS_DASHBOARD_REQUESTED =
  "ON_INITIALIZE_METRICS_DASHBOARD_REQUESTED";
export const ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED =
  "ON_INITIALIZE_METRICS_DASHBOARD_SUCCEED";
export const ON_INITIALIZE_METRICS_DASHBOARD_FAILED =
  "ON_INITIALIZE_METRICS_DASHBOARD_FAILED";

export const ON_CREATE_METRIC_REQUESTED = "ON_CREATE_METRIC_REQUESTED";
export const ON_CREATE_METRIC_SUCCEED = "ON_CREATE_METRIC_SUCCEED";
export const ON_CREATE_METRIC_FAILED = "ON_CREATE_METRIC_FAILED";

export const ON_UPDATE_WATCHED_TIME_REQUESTED =
  "ON_UPDATE_WATCHED_TIME_REQUESTED";
export const ON_UPDATE_WATCHED_TIME_SUCCEED = "ON_UPDATE_WATCHED_TIME_SUCCEED";
export const ON_UPDATE_WATCHED_TIME_FAILED = "ON_UPDATE_WATCHED_TIME_FAILED";

export const ON_UPDATE_AUDIO_CLICKED_REQUESTED =
  "ON_UPDATE_AUDIO_CLICKED_REQUESTED";
export const ON_UPDATE_AUDIO_CLICKED_SUCCEED =
  "ON_UPDATE_AUDIO_CLICKED_SUCCEED";
export const ON_UPDATE_AUDIO_CLICKED_FAILED = "ON_UPDATE_AUDIO_CLICKED_FAILED";

export const ON_UPDATE_CTA_CLICKED_REQUESTED =
  "ON_UPDATE_CTA_CLICKED_REQUESTED";
export const ON_UPDATE_CTA_CLICKED_SUCCEED = "ON_UPDATE_CTA_CLICKED_SUCCEED";
export const ON_UPDATE_CTA_CLICKED_FAILED = "ON_UPDATE_CTA_CLICKED_FAILED";
export const ON_SUBMIT_METRICS_INFORMATION_REQUESTED =
  "ON_SUBMIT_METRICS_INFORMATION_REQUESTED";
export const ON_SUBMIT_METRICS_INFORMATION_SUCCEED =
  "ON_SUBMIT_METRICS_INFORMATION_SUCCEED";
export const ON_SUBMIT_METRICS_INFORMATION_FAILED =
  "ON_SUBMIT_METRICS_INFORMATION_FAILED";
