import { SIZES } from "common/constants/sizes.contants";
import styled from "styled-components";

export const MetricsDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${SIZES.smallDesktop}px;
  justify-content: center;

  @media (max-width: ${SIZES.smallDesktop}px) {
    padding: 0 25px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;
