import styled from "styled-components";
import { ReactComponent as replay } from "assets/icon-replay.svg";
import { ReactComponent as replayHover } from "assets/icon-replay-fill-01.svg";
import { ReactComponent as sound } from "assets/sound-icon-01.svg";
import { ReactComponent as soundBlocked } from "assets/icon-sound-x.svg";
import { ReactComponent as soundHover } from "assets/sound-icon_fill-01.svg";
import { ReactComponent as soundBlockedHover } from "assets/icon-sound-X_fill.svg";
import { IVisible } from "./types";

export const ControlsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #214569;
  border-radius: 0;
`;

export const Replay = styled(replay)<IVisible>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  cursor: ${(props) => (props.replayvisible ? "pointer" : "default")};
  opacity: ${(props) => (props.replayvisible ? "1" : "0.5")};
`;

export const ReplayCircleHover = styled(replayHover)<IVisible>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  cursor: ${(props) => (props.replayvisible ? "pointer" : "default")};
  opacity: 0;
`;

export const ReplayContainer = styled.div<IVisible>`
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  position: relative;
  &:hover {
    ${Replay} {
      opacity: ${(props) => (props.replayvisible ? "0" : "")};
    }
    ${ReplayCircleHover} {
      opacity: ${(props) => (props.replayvisible ? "1" : "")};
    }
  }
`;

export const Sound = styled(sound)`
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  opacity: 1;
`;

export const SoundBlockedHover = styled(soundBlockedHover)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
`;

export const SoundBlock = styled(soundBlocked)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
`;

export const SoundHover = styled(soundHover)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
`;

export const SoundDisabled = styled(soundBlocked)`
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  opacity: 0.5;
  position: absolute;
`;

export const SoundContainer = styled.div<IVisible>`
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  position: relative;
  &:hover {
    ${Sound} {
      opacity: ${(props) => (!props.replayvisible ? "0" : "")};
    }
    ${SoundHover} {
      opacity: ${(props) => (!props.replayvisible ? "1" : "")};
    }
    ${SoundBlock} {
      opacity: ${(props) => (!props.replayvisible ? "0" : "")};
    }
    ${SoundBlockedHover} {
      opacity: ${(props) => (!props.replayvisible ? "1" : "")};
    }
  }
`;
