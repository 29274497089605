/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onAddPublicAssetTermsAndConditions } from "redux/actions/asset.actions";
import { getPublicAssetSuccessSelector } from "redux/selectors/asset.selector";
import TermsAndConditionsView from "views/TermsAndConditionsView/TermsAndConditionsView";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const uuid = "16889edd-e0d9-456c-a461-14fcfcdabd8d";
  const onSubmitFile = (file: File) => {
    dispatch(onAddPublicAssetTermsAndConditions(file, uuid));
  };
  const fileSuccessSelector = useSelector(getPublicAssetSuccessSelector());

  return (
    <TermsAndConditionsView
      onUploadAsset={onSubmitFile}
      successUpload={fileSuccessSelector}
    />
  );
};

export default TermsAndConditions;
