import { IAPIError } from "common/interfaces/api.interface";
import { IUser } from "common/interfaces/user.interface";
import { AnyAction } from "redux";
import * as constants from "redux/constants/app.constants";

export const initializePrivateRoute = (): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED,
});

export const initializePrivateRouteSucceded = (user: IUser): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED,
  user,
});

export const initializePrivateRouteFailed = (error: IAPIError): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED,
  error,
});

export const initializePublicRoute = (restricted: boolean): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_PUBLIC_ROUTE_REQUESTED,
  restricted,
});

export const initializePublicRouteSucceded = (
  user: IUser,
  restricted: boolean
): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED,
  user,
  restricted,
});

export const initializePublicRouteFailed = (error: IAPIError): AnyAction => ({
  type: constants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED,
  error,
});
