import { IAPIError } from "common/interfaces/api.interface";
import { IUser } from "common/interfaces/user.interface";
import { AnyAction } from "redux";
import * as constants from "redux/constants/user.constants";
import { IChangePasswordFormData } from "views/ChangePasswordView/types";
import { ICreateUserFormData } from "views/CreateUserView/types";
import { IForgotPasswordFormData } from "views/ForgotPasswordView/types";
import { ILoginFormData } from "views/LoginView/types";
import { IRegisterFormData } from "views/RegisterView/types";

export function onLogin(formData: ILoginFormData): AnyAction {
  return {
    type: constants.USER_ON_LOGIN_REQUESTED,
    formData,
  };
}

export function onLoginSucceeded(data: IUser): AnyAction {
  return {
    type: constants.USER_ON_LOGIN_SUCCEEDED,
    data,
  };
}

export function onLoginFailed(error: IAPIError): AnyAction {
  return {
    type: constants.USER_ON_LOGIN_FAILED,
    error,
  };
}

export function onRegister(formData: IRegisterFormData): AnyAction {
  return {
    type: constants.USER_ON_REGISTER_REQUESTED,
    formData,
  };
}

export function onRegisterSucceeded(): AnyAction {
  return {
    type: constants.USER_ON_REGISTER_SUCCEEDED,
  };
}

export function onRegisterFailed(): AnyAction {
  return {
    type: constants.USER_ON_REGISTER_FAILED,
  };
}

export function onInitializeRegister(token: string): AnyAction {
  return {
    type: constants.ON_INITIALIZE_REGISTER_REQUESTED,
    token,
  };
}

export function onInitializeRegisterSucceeded(data: IUser): AnyAction {
  return {
    type: constants.ON_INITIALIZE_REGISTER_SUCCEEDED,
    data,
  };
}

export function onInitializeRegisterFailed(): AnyAction {
  return {
    type: constants.ON_INITIALIZE_REGISTER_FAILED,
  };
}

export function getUsersSucceeded(data: IUser[]): AnyAction {
  return {
    type: constants.ON_RETRIEVE_USERS_SUCCEEDED,
    data,
  };
}

export function getUsersFailed(): AnyAction {
  return {
    type: constants.ON_RETRIEVE_USERS_FAILED,
  };
}

export const onInitializeLogin = (): AnyAction => ({
  type: constants.ON_INITIALIZE_LOGIN_REQUESTED,
});

export const onInitializeLoginSucceed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_LOGIN_SUCCEED,
});

export const onInitializeLoginFailed = (): AnyAction => ({
  type: constants.ON_INITIALIZE_LOGIN_FAILED,
});

export const onLogoutRequest = (): AnyAction => ({
  type: constants.ON_LOGOUT_REQUESTED,
});

export const onLogoutSucceeded = (): AnyAction => ({
  type: constants.ON_LOGOUT_SUCCEEDED,
});

export const onRedirectToCreateUserForm = (): AnyAction => ({
  type: constants.ON_REDIRECT_TO_CREATE_USER_FORM,
});

export const onCreateUserRequest = (
  formData: ICreateUserFormData
): AnyAction => ({
  type: constants.ON_CREATE_USER_REQUESTED,
  formData,
});

export const onCreateUserSucceeded = (data: IUser): AnyAction => ({
  type: constants.ON_CREATE_USER_SUCCEEDED,
  data,
});

export const onCreateUserFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_CREATE_USER_FAILED,
  error,
});

export const onDeleteUsers = (ids: string[]): AnyAction => ({
  type: constants.ON_DELETE_USERS_REQUESTED,
  ids,
});

export const onDeleteUsersSuccess = (data: IUser[]): AnyAction => ({
  type: constants.ON_DELETE_USERS_SUCCESS,
  data,
});

export const onDeleteUsersFailed = (): AnyAction => ({
  type: constants.ON_DELETE_USERS_FAILED,
});

export const getUserCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_USER_COUNT_SUCCEED,
  count,
});

export const getUserCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_USER_COUNT_FAILED,
  error,
});

export const getUserCount = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_USER_REQUESTED,
  count,
});

export const onInitializeUserDashboard = (
  query: string,
  page: number
): AnyAction => ({
  type: constants.ON_INITIALIZE_USER_DASHBOARD_REQUESTED,
  query,
  page,
});

export const onGetUsersSucceed = (data: IUser[]): AnyAction => ({
  type: constants.ON_RETRIEVE_USER_SUCCEED,
  data,
});

export const onGetUsersFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_USER_FAILED,
  error,
});

export function onSubmitForgotPasswordRequest(
  formData: IForgotPasswordFormData
): AnyAction {
  return {
    type: constants.ON_SUBMIT_FORGOT_PASSWORD_REQUEST,
    formData,
  };
}

export function onSubmitForgotPasswordSucceeded(): AnyAction {
  return {
    type: constants.ON_SUBMIT_FORGOT_PASSWORD_SUCCEEDED,
  };
}

export function onSubmitForgotPasswordFailed(): AnyAction {
  return {
    type: constants.ON_SUBMIT_FORGOT_PASSWORD_FAILED,
  };
}

export function onInitializeChangePasswordRequest(token: string): AnyAction {
  return {
    type: constants.ON_INITIALIZE_CHANGE_PASSWORD_REQUEST,
    token,
  };
}

export function onInitializeChangePasswordSucceeded(): AnyAction {
  return {
    type: constants.ON_INITIALIZE_CHANGE_PASSWORD_SUCCEEDED,
  };
}

export function onInitializeChangePasswordFailed(): AnyAction {
  return {
    type: constants.ON_INITIALIZE_CHANGE_PASSWORD_FAILED,
  };
}

export function onChangePasswordRequest(
  formData: IChangePasswordFormData,
  token: string
): AnyAction {
  return {
    type: constants.ON_CHANGE_PASSWORD_REQUEST,
    formData,
    token,
  };
}

export function onChangePasswordSucceeded(): AnyAction {
  return {
    type: constants.ON_CHANGE_PASSWORD_SUCCEEDED,
  };
}

export function onChangePasswordFailed(): AnyAction {
  return {
    type: constants.ON_CHANGE_PASSWORD_FAILED,
  };
}

export const onInitializeCreateUser = (): AnyAction => ({
  type: constants.ON_INITIALIZE_CREATE_USER,
});
