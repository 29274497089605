import { AnyAction } from "redux";
import {
  ON_CREATE_CAMPAIGN_FAILED,
  ON_CREATE_CAMPAIGN_SUCCEED,
} from "redux/constants/campaign.constants";
import {
  ON_DELETE_CLIENT_SUCCESS,
  ON_SUBMIT_CLIENT_CREATE_FAILED,
  ON_SUBMIT_CLIENT_CREATE_SUCCESS,
} from "redux/constants/client.constants";
import { ON_NOTIFICATION_HIDE } from "redux/constants/error.constants";
import {
  ON_CREATE_TEMPLATE_FAILED,
  ON_CREATE_TEMPLATE_SUCCESS,
  ON_INITIALIZE_TEMPLATE_FORM,
} from "redux/constants/template.constants";
import {
  ON_CREATE_USER_FAILED,
  ON_CREATE_USER_SUCCEEDED,
  USER_ON_LOGIN_FAILED,
  USER_ON_LOGIN_SUCCEEDED,
} from "redux/constants/user.constants";

export interface IErrorReducerState {
  error: boolean;
  message: string;
}

const defaultState: IErrorReducerState = {
  error: false,
  message: "",
};

const addError = (state: IErrorReducerState, action: AnyAction) => {
  return {
    ...state,
    error: true,
    message: action.error.message,
  };
};

const removeError = (state: IErrorReducerState) => {
  return {
    ...state,
    error: false,
    message: "",
  };
};

const errorReducer = (
  state: IErrorReducerState = defaultState,
  action: AnyAction
): IErrorReducerState => {
  switch (action.type) {
    case ON_SUBMIT_CLIENT_CREATE_FAILED:
    case ON_CREATE_TEMPLATE_FAILED:
    case ON_CREATE_CAMPAIGN_FAILED:
    case ON_CREATE_USER_FAILED:
    case USER_ON_LOGIN_FAILED:
      return addError(state, action);
    case ON_SUBMIT_CLIENT_CREATE_SUCCESS:
    case ON_DELETE_CLIENT_SUCCESS:
    case ON_CREATE_TEMPLATE_SUCCESS:
    case ON_NOTIFICATION_HIDE:
    case ON_CREATE_CAMPAIGN_SUCCEED:
    case ON_CREATE_USER_SUCCEEDED:
    case USER_ON_LOGIN_SUCCEEDED:
    case ON_INITIALIZE_TEMPLATE_FORM:
      return removeError(state);
    default:
      return state;
  }
};

export default errorReducer;
