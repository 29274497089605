import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 95%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${SIZES.smallDesktop}px) {
    height: 82%;
  }

  @media (max-width: ${SIZES.bigTablet}px) {
    height: 82%;
  }

  @media (max-width: ${SIZES.bigPhone}px) {
    height: 85%;
  }
`;

export const Title = styled.h1`
  font-size: 26px;
  font-family: Barlow-Medium;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  color: ${COLORS.white};
  z-index: 999;
  margin-bottom: 10px;

  @media (max-width: ${SIZES.bigTablet}px) {
    font-size: 18px;
    text-align: center;
    padding: 0 12px;
  }

  @media (max-width: ${SIZES.smallTablet}px) {
    padding: 0 28px;
  }

  @media (max-width: ${SIZES.bigPhone}px) {
    line-height: 23px;
  }
`;

export const NotFound = styled.h1`
  font-size: 220px;
  font-family: Barlow-Bold;
  font-weight: bold;
  display: flex;
  color: ${COLORS.white};
  justify-content: center;
  margin-bottom: 30px;
  z-index: 999;

  @media (max-width: ${SIZES.bigPhone}px) {
    font-size: 150px;
  }
`;

export const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${SIZES.smallDesktop}px;
  justify-content: center;

  @media (max-width: ${SIZES.smallDesktop}px) {
    padding: 0 25px;
  }
`;
