export const copyToClipboard = (copy: string): void => {
  const elemento = copy;
  const el = document.createElement("textarea");
  el.value = elemento;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
