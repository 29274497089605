import React, { FunctionComponent } from "react";
import { Form, Field } from "react-final-form";
import i18next from "i18next";

import {
  composeValidators,
  emailValidation,
  nameValidation,
  passwordConfirmationValidation,
  requiredValidation,
} from "helper/validations";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { IRegisterFormProps } from "./types";
import { CustomForm, ButtonContainer } from "./styles";
import "./i18n";

const RegisterForm: FunctionComponent<IRegisterFormProps> = ({
  onSubmit,
  userEmail,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <CustomForm data-testid="register-form" onSubmit={handleSubmit}>
        <Field
          label={i18next.t("REGISTER_FORM:EMAIL")}
          name="email"
          render={Input}
          type="email"
          validate={composeValidators(requiredValidation, emailValidation)}
          defaultValue={userEmail}
          disabled={!!userEmail}
        />
        <Field
          label={i18next.t("REGISTER_FORM:FIRST_NAME")}
          name="firstName"
          render={Input}
          type="firstName"
          validate={composeValidators(requiredValidation, nameValidation)}
        />
        <Field
          label={i18next.t("REGISTER_FORM:LAST_NAME")}
          name="lastName"
          render={Input}
          type="lastName"
          validate={composeValidators(requiredValidation, nameValidation)}
        />
        <Field
          label={i18next.t("REGISTER_FORM:PASSWORD")}
          name="password"
          render={Input}
          type="password"
          validate={requiredValidation}
        />
        <Field
          label={i18next.t("REGISTER_FORM:CONFIRM_PASSWORD")}
          name="confirm password"
          render={Input}
          type="password"
          validate={passwordConfirmationValidation}
        />
        <ButtonContainer>
          <Button disabled={pristine || submitting} type="submit">
            {i18next.t("REGISTER_FORM:SUBMIT_BUTTON")}
          </Button>
        </ButtonContainer>
      </CustomForm>
    )}
  />
);

export default RegisterForm;
