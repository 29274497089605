import { takeLatest, call, all, put } from "redux-saga/effects";
import {
  deleteTextVariables,
  editTextVariable,
  getCount,
  getTenTextVariablesData,
} from "service/text-variable.service";
import { IAPIError } from "common/interfaces/api.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { AnyAction } from "redux";
import * as textVariableActions from "../actions/text-variable.actions";
import * as constants from "../constants/text-variable.constants";

function* getTextVariables(action: AnyAction) {
  try {
    const response: ITextVariable[] = yield call(
      getTenTextVariablesData,
      action.query,
      action.page
    );
    yield put(textVariableActions.getTextVariablesSucceed(response));
  } catch (err) {
    yield put(textVariableActions.getTextVariablesFailed(err as IAPIError));
  }
}

function* getTextVariableCount() {
  try {
    const count: number = yield call(getCount);
    yield put(textVariableActions.getTextVariableCountSucceed(count));
  } catch (err) {
    yield put(textVariableActions.getTextVariableCountFailed(err as IAPIError));
  }
}

function* onDeleteTextVariables(action: AnyAction) {
  try {
    const response: ITextVariable[] = yield call(
      deleteTextVariables,
      action.textVariablesIds
    );
    yield put(textVariableActions.onDeleteTextVariablesSucceed(response));
  } catch (err) {
    yield put(
      textVariableActions.onDeleteTextVariablesFailed(err as IAPIError)
    );
  }
}

function* onEditTextVariables(action: AnyAction) {
  try {
    yield call(editTextVariable, action.textVariable);
    yield put(textVariableActions.onEditTextVariableSucceed());
  } catch (err) {
    yield put(textVariableActions.onEditTextVariableFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(constants.ON_RETRIEVE_TEXT_VARIABLES_REQUESTED, getTextVariables),
  takeLatest(
    constants.ON_RETRIEVE_TEXT_VARIABLES_REQUESTED,
    getTextVariableCount
  ),
  takeLatest(
    constants.ON_DELETE_TEXT_VARIABLES_REQUESTED,
    onDeleteTextVariables
  ),
  takeLatest(constants.ON_EDIT_TEXT_VARIABLE_REQUESTED, onEditTextVariables),
]);
