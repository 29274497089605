import { IAPIError } from "common/interfaces/api.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/text-variable.constants";

export const getTextVariables = (query: string, page: number): AnyAction => ({
  type: constants.ON_RETRIEVE_TEXT_VARIABLES_REQUESTED,
  query,
  page,
});

export const getTextVariablesSucceed = (data: ITextVariable[]): AnyAction => ({
  type: constants.ON_RETRIEVE_TEXT_VARIABLES_SUCCEED,
  data,
});

export const getTextVariablesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_TEXT_VARIABLES_FAILED,
  error,
});

export const onDeleteTextVariables = (
  textVariablesIds: string[]
): AnyAction => ({
  type: constants.ON_DELETE_TEXT_VARIABLES_REQUESTED,
  textVariablesIds,
});

export const onDeleteTextVariablesSucceed = (
  data: ITextVariable[]
): AnyAction => ({
  type: constants.ON_DELETE_TEXT_VARIABLES_SUCCEED,
  data,
});

export const onDeleteTextVariablesFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_TEXT_VARIABLES_FAILED,
  error,
});

export const onSelectEditTextVariables = (
  textVariableId: string
): AnyAction => ({
  type: constants.ON_SELECT_TEXT_VARIABLE_EDIT,
  textVariableId,
});

export const onEditTextVariable = (textVariable: ITextVariable): AnyAction => ({
  type: constants.ON_EDIT_TEXT_VARIABLE_REQUESTED,
  textVariable,
});

export const onEditTextVariableSucceed = (): AnyAction => ({
  type: constants.ON_EDIT_TEXT_VARIABLE_SUCCEED,
});

export const onEditTextVariableFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_EDIT_TEXT_VARIABLE_FAILED,
  error,
});

export const getTextVariableCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_TEXT_VARIABLE_COUNT_SUCCEED,
  count,
});

export const getTextVariableCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_TEXT_VARIABLE_COUNT_FAILED,
  error,
});
