import React, { FunctionComponent, useEffect } from "react";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import CreateUserView from "views/CreateUserView/CreateUserView";
import { useDispatch, useSelector } from "react-redux";
import { ICreateUserFormData } from "views/CreateUserView/types";
import {
  onCreateUserRequest,
  onInitializeCreateUser,
} from "redux/actions/user.actions";
import "./i18n";
import i18next from "i18next";
import { getUserToken } from "redux/selectors/user.selector";
import getSuccessSelector from "redux/selectors/success.selector";
import { hideSuccessNotification } from "redux/actions/success.actions";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import getErrorSelector from "redux/selectors/error.selector";
import { IError } from "common/interfaces/error.interface";
import { hideErrorNotification } from "redux/actions/error.actions";

const CreateUserContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const token = useSelector(getUserToken());
  const errorSelector: IError = useSelector(getErrorSelector());
  const successSelector = useSelector(getSuccessSelector());

  const onSubmit = (formData: ICreateUserFormData) => {
    dispatch(onCreateUserRequest(formData));
  };

  useEffect(() => {
    dispatch(onInitializeCreateUser());
  }, [dispatch]);

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
    if (errorSelector.error) {
      dispatch(hideErrorNotification());
    }
  }, 3000);

  return (
    <DashboardLayout>
      {successSelector && <SuccessAlert message={i18next.t("USER:TEXT")} />}
      <CreateUserView
        onSubmit={onSubmit}
        token={token}
        userError={errorSelector}
      />
    </DashboardLayout>
  );
};

export default CreateUserContainer;
