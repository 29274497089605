import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";

import { useDispatch } from "react-redux";

import * as appActions from "redux/actions/app.actions";

interface IPublicRouteProps extends RouteProps {
  restricted: boolean;
}

const PublicRoute: FunctionComponent<IPublicRouteProps> = ({
  component: Component,
  restricted,
  ...rest
}) => {
  const dispatch = useDispatch();

  const initializePublicRoute = useCallback(() => {
    dispatch(appActions.initializePublicRoute(restricted));
  }, [dispatch, restricted]);

  useEffect(() => {
    initializePublicRoute();
  }, [initializePublicRoute]);

  if (!Component) return null;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PublicRoute;
