import { IAPIError } from "common/interfaces/api.interface";
import { IAsset } from "common/interfaces/file.interface";
import { INearpodCharacterCampaign } from "common/interfaces/nearpod-character-campaign.interface";
import { AnyAction } from "redux";
import { takeLatest, call, all, put } from "redux-saga/effects";
import {
  ON_CREATE_AND_EXPORT_REQUESTED,
  ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_REQUESTED,
} from "redux/constants/nearpod-character-campaign.constants";
import { getAssetImagePath } from "service/asset.service";
import * as services from "service/nearpod-character-campaign.service";
import { uploadPublicAssetService } from "service/upload.service";
import * as actions from "../actions/nearpod-character-campaign.actions";

function* createCampaign(action: AnyAction) {
  try {
    const response: INearpodCharacterCampaign = yield call(
      services.createCampaign,
      { ...action.formData, ...action.selectData }
    );
    yield put(actions.onCreateNearpodCharacterSucceed(response));
  } catch (err) {
    yield put(actions.onCreateNearpodCharacterFailed(err as IAPIError));
  }
}

function* onCreateAndExport(action: AnyAction) {
  try {
    let campaign!: INearpodCharacterCampaign;
    const avatarAsset: IAsset = yield call(
      uploadPublicAssetService,
      action.avatarFile
    );
    if (avatarAsset && avatarAsset._id) {
      const avatarPath: string = yield call(getAssetImagePath, avatarAsset._id);
      campaign = yield call(services.saveExport, action._id, avatarPath, true);
    }

    const badgeAsset: IAsset = yield call(
      uploadPublicAssetService,
      action.badgeFile
    );
    if (badgeAsset && badgeAsset._id) {
      const badgePath: string = yield call(getAssetImagePath, badgeAsset._id);
      campaign = yield call(services.saveExport, action._id, badgePath, false);
    }

    yield put(actions.onCreateAndExportSucceed(campaign));
  } catch (err) {
    yield put(actions.onCreateAndExportFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_REQUESTED, createCampaign),
  takeLatest(ON_CREATE_AND_EXPORT_REQUESTED, onCreateAndExport),
]);
