import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  width: 30%;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const FileName = styled.p`
  font-size: 15px;
  line-height: 25px;
`;

export const ErrorMessage = styled.p`
  font-size: 12px;
  height: 12px;
  text-align: left;
  color: ${COLORS.red};
  margin-left: 10px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
