import React, { FunctionComponent } from "react";
import IBadgeProps from "./types";
import {
  BodyImage,
  Container,
  FaceImage,
  ShortImage,
  TShirtImage,
  CapImage,
  Name,
  Country,
  Institute,
} from "./styles";

const Badge: FunctionComponent<IBadgeProps> = (props: IBadgeProps) => {
  return (
    <Container>
      <Name>{props.name}</Name>
      <Institute>{`from ${props.institution}`}</Institute>
      <Country>{`in ${props.region}`}</Country>
      <FaceImage alt="Face Image" src={props.faceImage} />
      <BodyImage
        alt="Body Image"
        src={props.bodyImage}
        character={props.character}
      />

      <ShortImage
        alt="Short Image"
        src={props.shortImage}
        character={props.character}
      />
      <TShirtImage
        alt="Shirt Image"
        src={props.tShirtImage}
        character={props.character}
      />
      <CapImage
        alt="Cap Image"
        src={props.capImage}
        character={props.character}
      />
    </Container>
  );
};

export default Badge;
