import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import { PUBLIC_URL } from "configs/configs";
import styled from "styled-components";
import { ICharacter } from "./types";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  background-image: url(${PUBLIC_URL}/nearpodAvatarCampaign/export/sm_rectangle_back.png);
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Name = styled.h2`
  width: 100%;
  position: absolute;
  font-family: "Avenir-Black";
  color: ${COLORS.bronze};
  font-size: 36px;
  z-index: 2;
  top: 30%;
  left: -7%;
  text-align: center;
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 18px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 12px;
  }
`;

export const Institute = styled.h2`
  width: 100%;
  position: absolute;
  font-family: "Avenir-Black";
  color: ${COLORS.bronze};
  font-size: 34px;
  top: 40%;
  left: -7%;
  text-align: center;
  z-index: 2;
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 18px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 12px;
  }
`;

export const Country = styled.h2`
  width: 100%;
  position: absolute;
  font-family: "Avenir-Black";
  color: ${COLORS.bronze};
  font-size: 34px;
  top: 50%;
  left: -7%;
  text-align: center;
  z-index: 2;

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 18px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 12px;
  }
`;

export const FaceImage = styled.img`
  width: 12%;
  position: absolute;
  z-index: -1;
  top: 8%;
  left: 78%;
`;

export const BodyImage = styled.img<ICharacter>`
  ${(props) =>
    props.character === 0 &&
    ` width: 17%;
      left: 77%;
      top: 32.5%;
  `}
  ${(props) =>
    props.character === 1 &&
    ` width: 17%;
      left: 73.6%;
      top: 35.3%;
    `}
  ${(props) =>
    props.character === 2 &&
    ` width: 23.5%;
      left: 72.5%;
      top: 31%;
    `}
  ${(props) =>
    props.character === 3 &&
    ` width: 24%;
      left: 70%;
      top: 35.4%;
    `}
  position: absolute;
  z-index: 2;
`;

export const TShirtImage = styled.img<ICharacter>`
  ${(props) =>
    props.character === 0 &&
    ` 
    width: 13.5%;
    top: 33%;
    left: 77%;
    `}
  ${(props) =>
    props.character === 1 &&
    ` 
      width: 12%;
      top: 35%;
      left: 77.9%;
    `}
  ${(props) =>
    props.character === 2 &&
    ` width: 18%;
      top: 32%;
      left: 74.5%;
    `}
  ${(props) =>
    props.character === 3 &&
    ` width: 14%;
      top: 35%;
      left: 76.7%;
    `}

  position: absolute;
  z-index: 2;
`;

export const CapImage = styled.img<ICharacter>`
  ${(props) =>
    props.character === 0 &&
    ` 
    top: 5%;
    left: 73.5%;
    width: 21%;
  `}
  ${(props) =>
    props.character === 1 &&
    ` top: 6%;
      left: 77%;
      width: 13.5%;
  `}
  ${(props) =>
    props.character === 2 &&
    ` top: 5%;
      left: 76.8%;
      width: 14%;
  `}
  ${(props) =>
    props.character === 3 &&
    ` top: 6%;
      left: 77.5%;
      width: 12.5%;
  `}
  position: absolute;
  z-index: 2;
`;

export const ShortImage = styled.img<ICharacter>`
  position: absolute;
  ${(props) =>
    props.character === 0 &&
    ` width: 8%;
      top: 51%;
      left: 80%;
  `}
  ${(props) =>
    props.character === 1 &&
    ` width: 8%;
      top: 53%;
      left: 79.5%;
    `}
  ${(props) =>
    props.character === 2 &&
    ` width: 10%;
      top: 57%;
      left: 78.8%;
    `}
    ${(props) =>
    props.character === 3 &&
    ` width: 10%;
      top: 49%;
      left: 78.8%;
    `}
  z-index: 2;
`;
