import { IShare } from "common/interfaces/share.interface";
import { AnyAction } from "redux";
import {
  ON_CREATE_EXPORT_DATA_SUCCEED,
  ON_CREATE_SHARE_CAMPAIGN_REQUESTED,
  ON_CREATE_SHARE_CAMPAIGN_SUCCEED,
  ON_DELETE_SHARE_CAMPAIGN_SUCCEED,
  ON_INITIALIZE_EDIT_VIEW_SUCCEED,
  ON_INITIALIZE_PLAYER_SUCCEED,
  ON_RETRIEVE_SHARE_CAMPAIGNS_SUCCEED,
  ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_SUCCEED,
  ON_UPDATE_PARSE_ROW_COUNT,
} from "redux/constants/share.constants";
import { ICSVRow } from "views/importCsvView/types";

export interface IShareReducerState {
  share: IShare;
  exportCsvData: ICSVRow[];
  shareCampaigns: IShare[];
  count: number;
  loading: boolean;
  requestCount: number;
  headers: string[];
}

const defaultState: IShareReducerState = {
  share: {
    _id: "",
    campaignId: "",
    variable: {
      text: [],
      image: [],
    },
    createdAt: "",
    updatedAt: "",
  },
  shareCampaigns: [],
  count: 0,
  loading: false,
  requestCount: 0,
  exportCsvData: [],
  headers: [],
};

const addShareData = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    share: action.data.shareData,
  };
};

const addShareCampaigns = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    shareCampaigns: action.data,
  };
};

const addCount = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    count: action.count,
  };
};

const setRequestCount = (state: IShareReducerState) => {
  const stateCopy = { ...state };
  const result = ++stateCopy.requestCount;
  return {
    ...state,
    requestCount: result,
  };
};

const setLoading = (state: IShareReducerState, action: AnyAction) => {
  return {
    ...state,
    loading: true,
    requestCount: 0,
    exportCsvData: [],
    headers: action.csvHeaders,
  };
};

const unsetLoading = (state: IShareReducerState) => {
  return {
    ...state,
    loading: false,
  };
};

const pushCsvData = (state: IShareReducerState, action: AnyAction) => {
  const newExportData = [...state.exportCsvData];
  return {
    ...state,
    exportCsvData: [...newExportData, action.row.csvRow],
  };
};

const shareReducer = (
  state: IShareReducerState = defaultState,
  action: AnyAction
): IShareReducerState => {
  switch (action.type) {
    case ON_INITIALIZE_PLAYER_SUCCEED:
    case ON_INITIALIZE_EDIT_VIEW_SUCCEED:
      return addShareData(state, action);
    case ON_RETRIEVE_SHARE_CAMPAIGNS_SUCCEED:
    case ON_DELETE_SHARE_CAMPAIGN_SUCCEED:
      return addShareCampaigns(state, action);
    case ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_SUCCEED:
      return addCount(state, action);
    case ON_CREATE_SHARE_CAMPAIGN_REQUESTED:
      return setLoading(state, action);
    case ON_CREATE_SHARE_CAMPAIGN_SUCCEED:
      return unsetLoading(state);
    case ON_UPDATE_PARSE_ROW_COUNT:
      return setRequestCount(state);
    case ON_CREATE_EXPORT_DATA_SUCCEED:
      return pushCsvData(state, action);
    default:
      return state;
  }
};

export default shareReducer;
