/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import React, { ChangeEvent, useRef, useState } from "react";
import {
  Container,
  FileName,
  ReplaceFileOrange,
  ReplaceFileText,
  Subtitle,
  Title,
  AcceptContainer,
  SubmitInput,
} from "./styles";
import IProps from "./types";

const ReplaceFile = (props: IProps) => {
  const [accepted, setAccepted] = useState(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onClickButton = () => {
    hiddenInputRef.current?.click();
  };

  const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  return (
    <Container>
      <Title>Campaign name</Title>
      <Subtitle>Upload your csv file to personalice ...</Subtitle>
      <ReplaceFileText>
        Selected file: <FileName>{props.filename}</FileName> |{" "}
        <ReplaceFileOrange onClick={onClickButton}>
          Replace file
        </ReplaceFileOrange>
      </ReplaceFileText>
      <input
        ref={hiddenInputRef}
        type="file"
        hidden
        onChange={(event) => props.onChangeFile(event)}
        accept={ACCEPT_TYPES.csv}
      />
      <AcceptContainer>
        <input
          onChange={(event) => onChangeCheckbox(event)}
          type="checkbox"
          name="accept terms and conditions"
        />
        <p>
          I confirm that I consent and agree to{" "}
          <span>Bodymovin Solutions Terms & Conditions</span>
        </p>
      </AcceptContainer>
      <SubmitInput
        disable={accepted}
        onClick={() => (accepted ? props.onAcceptTerms() : null)}
      >
        Confirm
      </SubmitInput>
    </Container>
  );
};

export default ReplaceFile;
