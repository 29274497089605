import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Button from "components/Button/Button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { FunctionComponent, useEffect, useState } from "react";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import TextVariableType from "enums/textVariable.enum";
import { IVariable } from "common/interfaces/share.interface";
import { IProps } from "./types";

const Row: FunctionComponent<IProps> = (props: IProps) => {
  const {
    shareCampaign,
    keys,
    isItemSelected,
    handleClick,
    labelId,
    editShareCampaignVariables,
  } = props;
  const [open, setOpen] = useState(false);
  const [textVariables, setTextVariables] = useState<ITextVariable[]>([]);

  const handleEdit = (shareCampaignId: string) => {
    editShareCampaignVariables(shareCampaignId);
  };

  useEffect(() => {
    if ((shareCampaign.variable as ITextVariable[]).length) {
      setTextVariables(shareCampaign.variable as ITextVariable[]);
    } else if ((shareCampaign.variable as IVariable).text) {
      setTextVariables((shareCampaign.variable as IVariable).text);
    }
  }, [shareCampaign.variable]);

  return (
    <>
      <TableRow
        hover
        selected={isItemSelected}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={shareCampaign._id}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) => handleClick(event, shareCampaign._id)}
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
          />
        </TableCell>
        <TableCell
          id={labelId}
          component="th"
          scope="row"
          align="left"
          padding="none"
          key={shareCampaign._id}
        >
          {shareCampaign._id}
        </TableCell>
        <TableCell
          id={labelId}
          component="th"
          scope="row"
          align="left"
          padding="none"
        >
          {shareCampaign.campaignId}
        </TableCell>
        <TableCell
          id={labelId}
          component="th"
          scope="row"
          align="left"
          padding="none"
        >
          {shareCampaign.createdAt}
        </TableCell>
        <TableCell id={labelId} component="th" scope="row" padding="none">
          {shareCampaign.updatedAt}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <Button onClick={() => handleEdit(shareCampaign._id)}>Edit</Button>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Variables
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {keys.map((key: string) => {
                      return <TableCell key={key}>{key}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {textVariables
                    .filter(
                      (variable) => variable.type !== TextVariableType.Still
                    )
                    .map((variable: ITextVariable) => (
                      <TableRow>
                        {keys.map((key: string) => {
                          return (
                            <TableCell key={variable._id}>
                              {variable[key]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;
