import { createStyles, makeStyles, Theme } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
  })
);
