/* eslint-disable jsx-a11y/html-has-lang */
import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onInitializeLottieJson } from "redux/actions/lottie-json.actions";
import { getLottieData } from "redux/selectors/lottie-json.selector";
import PshEoyProspectsView from "views/PshEoyProspectsView/PshEoyProspectsView";
import { createJsonDataProspects } from "helper/eoy-2022/createJsonDataProspects";
import EoyAnimation from "./video.json";

const PshEoyProspects: FunctionComponent = () => {
  const { projectName, id }: { projectName: string; id: string } = useParams();
  const dispatch = useDispatch();
  const lottieData = useSelector(getLottieData());

  useEffect(() => {
    const data = createJsonDataProspects(projectName, id);
    const parsed = JSON.parse(data);
    dispatch(onInitializeLottieJson(EoyAnimation, parsed));
  }, [dispatch, id, projectName]);

  return lottieData.data ? (
    <PshEoyProspectsView jsonData={lottieData.data} />
  ) : null;
};

export default PshEoyProspects;
