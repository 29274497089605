import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import history from "helper/history";
import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";
import reducers from "./reducers/reducers";
import rootSaga from "./sagas";
import redirectMiddleware from "./middlewares/redirect.middleware";
import loggingMiddleware from "./middlewares/logging.middleware";

export const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      redirectMiddleware,
      loggingMiddleware,
      sagaMiddleware,
      createSentryMiddleware(Sentry, {})
    )
  )
);

sagaMiddleware.run(rootSaga);

export default store;
