import styled from "styled-components";

export const CustomForm = styled.form`
  width: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: center;
`;
