import { AxiosResponse } from "axios";
import { INearpodCharacterCampaignFormData } from "common/interfaces/nearpod-character-campaign.interface";
import { post, put } from "./api";

export const createCampaign = async (
  data: INearpodCharacterCampaignFormData
): Promise<AxiosResponse> => {
  const response = await post(`nearpod-character-campaign/`, { ...data });
  return response.data;
};

export const saveExport = async (
  _id: string,
  path: string,
  isAvatar: boolean
): Promise<AxiosResponse> => {
  const response = await put(`nearpod-character-campaign/export/`, {
    _id,
    path,
    isAvatar,
  });
  return response.data;
};
