import { Container, CssBaseline } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { IProps } from "./types";

const ContainerFluid: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <>
      <CssBaseline />
      <Container data-testid="containerFluid" maxWidth="sm">
        {props.children}
      </Container>
    </>
  );
};

export default ContainerFluid;
