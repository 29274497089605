import React, { FunctionComponent, useEffect, useState } from "react";
import i18next from "i18next";
import "./i18n";
import Button from "components/Button/Button";
import CsvDownloader from "react-csv-downloader";
import getTimeStamp from "helper/getTimeStamp.helper";
import { ICSVRow } from "views/importCsvView/types";
import {
  ButtonContainer,
  DataContainer,
  Line,
  MetricsDashboardContainer,
  Title,
} from "./styles";
import { IMetricsDashoardViewProps } from "./types";

const MetricsDashboardView: FunctionComponent<IMetricsDashoardViewProps> = (
  props: IMetricsDashoardViewProps
) => {
  const { metricData } = props;
  const [csvDataDownload, setCsvDataDownload] = useState<ICSVRow>();
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  useEffect(() => {
    if (metricData) {
      const headers = Object.keys(metricData);
      let exportCsv = {};
      Object.entries(metricData).forEach(([key, value]) => {
        exportCsv = {
          ...exportCsv,
          [key]: value,
        };
      });
      setCsvDataDownload(exportCsv);
      setCsvHeaders(headers);
    }
  }, [metricData]);
  return (
    <MetricsDashboardContainer>
      <Title>{i18next.t("METRICS_DASHBOARD_VIEW:TITLE")}</Title>
      {metricData && metricData.viewsCount > 0 ? (
        <DataContainer>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:VIEWS", {
              count: metricData.viewsCount,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:TOTAL_WATCHED_TIME", {
              time: metricData.totalWatchTime,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:SOUND_PLAYED_COUNT", {
              count: metricData.soundPlayedCount,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:FINISHED_VIDEO_PERCENTAGE", {
              percentage: metricData.finishedVideoPercentage,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:FINISHED_VIDEO_COUNT", {
              count: metricData.finishedVideoCount,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:CLICKED_CTA_COUNT", {
              count: metricData.clickedCTACount,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:AVERAGE_WATCHED_TIME", {
              time: metricData.averageWatchedTime,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:UNIQUE_VIEWERS", {
              count: metricData.uniqueViewers,
            })}
          </Line>
          <Line>
            {i18next.t("METRICS_DASHBOARD_VIEW:UP_TO_SEVENTY_FIVE", {
              percentage: metricData.upToSeventyFivePercent,
            })}
          </Line>
          <ButtonContainer>
            <CsvDownloader
              filename={`BMS_METRICS_EXPORT_${getTimeStamp()}`}
              extension=".csv"
              separator=","
              datas={csvDataDownload ? [csvDataDownload] : []}
              columns={csvHeaders}
              text="DOWNLOAD"
            >
              <Button>{i18next.t("METRICS_DASHBOARD_VIEW:DOWNLOAD")}</Button>
            </CsvDownloader>
          </ButtonContainer>
        </DataContainer>
      ) : (
        i18next.t("METRICS_DASHBOARD_VIEW:NO_METRICS")
      )}
    </MetricsDashboardContainer>
  );
};

export default MetricsDashboardView;
