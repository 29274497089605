import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "ENGAGE_CHARACTER_STEP_ONE", {
  NAME_REQUIRED: "Name is required",
  NAME_REGEX: "Name should only contain letters",
  EMAIL_REQUIRED: "Email is required",
  EMAIL_REGEX: "Invalid email",
  INSTITUTION_REQUIRED: "Institution is required",
  COUNTRY_REQUIRED: "Country is required",
  STATE_REQUIRED: "State is required",
  NONE: "None",
  FORM_TITLE: "Please complete your information:",
  COUNTRY: "Country",
  STATE: "State",
  CONTINUE: "Continue",
});
