import { SIZES } from "common/constants/sizes.contants";
import { PUBLIC_URL } from "configs/configs";
import styled, { keyframes } from "styled-components";

export const FullScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  @media screen and (max-width: ${SIZES.smallTablet}px) {
    transform: rotate(90deg) scale(1.6);
  }
`;

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

export const OpacityContainer = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
  position: relative;

  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  display: flex;
  align-items: center;
  background: center / cover no-repeat url(${PUBLIC_URL}/eoy_psh/bg.svg);
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: red;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
`;
