import { COLORS } from "common/constants/color.contants";
import { SIZES } from "common/constants/sizes.contants";
import styled from "styled-components";
import { ReactComponent as backButton } from "assets/avatarNearpodCampaign/arrow_continue-01.svg";

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #f29150;

  @media screen and (max-height: ${SIZES.mediumTablet}px) {
    overflow-y: scroll;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 95%;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    width: 90%;
    height: 100%;
    justify-content: center;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 80%;
    height: 80%;
  }
`;

export const TitleWrapper = styled.div`
  width: 50%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  z-index: 2;

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    width: 35%;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin-bottom: 10px;
  }
`;

export const ImageTitle = styled.img`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin-bottom: 10px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: "Avenir-Black";
  color: ${COLORS.white};
  font-size: 36px;
  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    font-size: 28px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 16px;
  }
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 65%;
    align-self: center;
    margin-bottom: 25px;
  }

  @media screen and (max-width: ${SIZES.phoneLandscape}px) and (orientation: landscape) {
    width: 100%;
  }
`;

export const BadgeWrapper = styled.div`
  width: calc(1019px / 1.5);
  height: calc(582px / 1.5);
  z-index: 2;

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    width: calc(1019px / 2);
    height: calc(582px / 2);
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: calc(1019px / 4);
    height: calc(582px / 4);
  }
`;

export const AvatarWrapper = styled.div`
  width: 300px;
  height: 300px;
  z-index: 2;
  border: 7px solid white;
  border-radius: 100%;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 120px;
    height: 120px;
    border: 3px solid white;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  place-content: space-between;

  @media screen and (max-width: ${SIZES.smallDesktop}px) and (orientation: landscape) {
    width: 65%;
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 70%;
    align-self: center;
  }
`;

export const BadgeButtonWrapper = styled.div`
  width: 40%;
  z-index: 2;
`;

export const AvatarButtonWrapper = styled.div`
  width: 23%;
  z-index: 2;
`;

export const BackButtonContainer = styled.div`
  width: 130px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.white};
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  z-index: 2;

  &:hover {
    box-shadow: 0 0 0 3px ${COLORS.redSalsa};
  }

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 100px;
  }
`;

export const BackButton = styled(backButton)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin: 0 20px;
  transform: rotate(180deg);
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
`;

export const BackText = styled.p`
  font-size: 18px;
  font-family: Avenir-Medium;
  color: ${COLORS.fiord};

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 14px;
  }
`;

export const Button = styled.button`
  background-color: ${COLORS.redSalsa};
  color: ${COLORS.white};
  font-family: Avenir-Medium;
  border: none;
  padding: 10px 25px;
  border-radius: 10px;
  font-size: 18px;
  align-self: center;
  cursor: pointer;
  width: 180px;
  margin: 0 10px;
  display: flex;
  transition: all 0.3s ease-out;
  box-shadow: 0 9px 9px rgb(0 0 0 / 16%);
  z-index: 2;
  top: 30px;
  justify-content: center;

  &:hover {
    background-color: ${COLORS.vivid};
  }
`;
