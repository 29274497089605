import React, { FunctionComponent } from "react";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import AddClientView from "views/AddClientView/AddClientView";
import { useDispatch, useSelector } from "react-redux";
import { onSubmitClientCreate } from "redux/actions/client.actions";
import { ICreateClientFormData } from "views/AddClientView/types";
import { getClientLoadingSelector } from "redux/selectors/client.selector";
import getErrorSelector from "redux/selectors/error.selector";
import { IError } from "common/interfaces/error.interface";
import { hideErrorNotification } from "redux/actions/error.actions";

const CreateClient: FunctionComponent = () => {
  const dispatch = useDispatch();
  const onSubmit = (formData: ICreateClientFormData) => {
    dispatch(onSubmitClientCreate(formData.name));
  };
  const loadingSelector = useSelector(getClientLoadingSelector());
  const errorSelector: IError = useSelector(getErrorSelector());

  setTimeout(() => {
    if (errorSelector.error) {
      dispatch(hideErrorNotification());
    }
  }, 2500);

  return (
    <DashboardLayout>
      <AddClientView
        onSubmit={onSubmit}
        clientError={errorSelector}
        loading={loadingSelector}
      />
    </DashboardLayout>
  );
};

export default CreateClient;
