import { ITextVariable } from "common/interfaces/text-variable.interface";
import { ITextVariableReducerState } from "redux/reducers/text-variable.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getTextVariable = (state: IStore) => state.textVariable;

const getTextVariableSelector = (): OutputSelector<
  IStore,
  ITextVariableReducerState,
  (res: ITextVariableReducerState) => ITextVariableReducerState
> => createSelector([getTextVariable], (textVariable) => textVariable);

export const getTextVariableById = (
  id: string
): OutputSelector<
  IStore,
  ITextVariable[],
  (res: ITextVariableReducerState) => ITextVariable[]
> =>
  createSelector([getTextVariable], (textVariable) =>
    textVariable.textVariable.filter((text: ITextVariable) => text._id === id)
  );

export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: ITextVariableReducerState) => number
> => createSelector([getTextVariable], (data) => data.count);

export default getTextVariableSelector;
