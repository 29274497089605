import { IObject } from "./types";

const removeObjectNullProperties = (obj: IObject): IObject => {
  const objCopy = { ...obj };
  Object.keys(objCopy).forEach((key) => {
    const value = objCopy[key];
    const hasProperties = value && Object.keys(value).length > 0;
    if (value === null) {
      delete objCopy[key];
    } else if (typeof value !== "string" && hasProperties) {
      removeObjectNullProperties(value as IObject);
    }
  });
  return objCopy;
};

export default removeObjectNullProperties;
