export const COLORS = {
  black: "#000000",
  boxShadow: "#00000020",
  gray: "#727272",
  greenChristi: "#7DB00E",
  modalBackground: "rgba(71,79,99,0.75)",
  red: "#FF0000",
  white: "#FFFFFF",
  sanMarino: "#3F51B5",
  luckyPoint: "#141F5E",
  porcelain: "#EBEDEF",
  wildSand: "#F4F4F4",
  halfBaked: "#81C9C0",
  fiord: "#425061",
  bronze: "#742C08",
  chocolate: "#D36122",
  redSalsa: "#FC3C46",
  vivid: "#951F26",
  silverSand: "#C4C4C4",
  blueCrayola: "#1772ED",
  denim: "#0f60cd",
  gargoyleGas: "#ffdc4e",
};
export const PICKER_COLORS = [
  "#D9E3F0",
  "#F47373",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
  COLORS.white,
  COLORS.black,
];
