import { takeLatest, call, all, put } from "redux-saga/effects";
import { AnyAction } from "redux";
import { upload } from "service/upload.service";
import { IAPIError } from "common/interfaces/api.interface";
import { IAsset } from "common/interfaces/file.interface";
import * as assetsActions from "../actions/asset.actions";
import * as constants from "../constants/asset.constants";

function* uploadImages(action: AnyAction) {
  try {
    const response: IAsset[] = yield call(upload, action.files);
    yield put(assetsActions.onUploadSuccess(response));
  } catch (err) {
    yield put(assetsActions.onUploadFailed(err as IAPIError));
  }
}

export default all([takeLatest(constants.ON_UPLOAD_REQUESTED, uploadImages)]);
