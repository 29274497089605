import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";
import { createStyles, makeStyles } from "@material-ui/core";
import { ReactComponent as iconPlay } from "assets/wellnetEOY/icon_play.svg";
import { SIZES } from "common/constants/sizes.contants";
import { IImage } from "./types";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 95%;
  }
`;

export const Wrapper = styled.div`
  width: 60%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 2;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 100%;
  }
`;

export const Title = styled.h1`
  width: 740px;
  color: ${COLORS.white};
  font-size: 30px;
  font-family: Montserrat-SemiBold;
  text-align: center;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 340px;
    font-size: 14px;
  }
`;

export const Button = styled.button`
  width: 190px;
  height: 50px;
  border-radius: 28px;
  background-color: ${COLORS.blueCrayola};
  border: none;
  color: ${COLORS.white};
  font-family: Montserrat-Regular;
  font-size: 24px;
  transition: all 0.2s ease-out;
  &:hover {
    background-color: ${COLORS.denim};
  }
  cursor: pointer;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 139px;
    height: 36px;
    font-size: 14px;
  }
`;

export const VideosContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    grid-template-rows: 60px 60px;
    grid-gap: 5px;
  }
`;

export const VideoWrapper = styled.a`
  outline: none;
  text-decoration: none;
`;

export const VideoPlay = styled(iconPlay)`
  width: 80px;
  height: 80px;
  transition: all 0.3s ease-out;
  @media screen and (max-width: ${SIZES.bigDesktop}px) {
    width: 60px;
    height: 60px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 40px;
    height: 40px;
  }
`;

export const VideoImageContainer = styled.div<IImage>`
  width: 281px;
  height: 158px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  &:hover {
    ${VideoPlay} {
      transform: scale(1.2);
    }
  }
  @media screen and (max-width: ${SIZES.bigDesktop}px) {
    width: 230px;
    height: 140px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 110px;
    height: 60px;
  }
`;

export const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin-top: 5px;
  }
`;

export const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid;
  background-image: linear-gradient(18deg, white 50%, transparent 50%),
    linear-gradient(-90deg, red 50%, transparent 50%);
`;

export const Option = styled.div`
  width: 250px;
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${COLORS.white};
  font-size: 20px;
  font-family: Montserrat-Regular;
  text-align: left;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    width: 185px;
    font-size: 14px;
  }
`;

export const SubscriptionTitle = styled.p`
  color: ${COLORS.white};
  font-size: 20px;
  font-family: Montserrat-Regular;
  text-align: center;
  margin-top: 10px;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    font-size: 14px;
  }
`;

export const OptionsContainer = styled.div`
  margin-top: 15px;
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    margin-top: 0;
  }
`;

export const OptionCircle = styled.input`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border: 2px solid ${COLORS.white};
  border-radius: 50%;
  color: transparent;
  transform: translateY(-0.075em);
  &:before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${COLORS.black};
    /* Windows High Contrast Mode */
    background-color: transparent;
  }
  &:checked::before {
    transform: scale(1);
  }
`;

export const radioButtonStyles = makeStyles(() =>
  createStyles({
    sx: {
      color: COLORS.white,
      "&.Mui-checked": {
        color: COLORS.white,
      },
    },
  })
);
