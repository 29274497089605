/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from "react";
import { IProps } from "./types";
import {
  // CloseModal,
  Icon,
  ModalContainer,
  Rectangle,
  TextContainer,
  Title,
  RightContainer,
  Error,
  TransitionModal,
} from "./styles";

const MessageModal = (props: IProps) => {
  const [fadeType, setFadeType] = useState("");

  useEffect(() => {
    setTimeout(() => setFadeType("in"), 0);
  }, []);

  /**
   *
   *  @GUIDOG This is done beacuse the close modal, set the state to false, and we do not need that
   *  FIX THIS
   *
   *
   */

  // const handleClose = () => {
  //   setTimeout(() => setFadeType("out"), 0);
  // };

  useEffect(() => {
    if (fadeType === "in") {
      setTimeout(() => setFadeType("out"), 5000);
    }
  }, [fadeType]);

  if (fadeType === "out") {
    props.onClose();
  }

  return (
    <TransitionModal fadeType={fadeType} topPosition={props.topPosition}>
      <ModalContainer bgColor={props.bgColor}>
        <Rectangle>
          <Icon src={props.icon} alt="Message Icon" />
        </Rectangle>
        <RightContainer>
          <TextContainer>
            <Title>{props.title}</Title>
            <Error>{props.message}</Error>
          </TextContainer>
          {/* <CloseModal
            src={props.closeIcon}
            onClick={handleClose}
            alt="Close Icon"
          /> */}
        </RightContainer>
      </ModalContainer>
    </TransitionModal>
  );
};

export default MessageModal;
