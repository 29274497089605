import { IError } from "common/interfaces/error.interface";
import ContainerFluid from "components/ContainerFluid/ContainerFluid";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onInitializeLogin, onLogin } from "redux/actions/user.actions";
import getErrorSelector from "redux/selectors/error.selector";
import LoginView from "views/LoginView/LoginView";
import { ILoginFormData } from "views/LoginView/types";

const Login: FunctionComponent = () => {
  const dispatch = useDispatch();
  const errorSelector: IError = useSelector(getErrorSelector());

  useEffect(() => {
    dispatch(onInitializeLogin());
  }, [dispatch]);

  const onSubmit = (formData: ILoginFormData) => {
    dispatch(onLogin(formData));
  };

  return (
    <ContainerFluid>
      <LoginView onSubmit={onSubmit} error={errorSelector.error} />
    </ContainerFluid>
  );
};

export default Login;
