/* eslint-disable */

import {
  IColorVariable,
  IRGBColor,
} from "common/interfaces/color-variable.interface";
import { IDataLayer } from "common/interfaces/layer.interface";
import { hexToRGB } from "helper/hexToRGB.helper";
import { IObject } from "helper/types";

export const replaceObjectsColors = (
  dataLayer: IDataLayer,
  colors: IColorVariable[]
) => {
  const dataCopy = { ...dataLayer };
  const colorsToReplace = colors;
  if (dataCopy.layers) {
    const dataAssetLayers: IObject[] = dataCopy.layers.map((dataAssetLayer) => {
      colorsToReplace.forEach((color: IColorVariable) => {
        if (dataAssetLayer.nm === color.name) {
          let textColorRGB: IRGBColor | null = { r: 0, g: 0, b: 0 };
          textColorRGB = hexToRGB(color.value);
          if (dataAssetLayer.shapes) {
            const dataAssetLayerShapes = (
              dataAssetLayer.shapes as IObject[]
            ).map((dataAssetLayerShape: IObject) => {
              if (
                dataAssetLayerShape.it &&
                (dataAssetLayerShape.it as IObject[])[1] &&
                (dataAssetLayerShape.it as IObject[])[1].c &&
                ((dataAssetLayerShape.it as IObject[])[1].c as IObject)
              ) {
                if (textColorRGB) {
                  (((dataAssetLayerShape.it as IObject[])[1].c as IObject)
                    .k as number[]) = [
                    textColorRGB.r,
                    textColorRGB.g,
                    textColorRGB.b,
                    1,
                  ];
                }
              }
              return dataAssetLayerShape;
            });
            dataAssetLayer.shapes = dataAssetLayerShapes;
          }
          return dataAssetLayer;
        } else if (
          (dataAssetLayer.nm === "Forearm" ||
            dataAssetLayer.nm === "Forearm 2") &&
          (dataAssetLayer.shapes as IObject[]).length
        ) {
          (dataAssetLayer.shapes as IObject[]).map((dataShape) => {
            if (dataShape.nm === color.name) {
              let textColorRGB: IRGBColor | null = { r: 0, g: 0, b: 0 };
              textColorRGB = hexToRGB(color.value);
              if (
                dataShape.it &&
                (dataShape.it as IObject[])[1] &&
                (dataShape.it as IObject[])[1].c &&
                ((dataShape.it as IObject[])[1].c as IObject) &&
                textColorRGB
              ) {
                (((dataShape.it as IObject[])[1].c as IObject).k as number[]) =
                  [textColorRGB.r, textColorRGB.g, textColorRGB.b, 1];
              }
            }
            return dataShape;
          });
        }
      });
      return dataAssetLayer;
    });
    dataCopy.layers = dataAssetLayers;
    return dataCopy;
  }

  return dataCopy;
};
