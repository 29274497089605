import { ILayer } from "common/interfaces/layer.interface";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSaveData,
  onSaveEmail,
  onSendData,
} from "redux/actions/wellnetEOY.actions";
import { GetWellnetEOYData } from "redux/selectors/wellnetEOY.selector";
import Player from "views/Player/Player";
import { useParams } from "react-router-dom";
import WellnetEOYView from "views/WellnetEOY/WellnetEOYView/WellnetEOYView";
import * as shareActions from "redux/actions/share.actions";
import * as metricActions from "redux/actions/metric.actions";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import {
  getShareImageVariables,
  getShareTextVariables,
} from "redux/selectors/share.selector";
import getTemplateSelector from "redux/selectors/template.selector";
import TextVariableType from "enums/textVariable.enum";
import { replaceVariables } from "helper/get_json_layer.helper";
import RotattionAnim from "assets/wellnetEOY/wellnet_phone_rotation.json";
import { useMobileOrientation, isMobile, isTablet } from "react-device-detect";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import { useResize } from "helper/resize.helper";
import { ViewContainer } from "views/WellnetEOY/styles";

const WellnetEOYCampaign: FunctionComponent = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const { isLandscape } = useMobileOrientation();
  const [isComplete, setIsComplete] = useState(false);
  const [layersData, setLayersData] = useState<ILayer[]>([]);
  const getShareTextVariable = useSelector(getShareTextVariables());
  const getTemplateData = useSelector(getTemplateSelector());
  const [textVariables, setTextVariables] = useState<ITextVariable[]>([]);
  const getShareImageVariable = useSelector(getShareImageVariables());
  const data = useSelector(GetWellnetEOYData());
  const [step, setStep] = useState<number>(1);
  const [back, setBack] = useState<boolean>(false);
  const [widthValue, handleWindowSizeChangeMethod, heightValue]: (
    | number
    | (() => void)
  )[] = useResize();
  const width = widthValue as number;
  const height = heightValue as number;
  const handleWindowSizeChange = handleWindowSizeChangeMethod as () => void;

  window.addEventListener("resize", handleWindowSizeChange);

  useEffect(() => {
    dispatch(shareActions.onInitializePlayer(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getShareTextVariable) {
      const email = getShareTextVariable.find((textVariable) =>
        textVariable.value.includes("@")
      )?.value;
      if (email) {
        dispatch(onSaveEmail(email));
      }
    }
  }, [dispatch, getShareTextVariable]);

  useEffect(() => {
    if (getTemplateData.template && !!getTemplateData.template.layers.length) {
      const textVariablesFiltered =
        getTemplateData.template.textVariables.filter(
          (textVariable: ITextVariable) =>
            textVariable.type === TextVariableType.Still
        );
      getShareTextVariable.forEach((textVariableShare: ITextVariable) =>
        textVariablesFiltered.push(textVariableShare)
      );
      setTextVariables(textVariablesFiltered);
      replaceVariables(
        getTemplateData.template,
        setLayersData,
        getTemplateData.template.layers,
        textVariablesFiltered,
        getShareImageVariable
      );
    }
  }, [
    getTemplateData.template,
    getTemplateData.template.layers,
    getShareTextVariable,
    getShareImageVariable,
  ]);

  const onCreateMetric = () => {
    dispatch(metricActions.onCreateMetric(id));
  };

  const onUpdateWatchedTime = (lastSecondPlayed: number) => {
    dispatch(metricActions.onUpdateWatchedTime(lastSecondPlayed));
  };

  const onUpdateCTAClicked = () => {
    dispatch(metricActions.onUpdateCTAClicked());
  };

  const onUpdateAudioClicked = () => {
    dispatch(metricActions.onUpdateAudioClicked());
  };

  const onClickEndCta = () => {
    window.open("/", "_blank");
  };

  const onCompleteVideo = () => {
    setIsComplete(true);
  };

  const onClickDone = (pollResult: number, textArea: string) => {
    dispatch(onSaveData({ pollResult, textArea }));
    setStep(2);
  };
  const onClickAccept = (subscription: boolean) => {
    dispatch(onSendData(data, subscription));
    setStep(3);
  };
  const onClickBack = () => {
    setBack(true);
    setStep(2);
  };

  const onClickContinue = () => {
    setStep(3);
  };

  return (
    <>
      {!isLandscape && (isMobile || isTablet) ? (
        <ViewContainer width={width} height={height}>
          <LottiePlayer
            autoplay
            loop
            animationJsonPath={RotattionAnim}
            preserveAspectRatio="xMidYMid meet"
          />
        </ViewContainer>
      ) : (
        <>
          {!isComplete && !!layersData.length && (
            <Player
              isSharePlayer
              layers={layersData}
              textVariables={textVariables}
              audio={getTemplateData.template.audio?.dataPath}
              backgroundColor={
                getTemplateData.template.template.backgroundColor
              }
              onCreateMetric={onCreateMetric}
              onUpdateWatchedTime={onUpdateWatchedTime}
              onUpdateCTAClicked={onUpdateCTAClicked}
              onUpdateAudioClicked={onUpdateAudioClicked}
              duration={getTemplateData.template.template.duration}
              onClickEndCta={onClickEndCta}
              videoPath={getTemplateData.template.video?.dataPath}
              onCompleteVideo={onCompleteVideo}
            />
          )}
          {isComplete && (
            <WellnetEOYView
              step={step}
              back={back}
              onClickDone={onClickDone}
              onClickAccept={onClickAccept}
              onClickContinue={onClickContinue}
              onClickBack={onClickBack}
            />
          )}
        </>
      )}
    </>
  );
};

export default WellnetEOYCampaign;
