import styled, { keyframes } from "styled-components";
import { SIZES } from "common/constants/sizes.contants";
import { ReactComponent as backButton } from "assets/wellnetEOY/back_button.svg";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
`;

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`;

export const Wrapper = styled.div`
  width: 85%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 2;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;

  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) and (orientation: landscape) {
    height: 90%;
  }
`;

export const BackButton = styled(backButton)`
  width: 75px;
  height: 75px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: ${SIZES.bigDesktop}px) {
    width: 50px;
    height: 50px;
  }
  @media screen and (max-width: ${SIZES.smallDesktop}px) {
    width: 60px;
    height: 60px;
  }
  @media screen and (max-width: ${SIZES.bigPhoneLandscape}px) {
    width: 40px;
    height: 40px;
  }
`;
