import { IWellnetEOYReducerState } from "common/interfaces/wellnetEOY.interface";
import { AnyAction } from "redux";
import {
  ON_SAVE_DATA,
  ON_SAVE_EMAIL,
} from "redux/constants/wellnetEOY.constants";

const defaultState: IWellnetEOYReducerState = {
  pollResult: 0,
  textArea: "",
  email: "",
};

const saveData = (state: IWellnetEOYReducerState, action: AnyAction) => {
  return {
    ...state,
    pollResult: action.data.pollResult,
    textArea: action.data.textArea,
  };
};

const saveEmail = (state: IWellnetEOYReducerState, action: AnyAction) => {
  return {
    ...state,
    email: action.email,
  };
};

const wellnetEOYReducer = (
  state: IWellnetEOYReducerState = defaultState,
  action: AnyAction
): IWellnetEOYReducerState => {
  switch (action.type) {
    case ON_SAVE_DATA:
      return saveData(state, action);
    case ON_SAVE_EMAIL:
      return saveEmail(state, action);
    default:
      return state;
  }
};

export default wellnetEOYReducer;
