import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onInitializeMetricDashboard } from "redux/actions/metric.actions";
import { getMetricsData } from "redux/selectors/metric.selector";
import StatsCampaign from "views/StatsCampaign/StatsCampaign";

const StatsCampaignContainer: FunctionComponent = () => {
  const { campaignId }: { campaignId: string } = useParams();
  const dispatch = useDispatch();
  const metricData = useSelector(getMetricsData());

  useEffect(() => {
    dispatch(onInitializeMetricDashboard(campaignId));
  }, [dispatch, campaignId]);

  return metricData.viewsCount > 0 ? (
    <StatsCampaign metrics={metricData} />
  ) : null;
};

export default StatsCampaignContainer;
