import { ITextVariable } from "common/interfaces/text-variable.interface";
import React, { useState, useEffect, FunctionComponent } from "react";
import i18next from "i18next";
import "./i18n";
import EditForm from "components/EditForm/EditForm";
import { IField } from "components/EditForm/types";
import TextVariableType from "enums/textVariable.enum";
import { Container } from "./styles";
import { IProps } from "./types";

const EditShareCampaignVariablesView: FunctionComponent<IProps> = (
  props: IProps
) => {
  const [values, setValues] = useState<ITextVariable[]>([]);
  const [fields, setFields] = useState<IField[]>([]);

  useEffect(() => {
    const newValues = [...props.textVariables];
    setValues(newValues);
    setFields(
      props.textVariables.map((variable) => {
        return {
          value:
            variable.type === TextVariableType.CTA
              ? variable.ctaLink
              : variable.value,
          type: "text",
          label: variable.name,
        };
      })
    );
  }, [props.textVariables]);

  const onChangeValue = (newValue: string, name: string, index: number) => {
    const items = [...values];
    const item = values.find((value: ITextVariable) => value.name === name);
    if (item) {
      if (item.type === 2) {
        item.ctaLink = newValue;
        items[index] = item;
        setValues(items);
      } else {
        item.value = newValue;
        items[index] = item;
        setValues(items);
      }
    }
  };

  const editVariables = () => {
    props.editVariables(values);
  };

  return (
    <Container>
      <EditForm
        buttonText={i18next.t("EDIT_SHARE:BUTTON_TEXT")}
        fields={fields}
        onChange={onChangeValue}
        onSubmit={editVariables}
      />
    </Container>
  );
};
export default EditShareCampaignVariablesView;
