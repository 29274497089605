/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/html-has-lang */
import React, { FunctionComponent, useEffect } from "react";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import { FullScreenContainer } from "./styles";
import "./psh-eoy-styles.css";

interface IProps {
  jsonData: any;
}

const PshEoyProspectsView: FunctionComponent<IProps> = ({ jsonData }) => {
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("full--screen--container");
    document.documentElement.classList.add("html--container-lottie");
    document.getElementById("root")?.classList.add("lottie-root-container");
    return () => {
      document.body.classList.remove("full--screen--container");
      document.documentElement.classList.remove("html--container-lottie");
      document
        .getElementById("root")
        ?.classList.remove("lottie-root-container");
    };
  }, []);

  return (
    <FullScreenContainer>
      <LottiePlayer
        animationJsonPath={jsonData}
        autoplay
        loop={false}
        preserveAspectRatio="xMidYMid Meet"
      />
    </FullScreenContainer>
  );
};

export default PshEoyProspectsView;
