import React, { FunctionComponent } from "react";
import { Form, Field } from "react-final-form";
import i18next from "i18next";
import {
  passwordConfirmationValidation,
  requiredValidation,
} from "helper/validations";
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { IChangePasswordFormProps } from "./types";
import { CustomForm, ButtonContainer } from "./styles";
import "./i18n";

const ChangePasswordForm: FunctionComponent<IChangePasswordFormProps> = (
  props: IChangePasswordFormProps
) => (
  <Form
    onSubmit={props.onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <CustomForm onSubmit={handleSubmit}>
        <Field
          label={i18next.t("CHANGE_PASSWORD_FORM:PASSWORD")}
          name="password"
          render={Input}
          type="password"
          validate={requiredValidation}
        />
        <Field
          label={i18next.t("CHANGE_PASSWORD_FORM:CONFIRM_PASSWORD")}
          name="confirmPassword"
          render={Input}
          type="password"
          validate={passwordConfirmationValidation}
        />
        <ButtonContainer>
          <Button disabled={pristine || submitting} type="submit">
            {i18next.t("CHANGE_PASSWORD_FORM:SUBMIT_BUTTON")}
          </Button>
        </ButtonContainer>
      </CustomForm>
    )}
  />
);

export default ChangePasswordForm;
