import React, { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, RouteProps } from "react-router-dom";

import * as appActions from "redux/actions/app.actions";

interface IPrivateRouteProps extends RouteProps {
  isUserLogged: boolean;
}

const PrivateRoute: FunctionComponent<IPrivateRouteProps> = ({
  component: Component,
  isUserLogged,
  ...rest
}) => {
  const dispatch = useDispatch();
  const initializePrivateRoute = useCallback(() => {
    dispatch(appActions.initializePrivateRoute());
  }, [dispatch]);

  useEffect(() => {
    initializePrivateRoute();
  }, [initializePrivateRoute]);

  if (!Component) return null;
  return isUserLogged ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : null;
};

export default PrivateRoute;
