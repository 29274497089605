import { FieldValidator } from "final-form";
import i18next from "i18next";
import {
  COMPLETE_NAME_REGEX,
  EMAIL_REGEX,
  PASSWORD_MINIMUM_LENGTH,
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
} from "./contants";
import "./i18n";

interface IAllValues {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  passwordConfirmation?: string;
}

export function composeValidators(
  ...validators: FieldValidator<string>[]
): FieldValidator<string> | undefined {
  return (value: string, allValues: IAllValues) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues),
      undefined
    );
}

export function requiredValidation(value: string): string | undefined {
  return value ? undefined : i18next.t("VALIDATIONS:REQUIRED_FIELD");
}

export function emailValidation(value: string): string | undefined {
  return !!value && !EMAIL_REGEX.test(value)
    ? i18next.t("VALIDATIONS:INVALID_FIELD", {
        field: i18next.t("FIELDS:EMAIL").toLocaleLowerCase(),
      })
    : undefined;
}

export function passwordValidation(value: string): string | undefined {
  if (!!value && value.length < PASSWORD_MINIMUM_LENGTH) {
    return i18next.t("VALIDATIONS:INVALID_FIELD_LENGTH", {
      field: i18next.t("FIELDS:PASSWORD"),
      length: PASSWORD_MINIMUM_LENGTH,
    });
  }

  return !PASSWORD_REGEX.test(value)
    ? i18next.t("VALIDATIONS:INVALID_FIELD_SPECIAL_CHARACTERS", {
        field: i18next.t("FIELDS:PASSWORD"),
      })
    : undefined;
}

export function passwordConfirmationValidation(
  value: string,
  allValues: IAllValues
): string | undefined {
  if (value !== allValues.password) {
    return i18next.t("VALIDATIONS:FIELD_NOT_MATCH", {
      field: i18next.t("FIELDS:PASSWORD"),
    });
  }
  return passwordValidation(value);
}

export function dateValidation(value: string): string | undefined {
  return !!value && new Date(value) >= new Date()
    ? i18next.t("VALIDATIONS:INVALID_FIELD", {
        field: i18next.t("FIELDS:DATE").toLocaleLowerCase(),
      })
    : undefined;
}

export function phoneNumberValidation(value: string): string | undefined {
  return !!value && !PHONE_NUMBER_REGEX.test(value)
    ? i18next.t("VALIDATIONS:INVALID_FIELD", {
        field: i18next.t("FIELDS:PHONE_NUMBER").toLocaleLowerCase(),
      })
    : undefined;
}

export function nameValidation(value: string): string | undefined {
  return !!value && !COMPLETE_NAME_REGEX.test(value)
    ? i18next.t("VALIDATIONS:INVALID_NAME")
    : undefined;
}
