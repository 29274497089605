import { PUBLIC_URL } from "configs/configs";

const GREEN_TICK = `${PUBLIC_URL}/message-modals/icon-green.svg`;

const GREEN_CLOSE = `${PUBLIC_URL}/message-modals/close-green.svg`;

const RED_TICK = `${PUBLIC_URL}/message-modals/icon-red.svg`;

const RED_CLOSE = `${PUBLIC_URL}/message-modals/close-red.svg`;

const YELLOW_TICK = `${PUBLIC_URL}/message-modals/icon-yellow.svg`;

const YELLOW_CLOSE = `${PUBLIC_URL}/message-modals/close-yellow.svg`;

const BLUE_TICK = `${PUBLIC_URL}/message-modals/icon-blue.svg`;

const BLUE_CLOSE = `${PUBLIC_URL}/message-modals/close-blue.svg`;

const GREEN_COLOR = "#52D365";

const RED_COLOR = "#FF7572";

const YELLOW_COLOR = "#FEDA40";

const BLUE_COLOR = "#8FC6E6";

export const MESSAGE = {
  success: {
    icon: GREEN_TICK,
    close: GREEN_CLOSE,
    title: "Success!",
    bgColor: GREEN_COLOR,
  },
  error: {
    icon: RED_TICK,
    close: RED_CLOSE,
    title: "Error",
    bgColor: RED_COLOR,
  },
  warning: {
    icon: YELLOW_TICK,
    close: YELLOW_CLOSE,
    title: "Warning!",
    bgColor: YELLOW_COLOR,
  },
  info: {
    icon: BLUE_TICK,
    close: BLUE_CLOSE,
    title: "Info!",
    bgColor: BLUE_COLOR,
  },
};
