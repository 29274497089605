/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { useDispatch } from "react-redux";
import { onSubmitDownloadTC } from "redux/actions/asset.actions";
import TermsAndConditionsDownloadView from "views/TermsAndConditionsDownloadView/TermsAndConditionsDownloadView";

const TermsAndConditionsDownload = () => {
  const dispatch = useDispatch();

  // const uuid = "16889edd-e0d9-456c-a461-14fcfcdabd8d";
  const onSubmitDownloadButton = (downloadText: string) => {
    dispatch(onSubmitDownloadTC(downloadText));
  };
  // const fileSuccessSelector = useSelector(getPublicAssetSuccessSelector());

  return <TermsAndConditionsDownloadView onSubmit={onSubmitDownloadButton} />;
};

export default TermsAndConditionsDownload;
