import {
  Step,
  StepLabel,
  Stepper,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { IProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

function getSteps() {
  return ["Upload Template images variables", "Upload Jsons"];
}

const CustomizedSteppers: FunctionComponent<IProps> = (props: IProps) => {
  const classes = useStyles();
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={props.activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomizedSteppers;
