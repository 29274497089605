import { IMetricData } from "common/interfaces/metric.interface";
import { AnyAction } from "redux";
import { takeLatest, call, all, put, select } from "redux-saga/effects";
import { getMetricId } from "redux/selectors/metric.selector";
import {
  createMetric,
  getMetricsData,
  updateAudioClicked,
  updateCTAClicked,
  updateWatchedTime,
} from "service/metric.service";
import * as metricsActions from "../actions/metric.actions";
import * as metricConstants from "../constants/metric.constants";

function* getMetrics(action: AnyAction) {
  try {
    const response: IMetricData = yield call(getMetricsData, action.campaignId);
    yield put(metricsActions.onInitializeMetricDashboardSucceed(response));
  } catch (err) {
    yield put(metricsActions.onInitializeMetricDashboardFailed());
  }
}

function* onCreateMetric(action: AnyAction) {
  try {
    const response: string = yield call(createMetric, action.shareCampaignId);
    yield put(metricsActions.onCreateMetricSucceed(response));
  } catch (err) {
    yield put(metricsActions.onCreateMetricFailed());
  }
}

function* onUpdateWatchedTime(action: AnyAction) {
  try {
    const id: string = yield select(getMetricId());
    if (id) {
      yield call(updateWatchedTime, id, action.lastSecondPlayed);
      yield put(metricsActions.onUpdateWatchedTimeSucceed());
    }
  } catch (err) {
    yield put(metricsActions.onUpdateWatchedTimeFailed());
  }
}

function* onUpdateAudioClicked() {
  try {
    const id: string = yield select(getMetricId());
    if (id) {
      yield call(updateAudioClicked, id);
      yield put(metricsActions.onUpdateAudioClickedSucceed());
    }
  } catch (err) {
    yield put(metricsActions.onUpdateAudioClickedFailed());
  }
}

function* onUpdateCTAClicked() {
  try {
    const id: string = yield select(getMetricId());
    if (id) {
      yield call(updateCTAClicked, id);
      yield put(metricsActions.onUpdateCTAClickedSucceed());
    }
  } catch (err) {
    yield put(metricsActions.onUpdateCTAClickedFailed());
  }
}

export default all([
  takeLatest(
    metricConstants.ON_INITIALIZE_METRICS_DASHBOARD_REQUESTED,
    getMetrics
  ),
  takeLatest(metricConstants.ON_CREATE_METRIC_REQUESTED, onCreateMetric),
  takeLatest(
    metricConstants.ON_UPDATE_WATCHED_TIME_REQUESTED,
    onUpdateWatchedTime
  ),
  takeLatest(
    metricConstants.ON_UPDATE_AUDIO_CLICKED_REQUESTED,
    onUpdateAudioClicked
  ),
  takeLatest(
    metricConstants.ON_UPDATE_CTA_CLICKED_REQUESTED,
    onUpdateCTAClicked
  ),
]);
