import { IUserState } from "common/interfaces/user.interface";
import { IStore } from "redux/reducers/types";
import { createSelector } from "reselect";

const getUser = (state: IStore) => {
  return state.user;
};

export const isUserLogged = (state: IStore): IUserState => {
  const user = getUser(state);
  return user;
};

export const isUserLoggedSelector = createSelector([getUser], (user) => {
  return user.data;
});
