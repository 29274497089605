import { IVariable } from "common/interfaces/share.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "redux/actions/share.actions";
import getShareSelector from "redux/selectors/share.selector";
import EditShareCampaignVariablesView from "views/editShareCampaignVariables/editShareCampaignVariables";

const EditShareCampaignVariables: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getShareData = useSelector(getShareSelector());
  const { id }: { id: string } = useParams();
  const [textVariables, setTextVariables] = useState<ITextVariable[]>([]);

  useEffect(() => {
    dispatch(actions.onInitializeEditVariables(id));
  }, [dispatch, id]);

  const editShareCampaignVariables = (values: ITextVariable[]) => {
    const variableData: IVariable = { text: values, image: [] };
    dispatch(actions.onEditShareCampaign(variableData, id));
  };

  useEffect(() => {
    if ((getShareData.share.variable as ITextVariable[]).length) {
      setTextVariables(getShareData.share.variable as ITextVariable[]);
    } else if ((getShareData.share.variable as IVariable).text) {
      setTextVariables((getShareData.share.variable as IVariable).text);
    }
  }, [getShareData.share.variable]);

  return (
    <DashboardLayout>
      <EditShareCampaignVariablesView
        textVariables={textVariables}
        editVariables={editShareCampaignVariables}
      />
    </DashboardLayout>
  );
};

export default EditShareCampaignVariables;
