import { INearpodCharacterCampaignReducerState } from "common/interfaces/nearpod-character-campaign.interface";
import { AnyAction } from "redux";
import {
  ON_EXPORT_SUCCEED,
  ON_SUBMIT_CROP_ASSET_REQUESTED,
  ON_SUBMIT_CROP_ASSET_SUCCEED,
} from "redux/constants/asset.constants";
import {
  ON_CREATE_AND_EXPORT_REQUESTED,
  ON_CREATE_AND_EXPORT_SUCCEED,
  ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_SUCCEED,
  ON_SAVE_NEARPOD_CHARACTER_CAMPAIGN_DATA,
} from "redux/constants/nearpod-character-campaign.constants";

const defaultState: INearpodCharacterCampaignReducerState = {
  campaign: {
    _id: "",
    name: "",
    email: "",
    country: "",
    state: "",
    institution: "",
    badgeAssetPath: "",
    avatarAssetPath: "",
    character: 0,
    color: 1,
    cap: 0,
    shirt: 0,
    short: 0,
  },
  faceAsset: "",
  loading: false,
};

const saveCampaign = (
  state: INearpodCharacterCampaignReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    campaign: {
      ...defaultState.campaign,
      name: action.data.name,
      email: action.data.email,
      country: action.data.country,
      state: action.data.state,
      institution: action.data.institution,
    },
  };
};

const addCampaign = (
  state: INearpodCharacterCampaignReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    campaign: action.data,
    loading: false,
  };
};

const addFaceAssetPath = (
  state: INearpodCharacterCampaignReducerState,
  action: AnyAction
) => {
  return {
    ...state,
    faceAsset: action.assetPath,
    loading: false,
  };
};

const removeExportAssets = (state: INearpodCharacterCampaignReducerState) => {
  return {
    ...state,
    campaign: {
      ...state.campaign,
      badgeAssetPath: "",
      avatarAssetPath: "",
    },
    loading: true,
  };
};

const setLoading = (state: INearpodCharacterCampaignReducerState) => {
  return {
    ...state,
    loading: true,
  };
};

const nearpodCharacterCampaignReducer = (
  state: INearpodCharacterCampaignReducerState = defaultState,
  action: AnyAction
): INearpodCharacterCampaignReducerState => {
  switch (action.type) {
    case ON_SAVE_NEARPOD_CHARACTER_CAMPAIGN_DATA:
      return saveCampaign(state, action);
    case ON_CREATE_NEARPOD_CHARACTER_CAMPAIGN_SUCCEED:
    case ON_EXPORT_SUCCEED:
    case ON_CREATE_AND_EXPORT_SUCCEED:
      return addCampaign(state, action);
    case ON_SUBMIT_CROP_ASSET_REQUESTED:
      return setLoading(state);
    case ON_SUBMIT_CROP_ASSET_SUCCEED:
      return addFaceAssetPath(state, action);
    case ON_CREATE_AND_EXPORT_REQUESTED:
      return removeExportAssets(state);
    default:
      return state;
  }
};

export default nearpodCharacterCampaignReducer;
