import { InputLabel, TextField } from "@material-ui/core";
import { ACCEPT_TYPES } from "common/constants/fileInputAcceptTypes.contants";
import Button from "components/Button/Button";
import FileInput from "components/FileInput/FileInput";
import i18next from "i18next";
import React, { FunctionComponent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./i18n";
import { BlockPicker, ColorResult } from "react-color";
import { Template } from "common/interfaces/template.interface";
import { COLORS, PICKER_COLORS } from "common/constants/color.contants";
import {
  Container,
  InputContainer,
  InputColorContainer,
  ResetContainer,
  FileInputContainer,
  TextContainer,
  ErrorText,
  Message,
  List,
} from "./styles";
import { IFile, ILayerNames, ILayersFiles, IProps } from "./types";

const layers: ILayerNames[] = [
  {
    name: i18next.t("LAYER_CONSTANT:TEXT_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:TEXT_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:TEXT_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:IMAGES_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:IMAGES_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:IMAGES_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:MISC_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:MISC_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:MISC_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:INTERACTIONS_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:INTERACTIONS_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:INTERACTIONS_FILE_NAME"),
  },
  {
    name: i18next.t("LAYER_CONSTANT:OBJECTS_LAYER"),
    buttonText: i18next.t("LAYER_CONSTANT:OBJECTS_BUTTON"),
    fileName: i18next.t("LAYER_CONSTANT:OBJECTS_FILE_NAME"),
  },
];

const UploadJsonsForm: FunctionComponent<IProps> = (props: IProps) => {
  const [inputFiles, setInputFiles] = useState<IFile[]>([]);
  const [assetAudio, setAssetAudio] = useState<File[]>([]);
  const [videoAsset, setVideoAsset] = useState<File[]>([]);
  const [audioName, setAudioName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [backgroundColor, setBackgroundColor] = useState<string>(COLORS.white);
  const [id] = useState(uuidv4());

  const onHandleInputsChange = (fileList: FileList) => {
    const files = Array.from(fileList);
    layers.forEach((layerName: ILayerNames) => {
      const file = files.find(
        (f: File) => f.name.toLowerCase() === layerName.fileName.toLowerCase()
      );
      if (file) {
        setInputFiles((prevState) => [
          ...prevState,
          { name: layerName.name, file },
        ]);
      }
    });
  };

  const onChangeAudio = (files: FileList) => {
    const file = Array.from(files);
    setAssetAudio(file);
  };

  const onChangeVideo = (files: FileList) => {
    const file = Array.from(files);
    setVideoAsset(file);
  };

  const onChangeAudioName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAudioName(event.target.value);
  };

  const onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(event.target.valueAsNumber);
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleResetFiles = () => {
    setInputFiles([]);
  };

  const handleResetAudioFile = () => {
    setAssetAudio([]);
  };

  const handleResetVideoFile = () => {
    setVideoAsset([]);
  };

  const jsonValidations = () => {
    if (
      !props.withImages &&
      inputFiles.some(
        (file: IFile) => file.name === i18next.t("LAYER_CONSTANT:IMAGES_LAYER")
      )
    ) {
      return i18next.t("UPLOAD_JSONS_FORM:UPLOADED_IMAGES_ERROR");
    }
    if (
      props.withImages &&
      !inputFiles.some(
        (file: IFile) => file.name === i18next.t("LAYER_CONSTANT:IMAGES_LAYER")
      )
    ) {
      return i18next.t("UPLOAD_JSONS_FORM:NO_IMAGES_ERROR");
    }
    return "";
  };

  const handleUpload = () => {
    const files: ILayersFiles = {
      assetText:
        inputFiles.find(
          (inputFile: IFile) =>
            inputFile.name === i18next.t("LAYER_CONSTANT:TEXT_LAYER")
        )?.file || null,
      assetImage:
        inputFiles.find(
          (inputFile: IFile) =>
            inputFile.name === i18next.t("LAYER_CONSTANT:IMAGES_LAYER")
        )?.file || null,
      assetObject:
        inputFiles.find(
          (inputFile: IFile) =>
            inputFile.name === i18next.t("LAYER_CONSTANT:OBJECTS_LAYER")
        )?.file || null,
      assetMiscellaneus:
        inputFiles.find(
          (inputFile: IFile) =>
            inputFile.name === i18next.t("LAYER_CONSTANT:MISC_LAYER")
        )?.file || null,
      assetInteractions:
        inputFiles.find(
          (inputFile: IFile) =>
            inputFile.name === i18next.t("LAYER_CONSTANT:INTERACTIONS_LAYER")
        )?.file || null,
    };
    const audio = {
      assetAudio,
      name: audioName,
    };
    const template: Template = {
      title,
      duration,
      description,
      backgroundColor,
      _id: "",
      createdAt: "",
      updatedAt: "",
    };
    props.onUploadFiles(files, audio, videoAsset, template);
  };

  const handleColorChange = (color: ColorResult) => {
    setBackgroundColor(color.hex);
  };

  return (
    <Container>
      <FileInputContainer>
        <InputContainer key={`title_${id}`}>
          <InputLabel>{i18next.t("UPLOAD_JSONS_FORM:TITLE")}</InputLabel>
          <TextField
            inputProps={{
              maxlength: 255,
            }}
            value={title}
            onChange={onChangeTitle}
            variant="outlined"
          />
        </InputContainer>
        <InputContainer key={`duration_${id}`}>
          <InputLabel>{i18next.t("UPLOAD_JSONS_FORM:DURATION")}</InputLabel>
          <TextField
            variant="outlined"
            type="number"
            value={duration}
            onChange={onChangeDuration}
          />
        </InputContainer>
        <InputContainer key={`description_${id}`}>
          <InputLabel>{i18next.t("UPLOAD_JSONS_FORM:DESCRIPTION")}</InputLabel>
          <TextField
            inputProps={{
              maxlength: 255,
            }}
            value={description}
            onChange={onChangeDescription}
            variant="outlined"
          />
        </InputContainer>
        <InputColorContainer key={`color_${id}`}>
          <InputLabel>
            {i18next.t("UPLOAD_JSONS_FORM:BACKGROUND_COLOR")}
          </InputLabel>
          <BlockPicker
            triangle="hide"
            color={backgroundColor}
            onChangeComplete={handleColorChange}
            colors={PICKER_COLORS}
          />
        </InputColorContainer>
      </FileInputContainer>
      <List>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:TEXT_LAYER"),
            file: i18next.t("LAYER_CONSTANT:TEXT_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:IMAGES_LAYER"),
            file: i18next.t("LAYER_CONSTANT:IMAGES_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:MISC_LAYER"),
            file: i18next.t("LAYER_CONSTANT:MISC_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:INTERACTIONS_LAYER"),
            file: i18next.t("LAYER_CONSTANT:INTERACTIONS_FILE_NAME"),
          })}
        </Message>
        <Message>
          {i18next.t("UPLOAD_JSONS_FORM:JSON_FILES_MESSAGE", {
            layer: i18next.t("LAYER_CONSTANT:OBJECTS_LAYER"),
            file: i18next.t("LAYER_CONSTANT:OBJECTS_FILE_NAME"),
          })}
        </Message>
      </List>
      <InputContainer key={`jsons_${id}`}>
        {!inputFiles.length ? (
          <FileInput
            multiple
            accept={ACCEPT_TYPES.jsons}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:UPLOAD_JSON_FILES")}
            onChange={(files) => onHandleInputsChange(files)}
          />
        ) : (
          <>
            <TextContainer>
              {i18next.t("UPLOAD_JSONS_FORM:SELECTED_JSONS") +
                inputFiles.map((inputFile) => inputFile.name).join(" - ")}
              {jsonValidations() && <ErrorText>{jsonValidations()}</ErrorText>}
            </TextContainer>
            <ResetContainer>
              <Button onClick={handleResetFiles}>
                {i18next.t("UPLOAD_JSONS_FORM:RESET_JSON_FILES")}
              </Button>
            </ResetContainer>
          </>
        )}
      </InputContainer>
      <InputContainer key={`audio_${id}`}>
        {assetAudio.length ? (
          <ResetContainer>
            <Button onClick={handleResetAudioFile}>
              {i18next.t("UPLOAD_JSONS_FORM:RESET_FILE", {
                layerName: i18next.t("UPLOAD_JSONS_FORM:AUDIO"),
              })}
            </Button>
          </ResetContainer>
        ) : (
          <FileInput
            accept={ACCEPT_TYPES.audio}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:AUDIO")}
            onChange={(event) => onChangeAudio(event)}
          />
        )}
      </InputContainer>
      <InputContainer key={`audioName_${id}`}>
        <InputLabel>{i18next.t("UPLOAD_JSONS_FORM:AUDIO_NAME")}</InputLabel>
        <TextField
          value={audioName}
          onChange={onChangeAudioName}
          variant="outlined"
        />
      </InputContainer>
      <InputContainer key={`video_${id}`}>
        {videoAsset.length ? (
          <ResetContainer>
            <Button onClick={handleResetVideoFile}>
              {i18next.t("UPLOAD_JSONS_FORM:RESET_FILE", {
                layerName: i18next.t("UPLOAD_JSONS_FORM:VIDEO"),
              })}
            </Button>
          </ResetContainer>
        ) : (
          <FileInput
            accept={ACCEPT_TYPES.video}
            buttonText={i18next.t("UPLOAD_JSONS_FORM:VIDEO")}
            onChange={(event) => onChangeVideo(event)}
          />
        )}
      </InputContainer>
      {!!inputFiles.length &&
        !jsonValidations() &&
        inputFiles.some(
          (file: IFile) => file.name === i18next.t("LAYER_CONSTANT:TEXT_LAYER")
        ) &&
        title &&
        description && (
          <Button onClick={handleUpload}>
            {i18next.t("UPLOAD_JSONS_FORM:SUBMIT")}
          </Button>
        )}
    </Container>
  );
};

export default UploadJsonsForm;
