import { takeLatest, all, put } from "redux-saga/effects";
import { AnyAction } from "redux";
import { IAPIError } from "common/interfaces/api.interface";
import { replaceImagesJson } from "helper/replace/replaceImagesJson.helper";
import { replaceTexts } from "helper/replace/replaceTexts.helper";
import * as lottieActions from "../actions/lottie-json.actions";
import * as constants from "../constants/lottie-json.contants";

function* retrieveData(action: AnyAction) {
  try {
    const { json, parsed } = action;
    const images = replaceImagesJson(json, parsed.images);
    const texts = replaceTexts(images, parsed.texts);
    yield put(lottieActions.onInitializeLottieJsonSucceed(texts));
  } catch (err) {
    yield put(lottieActions.onInitializeLottieJsonFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(constants.LOTTIE_JSON_ON_INITIALIZE, retrieveData),
]);
