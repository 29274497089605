import { Button, Grid, Input, InputLabel } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Container } from "./styles";
import { IField, IProps } from "./types";

const EditForm: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <Container data-testid="editForm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {props.fields.map((field: IField, index: number) => {
          return (
            <Grid key={field.label} item xs={12} sm={6}>
              <InputLabel>{field.label}</InputLabel>
              {field.type === "text" && (
                <Input
                  id={field.label}
                  value={field.value}
                  data-testid="inputEdit"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.onChange(event.target.value, field.label, index)
                  }
                />
              )}
            </Grid>
          );
        })}
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={props.onSubmit}>
            {props.buttonText}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default EditForm;
