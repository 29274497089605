import Button from "components/Button/Button";
import i18next from "i18next";
import React, { FunctionComponent } from "react";
import { ButtonContainer } from "./styles";
import { IProps } from "./types";
import "./i18n";

const StartButtons: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <ButtonContainer>
      <Button onClick={props.onClickLeftButton}>
        {i18next.t("START_BUTTONS:WITH_IMAGES")}
      </Button>
      <Button onClick={props.onClickRightButton}>
        {i18next.t("START_BUTTONS:WITHOUT_IMAGES")}
      </Button>
    </ButtonContainer>
  );
};

export default StartButtons;
