import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/campaign.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import getCampaignSelector, {
  getCount,
} from "redux/selectors/campaign.selector";
import DashboardView from "views/DashboardView/DashboardView";
import i18next from "i18next";
import "./i18n";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";
import { Data } from "components/DashboardTable/types";

const CampaignDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const getCampaignData = useSelector(getCampaignSelector());
  const getCampaignCount = useSelector(getCount());
  const [actualPage, setActualPage] = useState(0);
  const successSelector = useSelector(getSuccessSelector());
  const successText = useSelector(getSuccessText());
  const [tableKeys, setTableKeys] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(actions.getCampaigns(query, actualPage));
  }, [dispatch, actualPage]);

  const selectCampaign = (campaignId: string) => {
    dispatch(actions.selectCampaignSucced(campaignId));
  };

  useEffect(() => {
    if (getCampaignData.campaigns.length) {
      const keys = Object.keys(getCampaignData.campaigns[0]);
      setTableKeys(keys);
    }
  }, [getCampaignData]);

  const onClickViewMetrics = (campaignId: string) => {
    dispatch(actions.onRedirectToMetrics(campaignId));
  };

  const onCreateMetricsData = (campaignId: string) => {
    dispatch(actions.onRedirectToMetricsForm(campaignId));
  };

  const deleteCampaign = (campaignsId: string[]) => {
    dispatch(actions.deleteCampaigns(campaignsId));
  };

  const onCreateShareCampaign = (campaignId: string) => {
    dispatch(actions.onCreateShareCampaigns(campaignId));
  };

  const onSelectAddCampaign = () => {
    dispatch(actions.onSelectCampaignAdd());
  };

  const search = (query: string) => {
    dispatch(actions.getCampaigns(query, actualPage));
  };

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  return (
    <DashboardLayout>
      {successSelector &&
        (successText === SUCCESS_TEXT.created ? (
          <SuccessAlert message={i18next.t("CAMPAIGN:CREATED")} />
        ) : (
          <SuccessAlert message={i18next.t("CAMPAIGN:DELETED")} />
        ))}
      <DashboardView
        search={search}
        data={getCampaignData.campaigns as Data[]}
        keys={tableKeys}
        selectCampaign={selectCampaign}
        title="Campaigns"
        onDelete={deleteCampaign}
        isCampaign
        onClickAdd={onSelectAddCampaign}
        onClickViewMetrics={onClickViewMetrics}
        onCreateMetricsData={onCreateMetricsData}
        createShareCampaign={onCreateShareCampaign}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getCampaignCount}
      />
    </DashboardLayout>
  );
};
export default CampaignDashboardContainer;
