import { IAPIError } from "common/interfaces/api.interface";
import { IClient } from "common/interfaces/client.interface";
import { AnyAction } from "redux";
import * as constants from "../constants/client.constants";

export const getClients = (query: string, page: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CLIENTS_REQUESTED,
  query,
  page,
});

export const getClientsSucceed = (data: IClient[]): AnyAction => ({
  type: constants.ON_RETRIEVE_CLIENTS_SUCCEED,
  data,
});

export const getClientsFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_CLIENTS_FAILED,
  error,
});

export const getClientCountSucceed = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CLIENT_COUNT_SUCCEED,
  count,
});

export const getClientCountFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_RETRIEVE_CLIENT_COUNT_FAILED,
  error,
});

export const getClientCount = (count: number): AnyAction => ({
  type: constants.ON_RETRIEVE_CLIENT_REQUESTED,
  count,
});

export const onSelectClientAdd = (): AnyAction => ({
  type: constants.ON_SELECT_CLIENT_ADD,
});

export const onSubmitClientCreate = (name: string): AnyAction => ({
  type: constants.ON_SUBMIT_CLIENT_CREATE_REQUESTED,
  name,
});

export const onSubmitClientCreateSuccess = (data: IClient): AnyAction => ({
  type: constants.ON_SUBMIT_CLIENT_CREATE_SUCCESS,
  data,
});

export const onSubmitClientCreateFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_SUBMIT_CLIENT_CREATE_FAILED,
  error,
});

export const onDeleteClient = (clientIds: string[]): AnyAction => ({
  type: constants.ON_DELETE_CLIENT_REQUESTED,
  clientIds,
});

export const onDeleteClientSuccess = (data: IClient[]): AnyAction => ({
  type: constants.ON_DELETE_CLIENT_SUCCESS,
  data,
});

export const onDeleteClientFailed = (error: IAPIError): AnyAction => ({
  type: constants.ON_DELETE_CLIENT_FAILED,
  error,
});
