import { TEXT_VARIABLES_PROSPECTS_EOY_PSH_2022 } from "./textsProspects";

export const createJsonDataProspects = (
  projectName: string,
  id: string
): string => `
{"images": [{"name": "img_0", "asset": "${
  TEXT_VARIABLES_PROSPECTS_EOY_PSH_2022[`${projectName}_${id}`].photoRemitent
}"}],"texts": [{"_id": "1","name": "#s01_text01_c01","type": 1,"templateId": "1","ctaLink": "","value": "${
  TEXT_VARIABLES_PROSPECTS_EOY_PSH_2022[`${projectName}_${id}`].person
}"}, {"_id": "1","name": "#s06_text02_c01","type": 1,"templateId": "1","ctaLink": "","value": "${
  TEXT_VARIABLES_PROSPECTS_EOY_PSH_2022[`${projectName}_${id}`].remitent
}"}, {"_id": "1","name": "#s06_text03_c01","type": 1,"templateId": "1","ctaLink": "","value": "${
  TEXT_VARIABLES_PROSPECTS_EOY_PSH_2022[`${projectName}_${id}`].email
}"}]}`;
