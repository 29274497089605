const getTimeStamp = (): string => {
  const dt = new Date();
  const year = dt.getUTCFullYear();
  const month = `0${dt.getUTCMonth() + 1}`.slice(-2);
  const day = `0${dt.getUTCDate()}`.slice(-2);
  const hour = `0${dt.getUTCHours()}`.slice(-2);
  const min = `0${dt.getUTCMinutes()}`.slice(-2);
  const sec = `0${dt.getUTCSeconds()}`.slice(-2);
  return `${year}_${month}_${day}_${hour}_${min}_${sec}`;
};

export default getTimeStamp;
