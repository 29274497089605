import { AxiosResponse } from "axios";
import { IVariable } from "common/interfaces/share.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { get, post, put } from "./api";

export const getShareData = async (shareId: string): Promise<AxiosResponse> => {
  const response = await get(`share/${shareId}`);
  return response.data;
};

export const createShareCampaignService = async (
  campaignId: string,
  headersMatch: string[],
  csvHeaders: string[],
  csvData: string[]
): Promise<AxiosResponse> => {
  const data = {
    campaignId,
    headersMatch,
    csvHeaders,
    csvData,
  };
  const response = await post(`share/`, data);
  return response.data;
};

export const getShareCampaignsByCampaignId = async (
  query: string,
  campaignId: string,
  page: number
): Promise<AxiosResponse> => {
  let response;
  if (query === "") {
    response = await get(`share/campaign/${campaignId}/${page}`);
  } else {
    response = await get(`share/campaign/${campaignId}/${page}/${query}`);
  }

  return response.data;
};

export const deleteShareCampaign = async (
  shareCampaignId: string
): Promise<AxiosResponse> => {
  const data = {
    shareCampaignId,
  };
  const response = await put(`share/delete/`, data);
  return response.data;
};

export const editShareCampaign = async (
  values: IVariable,
  shareCampaignId: string
): Promise<AxiosResponse> => {
  const data = {
    values,
    shareCampaignId,
  };
  const response = await put(`share/edit/`, data);
  return response.data;
};

export const getShareCampaignVariables = async (
  shareCampaignId: string
): Promise<AxiosResponse> => {
  const response = await get(`share/${shareCampaignId}`);
  return response.data;
};

export const addShareCampaignService = async (
  values: ITextVariable[],
  campaignId: string
): Promise<AxiosResponse> => {
  const data = {
    campaignId,
    values,
  };
  const response = await post(`share/create-manually`, data);
  return response.data;
};

export const getCount = async (campaignId: string): Promise<AxiosResponse> => {
  const response = await get(`share/count/campaign/${campaignId}`);
  return response.data;
};

export const createShareCampaignSpartan = async (
  email: string,
  name: string,
  friendName?: string
): Promise<AxiosResponse> => {
  const data = {
    email,
    name,
    friendName,
  };
  const response = await post(`share/referral-campaign`, data);
  return response.data;
};
