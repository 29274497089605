import styled from "styled-components";
import { ICharacter } from "./types";

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const FaceImage = styled.img`
  position: absolute;
  left: 30%;
  width: 40%;
  top: 8%;
`;

export const ImageMask = styled.img`
  position: absolute;
  width: 100%;
  top: 0%;
`;

export const ImageCap = styled.img<ICharacter>`
  position: absolute;
  ${(props) =>
    props.character === 0 &&
    ` width: 75%;
      top: 1%;
      left: 13%;
    `}
  ${(props) =>
    props.character === 1 &&
    ` width: 48%;
      top: 2%;
      left: 26%;
    `}
  ${(props) =>
    props.character === 2 &&
    `  width: 50%;
      top: 1%;
      left: 25%;
    `}
  ${(props) =>
    props.character === 3 &&
    ` width: 46%;
      top: 1%;
      left: 28%;
    `}
`;

export const ImageBody = styled.img`
  position: absolute;
  width: 100%;
  top: 0%;
`;

export const ImageTShirt = styled.img<ICharacter>`
  position: absolute;

  ${(props) =>
    props.character === 0 &&
    ` 
    width: 46%;
    top: 57%;
    left: 26.5%;
    `}
  ${(props) =>
    props.character === 1 &&
    ` width: 37%;
      top: 58%;
      left: 32%;
    `}
  ${(props) =>
    props.character === 2 &&
    ` width: 50%;
      top: 56%;
      left: 25%;
    `}
  ${(props) =>
    props.character === 3 &&
    ` width: 45%;
      top: 59%;
      left: 28%;
    `}
`;

export const ImageBottomAvatar = styled.img`
  position: absolute;
  top: 0%;
  width: 100%;
`;
