import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  width: 196px;
  margin-left: 30px;
`;
