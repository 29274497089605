import {
  IShareImageVariable,
  IVariable,
} from "common/interfaces/share.interface";
import { ITextVariable } from "common/interfaces/text-variable.interface";
import { IShareReducerState } from "redux/reducers/share.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";
import { ICSVRow } from "views/importCsvView/types";

const getShare = (state: IStore) => state.share;
const getShareSelector = (): OutputSelector<
  IStore,
  IShareReducerState,
  (res: IShareReducerState) => IShareReducerState
> => createSelector([getShare], (share) => share);

export const getCount = (): OutputSelector<
  IStore,
  number,
  (res: IShareReducerState) => number
> => createSelector([getShare], (share) => share.count);

export const isLoading = (): OutputSelector<
  IStore,
  boolean,
  (res: IShareReducerState) => boolean
> => createSelector([getShare], (share) => share.loading);

export const getRequestCount = (): OutputSelector<
  IStore,
  number,
  (res: IShareReducerState) => number
> => createSelector([getShare], (share) => share.requestCount);

export const getExportCsvData = (): OutputSelector<
  IStore,
  ICSVRow[],
  (res: IShareReducerState) => ICSVRow[]
> => createSelector([getShare], (share) => share.exportCsvData);

export const getCsvHeaders = (): OutputSelector<
  IStore,
  string[],
  (res: IShareReducerState) => string[]
> => createSelector([getShare], (share) => share.headers);

export const getShareTextVariables = (): OutputSelector<
  IStore,
  ITextVariable[],
  (res: IShareReducerState) => ITextVariable[]
> =>
  createSelector([getShare], (share) => {
    if (share.share && (share.share.variable as ITextVariable[]).length) {
      return share.share.variable as ITextVariable[];
    }
    if (share.share && (share.share.variable as IVariable).text) {
      return (share.share.variable as IVariable).text;
    }
    return [];
  });

export const getShareImageVariables = (): OutputSelector<
  IStore,
  IShareImageVariable[],
  (res: IShareReducerState) => IShareImageVariable[]
> =>
  createSelector([getShare], (share) => {
    return (
      share.share &&
      (share.share.variable as IVariable).image &&
      (share.share.variable as IVariable).image
    );
  });
export default getShareSelector;
