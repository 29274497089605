export const ON_INITIALIZE_PLAYER_REQUESTED = "ON_INITIALIZE_PLAYER_REQUESTED";
export const ON_INITIALIZE_PLAYER_SUCCEED = "ON_INITIALIZE_PLAYER_SUCCEED";
export const ON_INITIALIZE_PLAYER_FAILED = "ON_INITIALIZE_PLAYER_FAILED";
export const ON_INITIALIZE_MATCH_CSV_REQUESTED =
  "ON_INITIALIZE_MATCH_CSV_REQUESTED";
export const ON_INITIALIZE_MATCH_CSV_SUCCEED =
  "ON_INITIALIZE_MATCH_CSV_SUCCEED";
export const ON_INITIALIZE_MATCH_CSV_FAILED = "ON_INITIALIZE_MATCH_CSV_FAILED";
export const ON_CREATE_SHARE_CAMPAIGN_REQUESTED =
  "ON_CREATE_SHARE_CAMPAIGN_REQUESTED";
export const ON_CREATE_SHARE_CAMPAIGN_SUCCEED =
  "ON_CREATE_SHARE_CAMPAIGN_SUCCEED";
export const ON_CREATE_SHARE_CAMPAIGN_FAILED =
  "ON_CREATE_SHARE_CAMPAIGN_FAILED";
export const ON_RETRIEVE_SHARE_CAMPAIGNS_REQUESTED =
  "ON_RETRIEVE_SHARE_CAMPAIGNS_REQUESTED";
export const ON_RETRIEVE_SHARE_CAMPAIGNS_SUCCEED =
  "ON_RETRIEVE_SHARE_CAMPAIGNS_SUCCEED";
export const ON_RETRIEVE_SHARE_CAMPAIGNS_FAILED =
  "ON_RETRIEVE_SHARE_CAMPAIGNS_FAILED";
export const ON_DELETE_SHARE_CAMPAIGN_REQUESTED =
  "ON_DELETE_SHARE_CAMPAIGN_REQUESTED";
export const ON_DELETE_SHARE_CAMPAIGN_SUCCEED =
  "ON_DELETE_SHARE_CAMPAIGN_SUCCEED";
export const ON_DELETE_SHARE_CAMPAIGN_FAILED =
  "ON_DELETE_SHARE_CAMPAIGN_FAILED";
export const ON_EDIT_SHARE_CAMPAIGN_REQUESTED =
  "ON_EDIT_SHARE_CAMPAIGN_REQUESTED";
export const ON_EDIT_SHARE_CAMPAIGN_SUCCEED = "ON_EDIT_SHARE_CAMPAIGN_SUCCEED";
export const ON_EDIT_SHARE_CAMPAIGN_FAILED = "ON_EDIT_SHARE_CAMPAIGN_FAILED";
export const ON_INITIALIZE_EDIT_VIEW_REQUESTED =
  "ON_INITIALIZE_EDIT_VIEW_REQUESTED";
export const ON_INITIALIZE_EDIT_VIEW_SUCCEED =
  "ON_INITIALIZE_EDIT_VIEW_SUCCEED";
export const ON_INITIALIZE_EDIT_VIEW_FAILED = "ON_INITIALIZE_EDIT_VIEW_FAILED";
export const ON_SELECT_SHARE_CAMPAIGN_EDIT = "ON_SELECT_SHARE_CAMPAIGN_EDIT";
export const ON_INITIALIZE_ADD_VIEW_REQUESTED =
  "ON_INITIALIZE_ADD_VIEW_REQUESTED";
export const ON_SELECT_SHARE_CAMPAIGN_ADD = "ON_SELECT_SHARE_CAMPAIGN_ADD";
export const ON_INITIALIZE_ADD_VIEW_SUCCEED = "ON_INITIALIZE_ADD_VIEW_SUCCEED";
export const ON_INITIALIZE_ADD_VIEW_FAILED = "ON_INITIALIZE_ADD_VIEW_FAILED";
export const ON_ADD_SHARE_CAMPAIGN_REQUESTED =
  "ON_ADD_SHARE_CAMPAIGN_REQUESTED";
export const ON_ADD_SHARE_CAMPAIGN_SUCCEED = "ON_ADD_SHARE_CAMPAIGN_SUCCEED";
export const ON_ADD_SHARE_CAMPAIGN_FAILED = "ON_ADD_SHARE_CAMPAIGN_FAILED";
export const ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_SUCCEED =
  "ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_SUCCEED";
export const ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_FAILED =
  "ON_RETRIEVE_SHARE_CAMPAIGN_COUNT_FAILED";
export const ON_PARSE_ROW_CREATE_REQUEST = "ON_PARSE_ROW_CREATE_REQUEST";
export const ON_UPDATE_PARSE_ROW_COUNT = "ON_UPDATE_PARSE_ROW_COUNT";
export const ON_CREATE_EXPORT_DATA_SUCCEED = "ON_CREATE_EXPORT_DATA_SUCCEED";
export const ON_SUBMIT_FORM_SPARTAN_REQUESTED =
  "ON_SUBMIT_FORM_SPARTAN_REQUESTED";
export const ON_SUBMIT_FORM_SPARTAN_SUCCEED = "ON_SUBMIT_FORM_SPARTAN_SUCCEED";
export const ON_SUBMIT_FORM_SPARTAN_FAILED = "ON_SUBMIT_FORM_SPARTAN_FAILED";
