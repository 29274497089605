import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/actions/client.actions";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import getClientSelector, { getCount } from "redux/selectors/client.selector";
import DashboardView from "views/DashboardView/DashboardView";
import getSuccessSelector, {
  getSuccessText,
} from "redux/selectors/success.selector";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { hideSuccessNotification } from "redux/actions/success.actions";
import i18next from "i18next";
import "./i18n";
import { SUCCESS_TEXT } from "common/constants/successMesages.constants";
import { Data } from "components/DashboardTable/types";

const ClientDashboardContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [actualPage, setActualPage] = useState(0);
  const getClientData = useSelector(getClientSelector());
  const getClientCount = useSelector(getCount());
  const successSelector = useSelector(getSuccessSelector());
  const successText = useSelector(getSuccessText());
  const [tableKeys, setTableKeys] = useState<string[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let query = params.get("q");
    if (!query) {
      query = "";
    }
    dispatch(actions.getClients(query, actualPage));
  }, [dispatch, actualPage]);

  useEffect(() => {
    if (getClientData.clients.length) {
      const keys = Object.keys(getClientData.clients[0]);
      setTableKeys(keys);
    }
  }, [getClientData]);

  const onClickAdd = () => {
    dispatch(actions.onSelectClientAdd());
  };

  const onDelete = (clientIds: string[]) => {
    dispatch(actions.onDeleteClient(clientIds));
  };

  const search = (query: string) => {
    dispatch(actions.getClients(query, actualPage));
  };

  setTimeout(() => {
    if (successSelector) {
      dispatch(hideSuccessNotification());
    }
  }, 2500);

  return (
    <DashboardLayout>
      {successSelector &&
        (successText === SUCCESS_TEXT.created ? (
          <SuccessAlert message={i18next.t("CLIENT:TEXT")} />
        ) : (
          <SuccessAlert message={i18next.t("CLIENT:DELETE")} />
        ))}
      <DashboardView
        search={search}
        data={getClientData.clients as Data[]}
        keys={tableKeys}
        onDelete={onDelete}
        title="Clients"
        onClickAdd={onClickAdd}
        actualPage={actualPage}
        setPage={setActualPage}
        count={getClientCount}
      />
    </DashboardLayout>
  );
};

export default ClientDashboardContainer;
