import React, { FunctionComponent, useState } from "react";
import { PUBLIC_URL } from "configs/configs";
import { Radio } from "@material-ui/core";
import i18next from "i18next";
import "./i18n";
import {
  Button,
  Container,
  Title,
  VideoImageContainer,
  VideoPlay,
  VideosContainer,
  Wrapper,
  SubscriptionContainer,
  radioButtonStyles,
  Option,
  SubscriptionTitle,
  OptionsContainer,
  VideoWrapper,
} from "./styles";
import { IProps } from "./types";

const Step2: FunctionComponent<IProps> = (props: IProps) => {
  const [subscription, setSubscription] = useState<string>("yes");
  const classes = radioButtonStyles();

  const onClickDone = () => {
    props.onClickAccept(subscription === "yes");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscription(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: subscription === item,
    onChange: handleChange,
    value: item,
  });

  return (
    <Container>
      <Wrapper>
        <Title>{i18next.t("STEP_2:TITLE")}</Title>
        <VideosContainer>
          <VideoWrapper
            href="https://www.youtube.com/watch?v=WLwxmQG63JM"
            target="_blank"
            rel="noreferrer"
          >
            <VideoImageContainer image={`${PUBLIC_URL}/wellnet/video_01.jpg`}>
              <VideoPlay />
            </VideoImageContainer>
          </VideoWrapper>
          <VideoWrapper
            href="https://www.youtube.com/watch?v=yaysBJx_sUE"
            target="_blank"
            rel="noreferrer"
          >
            <VideoImageContainer image={`${PUBLIC_URL}/wellnet/video_02.jpg`}>
              <VideoPlay />
            </VideoImageContainer>
          </VideoWrapper>
          <VideoWrapper
            href="https://www.youtube.com/watch?v=_NkejlZryro"
            target="_blank"
            rel="noreferrer"
          >
            <VideoImageContainer image={`${PUBLIC_URL}/wellnet/video_03.jpg`}>
              <VideoPlay />
            </VideoImageContainer>
          </VideoWrapper>
          <VideoWrapper
            href="https://www.youtube.com/watch?v=n24zllI1evU"
            target="_blank"
            rel="noreferrer"
          >
            <VideoImageContainer image={`${PUBLIC_URL}/wellnet/video_04.jpg`}>
              <VideoPlay />
            </VideoImageContainer>
          </VideoWrapper>
          <VideoWrapper
            href="https://www.youtube.com/watch?v=5_RzmqpMCbY"
            target="_blank"
            rel="noreferrer"
          >
            <VideoImageContainer image={`${PUBLIC_URL}/wellnet/video_05.jpg`}>
              <VideoPlay />
            </VideoImageContainer>
          </VideoWrapper>
          <VideoWrapper
            href="https://www.youtube.com/watch?v=EOrOSdXG8Yg"
            target="_blank"
            rel="noreferrer"
          >
            <VideoImageContainer image={`${PUBLIC_URL}/wellnet/video_06.jpg`}>
              <VideoPlay />
            </VideoImageContainer>
          </VideoWrapper>
        </VideosContainer>
        {!props.back ? (
          <>
            <SubscriptionContainer>
              <SubscriptionTitle>
                {i18next.t("STEP_2:SUBSCRIBE_QUESTION")}
              </SubscriptionTitle>
              <OptionsContainer>
                <Option>
                  <Radio {...controlProps("yes")} className={classes.sx} />
                  {i18next.t("STEP_2:YES")}
                </Option>
                <Option>
                  <Radio {...controlProps("no")} className={classes.sx} />
                  {i18next.t("STEP_2:NO")}
                </Option>
              </OptionsContainer>
            </SubscriptionContainer>
            <Button onClick={onClickDone}>{i18next.t("STEP_2:ACCEPT")}</Button>
          </>
        ) : (
          <Button onClick={props.onClickContinue}>
            {i18next.t("STEP_2:CONTINUE")}
          </Button>
        )}
      </Wrapper>
    </Container>
  );
};

export default Step2;
