import styled from "styled-components";

export const Text = styled.div`
  text-size: 14px;
  display: flex;
  text-align: center;
`;

export const Container = styled.div`
  width: 50%;
  display: flex;
  margin-top: 30px;
  margin-left: 650px;
  justify-content: center;
  align-items: center;
`;
