export const ON_RETRIEVE_CLIENTS_REQUESTED = "ON_RETRIEVE_CLIENTS_REQUESTED";
export const ON_RETRIEVE_CLIENTS_SUCCEED = "ON_RETRIEVE_CLIENTS_SUCCEED";
export const ON_RETRIEVE_CLIENTS_FAILED = "ON_RETRIEVE_CLIENTS_FAILED";
export const ON_SELECT_CLIENT_ADD = "ON_SELECT_CLIENT_ADD";
export const ON_SUBMIT_CLIENT_CREATE_REQUESTED =
  "ON_SUBMIT_CLIENT_CREATE_REQUESTED";
export const ON_SUBMIT_CLIENT_CREATE_SUCCESS =
  "ON_SUBMIT_CLIENT_CREATE_SUCCESS";
export const ON_SUBMIT_CLIENT_CREATE_FAILED = "ON_SUBMIT_CLIENT_CREATE_FAILED";
export const ON_DELETE_CLIENT_REQUESTED = "ON_DELETE_CLIENT_REQUESTED";
export const ON_DELETE_CLIENT_SUCCESS = "ON_DELETE_CLIENT_SUCCESS";
export const ON_DELETE_CLIENT_FAILED = "ON_DELETE_CLIENT_FAILED";
export const ON_RETRIEVE_CLIENT_COUNT_SUCCEED =
  "ON_RETRIEVE_CLIENT_COUNT_SUCCEED";
export const ON_RETRIEVE_CLIENT_COUNT_FAILED =
  "ON_RETRIEVE_CLIENT_COUNT_FAILED";
export const ON_RETRIEVE_CLIENT_REQUESTED = "ON_RETRIEVE_CLIENT_REQUESTED";
