import { takeLatest, call, all, put, delay, spawn } from "redux-saga/effects";
import { AnyAction } from "redux";
import { buffers } from "redux-saga";
import {
  addShareCampaignService,
  createShareCampaignService,
  createShareCampaignSpartan,
  deleteShareCampaign,
  editShareCampaign,
  getCount,
  getShareCampaignsByCampaignId,
  getShareCampaignVariables,
  getShareData,
} from "service/share.service";
import { getCampaignData } from "service/campaign.service";
import { IAPIError } from "common/interfaces/api.interface";
import { ILayer } from "common/interfaces/layer.interface";
import { getJsons, getTemplateService } from "service/template.service";
import { IShare } from "common/interfaces/share.interface";
import { ITemplate } from "common/interfaces/template.interface";
import { ICampaign } from "common/interfaces/campaign.interface";

import * as constants from "../constants/share.constants";
import * as shareActions from "../actions/share.actions";

function* initializePlayer(action: AnyAction) {
  try {
    const shareData: IShare = yield call(getShareData, action.shareId);
    const campaign: ICampaign = yield call(
      getCampaignData,
      shareData.campaignId
    );
    const template: ITemplate = yield call(
      getTemplateService,
      campaign.templateId
    );
    const jsons: ILayer[] = yield call(getJsons, template.layers);
    template.layers = jsons;
    const response = {
      template,
      shareData,
    };
    yield put(shareActions.onInitializePlayerSucceed(response));
  } catch (err: any) {
    yield put(shareActions.onInitializePlayerFailed(err as IAPIError));
  }
}

function* initializeMatch(action: AnyAction) {
  try {
    const campaign: ICampaign = yield call(getCampaignData, action.campaignId);
    const template: ITemplate = yield call(
      getTemplateService,
      campaign.templateId
    );
    yield put(shareActions.onInitializeMatchCSVSucceed(template));
  } catch (err) {
    yield put(shareActions.onInitializeMatchCSVFailed(err as IAPIError));
  }
}

function* channelRowCreation(buffer: any) {
  while (true) {
    const action: AnyAction = buffer.take();
    yield put({ type: constants.ON_UPDATE_PARSE_ROW_COUNT });
    const response: IShare[] = yield call(
      createShareCampaignService,
      action.campaignId,
      action.headersMatch,
      action.csvHeaders,
      [action.csvData]
    );
    yield delay(10);
    yield put(shareActions.onCreateExportDataSucceed(response));
    if (buffer.isEmpty()) {
      break;
    }
  }
  yield put(shareActions.onCreateShareDataSucceed());
}

function* createShareCampaign(action: AnyAction) {
  try {
    const buffer = buffers.expanding();

    yield all(
      action.csvData.map((row: any) => {
        return buffer.put({
          type: constants.ON_PARSE_ROW_CREATE_REQUEST,
          campaignId: action.campaignId,
          headersMatch: action.headersMatch,
          csvData: row,
          csvHeaders: action.csvHeaders,
        });
      })
    );
    yield spawn(channelRowCreation, buffer);
  } catch (err) {
    yield put(shareActions.onCreateShareDataFailed(err as IAPIError));
  }
}

function* getShareCampaigns(action: AnyAction) {
  try {
    const response: IShare[] = yield call(
      getShareCampaignsByCampaignId,
      action.query,
      action.campaignId,
      action.page
    );
    yield put(shareActions.getShareCampaignsSucceed(response));
  } catch (err) {
    yield put(shareActions.getShareCampaignsFailed(err as IAPIError));
  }
}

function* onDeleteShareCampaign(action: AnyAction) {
  try {
    const response: IShare[] = yield call(
      deleteShareCampaign,
      action.shareCampaignId
    );
    yield put(shareActions.onDeleteShareCampaignSucceed(response));
  } catch (err) {
    yield put(shareActions.onDeleteShareCampaignFailed(err as IAPIError));
  }
}

function* onEditShareCampaign(action: AnyAction) {
  try {
    const response: IShare = yield call(
      editShareCampaign,
      action.values,
      action.shareCampaignId
    );
    yield put(shareActions.onEditShareCampaignSucceed(response.campaignId));
  } catch (err) {
    yield put(shareActions.onEditShareCampaignFailed(err as IAPIError));
  }
}

function* onInitializeEditVariables(action: AnyAction) {
  try {
    const shareData: IShare = yield call(
      getShareCampaignVariables,
      action.shareCampaignId
    );
    const response = { shareData };

    yield put(shareActions.onInitializeEditVariablesSucceed(response));
  } catch (err) {
    yield put(shareActions.onInitializeEditVariablesFailed(err as IAPIError));
  }
}

function* onInitializeAddShareCampaign(action: AnyAction) {
  try {
    const campaign: ICampaign = yield call(getCampaignData, action.campaignId);
    const template: ITemplate = yield call(
      getTemplateService,
      campaign.templateId
    );
    yield put(shareActions.onInitializeAddShareCampaignSucceed(template));
  } catch (err) {
    yield put(
      shareActions.onInitializeAddShareCampaignFailed(err as IAPIError)
    );
  }
}

function* addShareCampaign(action: AnyAction) {
  try {
    yield call(addShareCampaignService, action.values, action.campaignId);
    yield put(shareActions.onAddShareCampaignSucceed(action.campaignId));
  } catch (err) {
    yield put(shareActions.onAddShareCampaignFailed(err as IAPIError));
  }
}

function* getShareCampaignCount(action: AnyAction) {
  try {
    const count: number = yield call(getCount, action.campaignId);
    yield put(shareActions.getShareCamapignCountSucceed(count));
  } catch (err) {
    yield put(shareActions.getShareCamapignCountFailed(err as IAPIError));
  }
}

function* onCreateShareCampaignSpartan(action: AnyAction) {
  try {
    yield call(
      createShareCampaignSpartan,
      action.email,
      action.name,
      action.friendName
    );
    yield put(shareActions.onSubmitFormSpartanSuccess());
  } catch (err) {
    yield put(shareActions.onSubmitFormSpartanFailed(err as IAPIError));
  }
}

export default all([
  takeLatest(constants.ON_INITIALIZE_PLAYER_REQUESTED, initializePlayer),
  takeLatest(constants.ON_INITIALIZE_MATCH_CSV_REQUESTED, initializeMatch),
  takeLatest(constants.ON_CREATE_SHARE_CAMPAIGN_REQUESTED, createShareCampaign),
  takeLatest(
    constants.ON_RETRIEVE_SHARE_CAMPAIGNS_REQUESTED,
    getShareCampaigns
  ),
  takeLatest(
    constants.ON_RETRIEVE_SHARE_CAMPAIGNS_REQUESTED,
    getShareCampaignCount
  ),
  takeLatest(
    constants.ON_DELETE_SHARE_CAMPAIGN_REQUESTED,
    onDeleteShareCampaign
  ),
  takeLatest(constants.ON_EDIT_SHARE_CAMPAIGN_REQUESTED, onEditShareCampaign),
  takeLatest(
    constants.ON_INITIALIZE_EDIT_VIEW_REQUESTED,
    onInitializeEditVariables
  ),
  takeLatest(
    constants.ON_INITIALIZE_ADD_VIEW_REQUESTED,
    onInitializeAddShareCampaign
  ),
  takeLatest(constants.ON_ADD_SHARE_CAMPAIGN_REQUESTED, addShareCampaign),
  takeLatest(
    constants.ON_SUBMIT_FORM_SPARTAN_REQUESTED,
    onCreateShareCampaignSpartan
  ),
]);
