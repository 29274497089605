import React, { FunctionComponent } from "react";
import i18next from "i18next";
import LoginForm from "./components/LoginForm/LoginForm";
import { ILoginViewProps } from "./types";
import {
  LoginContainer,
  LoginContent,
  LoginFormContainer,
  Title,
} from "./styles";
import "./i18n";

const LoginView: FunctionComponent<ILoginViewProps> = (
  props: ILoginViewProps
) => {
  const { onSubmit } = props;

  return (
    <LoginContainer data-testid="login-view">
      <LoginContent>
        <Title>{i18next.t("LOGIN_VIEW:TITLE")}</Title>
        <LoginFormContainer>
          <LoginForm onSubmit={onSubmit} error={props.error} />
        </LoginFormContainer>
      </LoginContent>
    </LoginContainer>
  );
};

export default LoginView;
