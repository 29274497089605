import { COLORS } from "common/constants/color.contants";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
`;

export const InputColorContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 50px 0;

  .block-picker {
    border: 1px solid ${COLORS.black};
  }

  .block-picker > div span div {
    border: 1px solid ${COLORS.black};
  }
`;

export const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  place-content: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 40px;
`;

export const ResetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled.p`
  color: ${COLORS.red};
`;

export const List = styled.ul``;

export const Message = styled.li`
  list-style-type: circle;
`;
