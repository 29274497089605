import React, { FunctionComponent } from "react";
import { Form, Field } from "react-final-form";
import i18next from "i18next";

import {
  composeValidators,
  emailValidation,
  requiredValidation,
} from "helper/validations";

import Button from "components/Button/Button";
import Input from "components/Input/Input";
import { ILoginFormProps } from "./types";
import {
  CustomForm,
  ForgotPasswordContainer,
  ForgotPasswordLink,
  ButtonContainer,
  ErrorMessage,
} from "./styles";
import "./i18n";

const LoginForm: FunctionComponent<ILoginFormProps> = ({ onSubmit, error }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, pristine }) => (
      <CustomForm data-testid="login-form" onSubmit={handleSubmit}>
        <Field
          label={i18next.t("LOGIN_FORM:EMAIL")}
          name="email"
          render={Input}
          type="email"
          validate={composeValidators(requiredValidation, emailValidation)}
        />
        <Field
          label={i18next.t("LOGIN_FORM:PASSWORD")}
          name="password"
          render={Input}
          type="password"
          validate={requiredValidation}
        />
        {error && (
          <ErrorMessage>{i18next.t("LOGIN_FORM:LOGIN_FAILED")}</ErrorMessage>
        )}
        <ForgotPasswordContainer>
          <ForgotPasswordLink to="/forgot-password">
            {i18next.t("LOGIN_FORM:FORGOT_PASSWORD")}
          </ForgotPasswordLink>
        </ForgotPasswordContainer>
        <ButtonContainer>
          <Button disabled={pristine || submitting} type="submit">
            {i18next.t("LOGIN_FORM:SUBMIT_BUTTON")}
          </Button>
        </ButtonContainer>
      </CustomForm>
    )}
  />
);

export default LoginForm;
