import { LinearProgress } from "@material-ui/core";
import DashboardLayout from "components/DashboardLayout/DashboardLayout";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as actions from "redux/actions/share.actions";
import {
  getRequestCount,
  isLoading,
  getExportCsvData,
  getCsvHeaders,
} from "redux/selectors/share.selector";
import getTemplateSelector from "redux/selectors/template.selector";
import ImportCsvView from "views/importCsvView/importCsvView";
import { IHeaderMatch } from "views/importCsvView/types";

const ImportCSVContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const getTemplateData = useSelector(getTemplateSelector());
  const getShareCsvHeaders = useSelector(getCsvHeaders());
  const getExportData = useSelector(getExportCsvData());
  const loading: boolean = useSelector(isLoading());
  const requestCount: number = useSelector(getRequestCount());
  const [rowsQuantity, setRowQuantity] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    dispatch(actions.onInitializeMatchCSV(id));
  }, [dispatch, id]);

  const confirmCSV = (
    headersMatch: IHeaderMatch[],
    csvHeaders: string[],
    csvData: string[][]
  ) => {
    setRowQuantity(csvData.length);
    dispatch(actions.onCreateShareData(id, headersMatch, csvHeaders, csvData));
  };

  useEffect(() => {
    if (rowsQuantity > 0) {
      const actualProgress = (requestCount * 100) / rowsQuantity;
      setProgress(actualProgress);
    }
  }, [requestCount, rowsQuantity]);

  return (
    <DashboardLayout>
      {!loading || rowsQuantity === 0 ? (
        !!getTemplateData.template.textVariables.length && (
          <ImportCsvView
            textVariables={getTemplateData.template.textVariables}
            exportCsvData={getExportData}
            confirmCSV={confirmCSV}
            headers={getShareCsvHeaders}
          />
        )
      ) : (
        <div>
          {requestCount} / {rowsQuantity}
          <LinearProgress variant="determinate" value={progress} />
        </div>
      )}
    </DashboardLayout>
  );
};

export default ImportCSVContainer;
