import React, { FunctionComponent } from "react";
import {
  Replay,
  Sound,
  SoundBlock,
  ControlsWrapper,
  SoundDisabled,
  ReplayCircleHover,
  ReplayContainer,
  SoundContainer,
  SoundHover,
  SoundBlockedHover,
} from "./styles";
import { IProps } from "./types";

const ControlsPreview: FunctionComponent<IProps> = (props: IProps) => {
  const handleReplay = () => {
    if (props.replayVisible) {
      props.replay();
    }
  };
  return (
    <ControlsWrapper>
      <ReplayContainer replayvisible={props.replayVisible ? 1 : 0}>
        <Replay replayvisible={props.replayVisible ? 1 : 0} />
        <ReplayCircleHover
          replayvisible={props.replayVisible ? 1 : 0}
          onClick={handleReplay}
        />
      </ReplayContainer>
      <SoundContainer>
        {(props.replayVisible && props.hasAudio) || props.soundDisabled ? (
          <SoundDisabled />
        ) : (
          props.hasAudio &&
          (props.toggle ? (
            <>
              <Sound />
              <SoundHover onClick={props.onChangeToggle} />
            </>
          ) : (
            <>
              <SoundBlock />
              <SoundBlockedHover onClick={props.onChangeToggle} />
            </>
          ))
        )}
      </SoundContainer>
    </ControlsWrapper>
  );
};
export default ControlsPreview;
