import { copyToClipboard } from "helper/copyToClipboard";
import React, { FunctionComponent, useState } from "react";
import {
  ButtonContainer,
  ButtonText,
  LogoContainer,
  Paragraph,
  PromoCode,
  SquareContainer,
  SuccessMessage,
  IconCopy,
  IconCheck,
} from "./styles";
import { IProps } from "./types";

const SquarePromoReveal: FunctionComponent<IProps> = (props: IProps) => {
  const [checked, setChecked] = useState<boolean>(false);

  const onRedirect = () => {
    props.onUpdateCTAClicked();
    window.open(props.redirectLink, "_blank");
  };

  const onCopyText = () => {
    setChecked(true);
    copyToClipboard(props.promoCode);
  };

  setTimeout(() => {
    if (checked) {
      setChecked(false);
    }
  }, 2000);

  return (
    <SquareContainer>
      <LogoContainer />
      {props.showSuccess && (
        <SuccessMessage>Your friend’s offer has been sent!</SuccessMessage>
      )}
      <Paragraph showSuccess={props.showSuccess}>Your code:</Paragraph>

      <PromoCode>
        {props.promoCode}
        {!checked ? <IconCopy onClick={onCopyText} /> : <IconCheck />}
      </PromoCode>
      <ButtonContainer onClick={onRedirect}>
        <ButtonText>REDEEM YOUR 15% OFF!</ButtonText>
      </ButtonContainer>
    </SquareContainer>
  );
};

export default SquarePromoReveal;
