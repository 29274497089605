import React, { FunctionComponent, useState } from "react";
import LottiePlayer from "components/LottiePlayer/LottiePlayer";
import AnimationBG from "./animationBG.json";
import {
  FormContainer,
  Replay,
  ReplayBlack,
  ReplayContainer,
  ReplayCircleHover,
} from "./styles";
import Form from "./components/Form/Form";
import SquarePromoReveal from "./components/SquarePromoReveal/SquarePromoReveal";
import { IProps } from "./types";

const SpartanEndForm: FunctionComponent<IProps> = (props: IProps) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const onSubmit = (email: string, name: string) => {
    setIsFormSubmitted(true);
    props.onSubmit(email, name);
  };

  return (
    <>
      {!isFormSubmitted && (
        <FormContainer showBGImage out={false}>
          <Form onSubmitForm={onSubmit} />
          <ReplayContainer>
            <Replay />
            <ReplayCircleHover onClick={() => props.setIsComplete(false)} />
          </ReplayContainer>
        </FormContainer>
      )}
      {isFormSubmitted && (
        <FormContainer showBGImage={false} out={false}>
          <SquarePromoReveal
            promoCode="GG-STWC15A"
            redirectLink="https://endurancecui.active.com/new/events/74242831/select-race?_p=47099830547891397&error=login_required&state=bb033435-33d2-46ba-9b01-fe13da74fe22&e4q=cdcc7dce-a6fa-48e1-a801-b2e63c9c4a7f&e4p=5f590a29-9093-4f3f-8931-9d952d9ea9dd&e4ts=1633009510&e4c=active&e4e=snawe00000000&e4rt=Safetynet&e4h=f960768f6a7ba71b00abad8d44ef7379"
            onUpdateCTAClicked={props.onUpdateCTAClicked}
            showSuccess
          />
          <LottiePlayer
            animationJsonPath={AnimationBG}
            autoplay={false}
            loop
            speed={0.5}
            segments={[13, 156]}
          />
          <ReplayContainer>
            <ReplayBlack />
            <ReplayCircleHover onClick={() => props.setIsComplete(false)} />
          </ReplayContainer>
        </FormContainer>
      )}
    </>
  );
};

export default SpartanEndForm;
