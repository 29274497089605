import { IErrorReducerState } from "redux/reducers/error.reducer";
import { IStore } from "redux/reducers/types";
import { createSelector, OutputSelector } from "reselect";

const getError = (state: IStore) => state.error;

const getErrorSelector = (): OutputSelector<
  IStore,
  IErrorReducerState,
  (res: IErrorReducerState) => IErrorReducerState
> => createSelector([getError], (error) => error);

export default getErrorSelector;
