import { locales } from "configs/i18n";
import i18next from "i18next";

i18next.addResources(locales.EN, "LOGIN_FORM", {
  EMAIL: "Email",
  FORGOT_PASSWORD: "Forgot your password?",
  PASSWORD: "Password",
  SUBMIT_BUTTON: "Log In",
  LOGIN_FAILED: "The username or password is incorrect",
});
